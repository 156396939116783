/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Checkbox, IconButton, Menu, MenuItem } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { enqueueSnackbar } from 'notistack';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  useDeleteFolderMutation,
  useDeleteRatesMutation
} from '../../../../../../api/references/estimates';
import {
  IEstimateReference,
  RowType
} from '../../../../../../api/references/estimates/estimates.types';
import {
  changeCreated,
  getPricesState,
  removeDataByID,
  removeTemplate,
  setNeighbor,
  setParentLevel
} from '../../../../../../store/slices/references/prices/prices';
import { useAppDispatch } from '../../../../../../store/store';
import { PricesReferenceContext } from '../../PricesReference';
import { IErrorData } from '../../PricesReference.types';
import AddLevelBtn from '../AddLevelBtn';
import LevelIcon from '../LevelIcon';
import { ExecutedTabCellLevelFolder } from '../../../../../Calculations/components/CalculationDirectory/tabs/ExecutedTab';

const Level: FC<ICellRendererParams<IEstimateReference, any, any>> = ({
  data,
  node,
  api
}) => {
  const {
    isCreated,
    data: tableData,
    filteredData
  } = useSelector(getPricesState);
  const { t } = useTranslation('references');
  const {
    collapseUUid,
    hiddenRowsUUid,
    removedIds,
    clearRemovedIds,
    checkboxClick,
    getFormState,
    add,
    deleteFolderConfirm,
    denyCreated
  } = useContext(PricesReferenceContext);
  const [deleteFolder] = useDeleteFolderMutation();
  const [deleteRates] = useDeleteRatesMutation();

  const dispatch = useAppDispatch();
  const anchorEl = useRef<null | HTMLElement>(null);
  const handleClose = () => {
    anchorEl.current = null;
    setMenuRowOpened(false);
  };
  const [isMenuRowOpened, setMenuRowOpened] = useState(false);

  const cancelCreate = () => {
    api.applyTransaction({ remove: [data!] });
    dispatch(changeCreated(false));
    dispatch(removeTemplate());
  };

  const handleOpenMenuRowClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!isCreated) return;
      const type = event.currentTarget.dataset?.type;
      // const id = event.currentTarget.dataset?.dataID;
      if (type === 'FOLDER' || type === 'RATE') {
        anchorEl.current = event.currentTarget;
        if (event.currentTarget) setMenuRowOpened(true);
      } else {
        // if (id && checkedRatePositions.includes(id)) {
        //   setCheckedRatePositions();
        // }
      }
    },
    [isCreated]
  );
  const renderIcon = useCallback(
    (rowType: RowType, id: number, parent: number | null) => {
      if (rowType !== 'FOLDER' && id < 0) {
        return <div />;
      }
      switch (rowType) {
        case 'RATE':
        case 'FOLDER':
          return null;
        default:
          return removedIds.length ? (
            data?.parentID === removedIds[0].parent ? (
              <Checkbox
                tabIndex={-1}
                checked={removedIds.findIndex((_) => _.id === id) >= 0}
                onClick={() => checkboxClick(id, rowType, parent)}
                size={'small'}
              />
            ) : (
              <span />
            )
          ) : (
            <Checkbox
              tabIndex={-1}
              checked={removedIds.findIndex((_) => _.id === id) >= 0}
              onClick={() => checkboxClick(id, rowType, parent)}
              size={'small'}
            />
          );
      }
    },
    [removedIds]
  );

  const handleRemove = (d: IEstimateReference) => {
    console.log('folder', d);
    if (d!.id >= 0) {
      if (d?.rowType === 'RATE') {
        checkboxClick(d.id, d?.rowType, d!.parentID);
        deleteFolderConfirm(d!.lvl as number, () => {
          deleteRates({ rateIDs: [d!.id], ratesPositionIDs: [] }).then(
            (resp) => {
              if ('error' in resp) {
                const message = (resp as IErrorData<string>)?.error?.data;
                handleClose();
                if (message)
                  enqueueSnackbar({
                    variant: 'error',
                    message: t(`estimates.${message}`),
                    autoHideDuration: 5000
                  });
              } else {
                dispatch(removeDataByID(d!));
                setTimeout(() => {
                  api.refreshCells({ force: true });
                }, 400);
                handleClose();
                clearRemovedIds();
                enqueueSnackbar({
                  variant: 'success',
                  message: 'Успешно удалено'
                });
              }
            }
          );
        });
      } else {
        console.log('folder delete confirm');
        deleteFolderConfirm(data!.lvl as number, () => {
          deleteFolder(data?.id!).then((resp) => {
            if ('error' in resp) {
              const message = (resp as IErrorData<string>)?.error?.data;
              handleClose();
              if (message)
                enqueueSnackbar({
                  variant: 'error',
                  message: t(`estimates.${message}`),
                  autoHideDuration: 5000
                });
            } else {
              dispatch(removeDataByID(data!));
              setTimeout(() => {
                api.refreshCells({ force: true });
              }, 400);
              handleClose();
              enqueueSnackbar({
                variant: 'success',
                message: 'Успешно удалено'
              });
            }
          });
        });
      }
    } else {
      if (tableData[0].id < 0) {
        enqueueSnackbar({
          variant: 'error',
          message: (
            <p>
              Сперва вы должны создать базовую сущность <br /> Вы еще не
              завершили создание
            </p>
          )
        });
        return;
      }
      const formState: { [key: string]: any } | undefined = getFormState?.();
      if (!formState) return;
      for (const [_, value] of Object.entries(formState)) {
        if (value) {
          denyCreated();
          return;
        }
      }
      dispatch(removeTemplate());
      dispatch(changeCreated(false));
    }
  };
  const isShowingArrow = useMemo(() => {
    if (!tableData) return false;
    const el = tableData.find((i) => i.uuid === data?.uuid);
    if (!el) return false;
    return !!el.children.length;
  }, [tableData]);
  return (
    <>
      <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
        <Box
          width={'68px'}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}>
          {data?.rowType !== 'RATE_POSITION' &&
          data &&
          'hasChildren' in data &&
          data.hasChildren !== null ? null : data?.rowType !==
              'RATE_POSITION' && isShowingArrow ? (
            <IconButton
              tabIndex={-1}
              style={{
                outline: 'none'
              }}
              onClick={() => {
                data?.uuid && collapseUUid(data.uuid);
              }}
              size="small">
              <KeyboardArrowUpIcon
                className={'arrow'}
                tabIndex={-1}
                sx={{
                  outline: 'none',
                  transition: 'rotate .2s linear forwards',
                  rotate: hiddenRowsUUid.includes(data?.uuid ?? '-10')
                    ? '180deg'
                    : 0
                }}
              />
            </IconButton>
          ) : null}
          {data!.rowType === 'RATE_POSITION' ? (
            <>
              <IconButton
                tabIndex={-1}
                sx={{
                  width: 34,
                  height: 34
                }}
                data-type={data?.rowType}
                data-ID={data?.id}
                onClick={handleOpenMenuRowClick}
                size="small">
                {/*<BadgeLevel>{data!.lvl}</BadgeLevel>*/}
                {renderIcon(data!.rowType, data!.id, data!.parentID)}
              </IconButton>
              {/*{data?.create ? (*/}
              {/*  <IconButton*/}
              {/*    tabIndex={-1}*/}
              {/*    sx={{*/}
              {/*      width: 34,*/}
              {/*      height: 34,*/}
              {/*    }}*/}
              {/*    data-type={data?.rowType}*/}
              {/*    data-ID={data?.id}*/}
              {/*    onClick={cancelCreate}*/}
              {/*    size="small">*/}
              {/*    <CloseIcon color={'error'} />*/}
              {/*  </IconButton>*/}
              {/*) : (*/}
              <IconButton
                tabIndex={-1}
                sx={{
                  width: 34,
                  height: 34
                }}
                data-type={data?.rowType}
                data-ID={data?.id}
                onClick={() => {
                  if (!isCreated) {
                    // console.log(tableData);
                    dispatch(
                      setParentLevel({
                        id: 5 === data?.lvl ? data!.parentID : data!.id,
                        template: 5
                      })
                    );
                    dispatch(setNeighbor(5 === data?.lvl ? data!.id : null));
                    add(
                      5,
                      node.rowIndex,
                      data?.lvl,
                      data?.rowType !== 'RATE_POSITION'
                        ? data?.id
                        : data?.parentID
                    );
                    // } else {
                    //   enqueueSnackbar({
                    //     variant: 'error',
                    //     message: 'Сперва вы должны создать базовую сущность\\Вы еще не завершили создание',
                    //   });
                    // }
                  } else {
                    enqueueSnackbar({
                      variant: 'error',
                      message: 'Вы еще не завершили создание'
                    });
                  }
                }}
                size="small">
                <LevelIcon levelName={5} />
              </IconButton>
              {/*)}*/}
            </>
          ) : (
            <>
              <div />
              {/*{data?.create ? (*/}
              {/*  <IconButton*/}
              {/*    tabIndex={-1}*/}
              {/*    sx={{*/}
              {/*      width: 34,*/}
              {/*      height: 34,*/}
              {/*    }}*/}
              {/*    data-type={data?.rowType}*/}
              {/*    data-ID={data?.id}*/}
              {/*    onClick={cancelCreate}*/}
              {/*    size="small">*/}
              {/*    <CloseIcon color={'error'} />*/}
              {/*  </IconButton>*/}
              {/*) : (*/}
              {/*  <div />*/}
              {/*)}*/}
              <AddLevelBtn
                level={data?.lvl ?? null}
                data={data!}
                isCreated={!!data?.create}
                type={data?.rowType!}
                filteredData={filteredData}
                remove={handleRemove}
                clickAddLevel={(template: number | null, t: any) => {
                  console.log(isCreated);
                  if (!isCreated) {
                    // console.log(tableData);
                    console.log(template, t);
                    dispatch(
                      setParentLevel({
                        id: template === data?.lvl ? data!.parentID : data!.id,
                        template: template as number
                      })
                    );
                    dispatch(
                      setNeighbor(template === data?.lvl ? data!.id : null)
                    );
                    console.log(
                      template,
                      node.rowIndex,
                      data?.lvl,
                      data?.rowType !== 'RATE_POSITION'
                        ? data?.id
                        : data?.parentID
                    );
                    add(
                      template === null ? 4 : template,
                      node.rowIndex,
                      data?.lvl,
                      data?.rowType !== 'RATE_POSITION'
                        ? data?.id
                        : data?.parentID
                    );
                    // } else {
                    //   enqueueSnackbar({
                    //     variant: 'error',
                    //     message: 'Сперва вы должны создать базовую сущность\\Вы еще не завершили создание',
                    //   });
                    // }
                  } else {
                    enqueueSnackbar({
                      variant: 'error',
                      message: 'Вы еще не завершили создание'
                    });
                  }
                }}
              />
            </>
          )}
        </Box>
        {/*) : (*/}
        {/*  <div></div>*/}
        {/*)}*/}
        {/*{data?.rowType !== 'RATE_POSITION' && (*/}
        {/*  <AddLevelBtn*/}
        {/*    level={data?.lvl ?? null}*/}
        {/*    isCreated={!!data?.create}*/}
        {/*    type={data?.rowType!}*/}
        {/*    clickAddLevel={(template: number | string, t: any) => {*/}
        {/*      if (!isCreated) {*/}
        {/*        // console.log(tableData);*/}
        {/*        dispatch(*/}
        {/*          setParentLevel({*/}
        {/*            id: template === data?.lvl ? data!.parentID : data!.id,*/}
        {/*            template: template as number,*/}
        {/*          }),*/}
        {/*        );*/}
        {/*        dispatch(setNeighbor(template === data?.lvl ? data!.id : null));*/}
        {/*        add(template, node.rowIndex, data?.lvl, data?.parentID);*/}
        {/*        // } else {*/}
        {/*        //   enqueueSnackbar({*/}
        {/*        //     variant: 'error',*/}
        {/*        //     message: 'Сперва вы должны создать базовую сущность\\Вы еще не завершили создание',*/}
        {/*        //   });*/}
        {/*        // }*/}
        {/*      } else {*/}
        {/*        enqueueSnackbar({ variant: 'error', message: 'Вы еще не завершили создание' });*/}
        {/*      }*/}
        {/*    }}*/}
        {/*  />*/}
        {/*)}*/}
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl.current}
        open={isMenuRowOpened}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <MenuItem
          sx={{
            '&:hover': {
              color: (theme) => theme.palette.primary.main
            },
            width: 200,
            transition: 'color 0.2s linear'
          }}
          onClick={() => handleRemove(data!)}>
          Удалить
        </MenuItem>
      </Menu>
    </>
  );
};

export const BadgeLevel: React.FC<PropsWithChildren> = ({ children }) => {
  return <ExecutedTabCellLevelFolder>{children}</ExecutedTabCellLevelFolder>;
};

export default Level;
