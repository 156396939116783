import { Stack } from '@mui/material';
import { useGetLSRFilesQuery } from 'api/lsr';
import { ProjectLabel } from 'components/ProjectLabel';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { useProjectId } from 'hooks/useProjectId';
import { FC, SyntheticEvent, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LSRTabValue, topbarTabsLabels } from 'types';
import { Table, Topbar } from './components';
import WSCustomSnackbar from './components/WSCustomSnackbar/WSCustomSnackbar';
import { useAppDispatch, useTypedSelector } from '../../store/store';
import { getWsState, setField } from '../../store/slices/ws';
import { useGetProjectDashboardQuery } from '../../api/projects';
import { useCalcId } from '../../hooks/useCalcId';

export const Lsr: FC = () => {
  // const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<LSRTabValue>('all');
  const projectID = useProjectId();
  const calcID = useCalcId();
  const { data: project } = useGetProjectDashboardQuery(
    { projectID },
    { skip: !projectID }
  );
  const location = useLocation();


  const {lsr:{inProgress,isOpenLsrDrawer:open,isStarted,targetDrawer}} = useTypedSelector(getWsState)
  const dispatch = useAppDispatch();

  const { data, isFetching } = useGetLSRFilesQuery({ projectID });

  const filteredLSRFilesData = useMemo(() => {
    if (!data) return [];
    if (selectedTab === 'all') return data.files;

    if (selectedTab === 'undefiend')
      return data.files.filter(
        (lsrFile) =>
          lsrFile.status === selectedTab || lsrFile.status === 'not_selected'
      );

    return data.files.filter((lsrFile) => lsrFile.status === selectedTab);
  }, [selectedTab, data]);

  const changeTabHandler = (_: SyntheticEvent, newValue: LSRTabValue) => {
    setSelectedTab(newValue);
  };

  useBreadcrumbs(
    [
      { title: <ProjectLabel /> },
      { title: `Реестр ЛСР` },
      { title: `${topbarTabsLabels[selectedTab]}` }
    ],
    [selectedTab]
  );
  console.log(targetDrawer);
  return (
    <Stack flex="1 1 auto" overflow="hidden">
      <Topbar
        open={open}
        setOpen={(f)=> {
          // dispatch(toggleLsrDrawer(f))
          dispatch(setField({isOpenLsrDrawer:f}))
          if(!isStarted || !inProgress||!targetDrawer){
            dispatch(setField({targetDrawer:location.pathname}))
          }
          // if (project?.project?.shortName) {
          //   dispatch(setField({key:'projectName', value:project?.project?.shortName}))
          // }
        }}
        value={selectedTab}
        onChange={changeTabHandler}
      />
      <Table data={filteredLSRFilesData} isLoading={isFetching} />
    </Stack>
  );
};
