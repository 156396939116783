import { createAsyncThunk } from '@reduxjs/toolkit';
import { IRowNode } from 'ag-grid-community';
import { calculationsApi } from 'api/calculations';
import { GetComplicatedListResponse, RimRequest } from 'api/calculations/types';
import { RootState } from 'store/store';
import { ComplicatedRimData, ComplicatedRimRow } from '../../../../types';
import {
  toggleEndFetch,
  toggleIsNotFetched,
  toggleLoader,
  togglePage
} from './rim.slice';
import {
  ENUMLocalStorage,
  getLocalStorage
} from '../../../../hooks/use-local-storage';
import { setHiddenRows } from '../rim/rim.slice';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { config } from '../../../../core/config';
import { getRefreshToken } from '../../../../utils/getRefreshToken';
import { delay } from 'lodash';
import { GetRimChildrenReturnType } from './rim.types';

export const getData = createAsyncThunk<ComplicatedRimData | null, RimRequest>(
  'rim_calculation/getData',
  async (params, { dispatch, getState, signal }) => {
    const rootState = getState() as RootState;
    // const gridRef = (getState() as RootState).AgGrid.gridRef;
    // const rimState = rootState.calculations.rim.calc;
    // const localStorageRows = getLocalStorage(
    //   ENUMLocalStorage.levelsIndexMethod
    // );
    // const isEnableBodyParams = Object.values(params.body ?? {}).flat(
    //   Infinity
    // ).length;
    // const findLevels = localStorageRows?.find(
    //   (level) => level.id === params.calcID
    // );
    // if (findLevels?.levels /*&& !isEnableBodyParams*/) {
    //   dispatch(setHiddenRows(new Set(findLevels.levels)));
    // }
    try {
      const isBodyEnabled =
        params.body && Object.values(params.body).flat().length;
      const result = await axios.post<ComplicatedRimData>(
        config.apiHost +
          `/calculation/${params.calcID}/complicated/${params.type}/v2/get`,
        params?.body || {},
        {
          // method: 'POST',
          params: {
            page: params.page,
            limit: params.limit,
            depth: params.depth
          },
          signal,
          headers: {
            Authorization: 'Bearer ' + rootState.auth.token
          }
        }
      );
      if (params?.depth) {
        const collapsedRows = new Set<number>();
        result.data.data.forEach((row) => {
          if (row.lvl! < params.depth!) {
            collapsedRows.add(row.id as number);
          }
        });
        dispatch(setHiddenRows(collapsedRows));
      }
      // const { unwrap } = dispatch(
      //   calculationsApi.endpoints['getCalculationCalcIDComplicated'].initiate({
      //     ...params
      //   })
      // );
      // const result = await unwrap();
      // if (isEnableBodyParams) {
      //   const hideRows: Set<number> = new Set();
      //   result.data.data
      //     .filter((item) => item.lvl >= 2)
      //     .forEach((item) => {
      //       hideRows.add(item.id);
      //     });
      //   dispatch(setHiddenRows(hideRows));
      // }
      // await delay(() => {}, 1000);
      console.log('server response getData', result);
      if ('data' in result) {
        // if (!findLevels?.levels /*&& !isEnableBodyParams*/) {
        //   const collapseRows = new Set(rimState.hiddenRows);
        //   result.data.data.forEach((el) => {
        //     if (!(el.lvl! >= rimState.collapseMode)) {
        //       collapseRows.add(el.id);
        //     }
        //   });
        //   dispatch(setHiddenRows(collapseRows));
        // }
        if (result?.data.data && result.data.data.length < params.limit) {
          dispatch(toggleEndFetch(true));
        } else {
          dispatch(togglePage());
        }
        dispatch(toggleIsNotFetched(false));

        return (
          {
            ...result.data,
            data:
              ((params.depth && params.depth === 1) ||
                (params.depth &&
                  params.depth > 1 &&
                  result.data.data.length < params.limit)) &&
              result.data?.limitedCostsAsRows?.length &&
              !isBodyEnabled
                ? result.data.data.concat(
                    [
                      {
                        id: -result.data?.limitedCostsAsRows[0].id,
                        type: 'limit-header' as ComplicatedRimRow['type'],
                        title: 'лимитированные',
                        hasChildren: false,
                        including: {
                          building: null,
                          equipment: null,
                          mounting: null,
                          other: null
                        },
                        lvl: 1
                      }
                    ].concat(result.data!.limitedCostsAsRows! as any)
                  )
                : result.data.data
          } ?? null
        );
      } else {
        return null;
      }
    } catch (e: any) {
      if (e?.response?.status == 401) {
        getRefreshToken(dispatch, rootState, getData, params);
      }
      console.log(e);
      return null;
    } finally {
      // if (!(getState() as RootState).calculations.bim.calc.endFetch) {
      //   dispatch(appendData({ ...params, page: params.page + 1 }));
      // }
    }
  }
);
export const appendData = createAsyncThunk<
  ComplicatedRimData | null,
  RimRequest
>(
  'rim_calculation/appendData',
  async (params, { dispatch, getState, signal }) => {
    dispatch(toggleLoader(true));
    const rootState = getState() as RootState;
    const gridRef = rootState.AgGrid.gridRef;
    const rimState = rootState.calculations.rim.calc;
    try {
      const isBodyEnabled =
        params.body && Object.values(params.body).flat().length;
      const result: AxiosResponse<ComplicatedRimData> = await axios.post(
        config.apiHost +
          `/calculation/${params.calcID}/complicated/${params.type}/v2/get`,
        params?.body || {},
        {
          // method: 'POST',
          params: { page: params.page, limit: params.limit },
          signal,
          headers: {
            Authorization: 'Bearer ' + rootState.auth.token
          }
        }
      );
      // await delay(() => {}, 1000);
      // const result = await dispatch(
      //   calculationsApi.endpoints['getCalculationCalcIDComplicated'].initiate({
      //     ...params
      //   })
      // );
      const limitList = rootState.calculations.rim.calc.limitedRows;
      console.log('axios response', result);
      if (!('data' in result)) return null;

      if (result.data?.data && gridRef) {
        // const rows: IRowNode<any>[] = [];
        // if (limitList.length) {
        //   for (const el of limitList) {
        //     const item = gridRef.api.getRowNode(String(el.id));
        //     if (item) rows.push(item);
        //   }
        //   const chapterLimit = gridRef.api.getRowNode(String(-limitList[0].id));
        //   if (chapterLimit) rows.push(chapterLimit);
        // }
        // const collapseRows = new Set(rimState.hiddenRows);
        // const newDataForTable = result.data!.data.filter((el) => {
        //   if (el.parentID === null) return true;
        //   if (
        //     rimState.hiddenRows.has(el.id) ||
        //     rimState.hiddenRows.has(el.parentID!)
        //   ) {
        //     if (el?.parentID) {
        //       return rimState.hiddenRows.has(el.parentID);
        //     }
        //     return true;
        //   } else {
        //     if (el.lvl! <= rimState.collapseMode && collapseRows.has(el.id)) {
        //       collapseRows.delete(el.id);
        //       return true;
        //     }
        //     return false;
        //   }
        // });
        // dispatch(setHiddenRows(collapseRows));
        const add =
          result.data?.data.length < params.limit &&
          result.data?.limitedCostsAsRows?.length &&
          !isBodyEnabled
            ? result.data.data.concat(
                [
                  {
                    id: -result.data.limitedCostsAsRows[0].id,
                    type: 'limit-header' as ComplicatedRimRow['type'],
                    title: 'лимитированные',
                    hasChildren: false,
                    including: {
                      building: null,
                      equipment: null,
                      mounting: null,
                      other: null
                    },
                    lvl: 1
                  }
                ].concat(result.data!.limitedCostsAsRows! as any)
              )
            : result.data.data;

        gridRef.api.applyTransactionAsync({ add });
        // if (result.data.data.length < params.limit) {
        //   dispatch(toggleEndFetch(true));
        // }
      }
      if (result.data?.data && result.data?.data.length < params.limit) {
        dispatch(toggleEndFetch(true));
      } else {
        dispatch(togglePage());
      }
      if (isBodyEnabled) result.data.limitedCostsAsRows = [];
      dispatch(toggleLoader(false));
      return result.data ?? null;
    } catch (e: any) {
      console.log(e);
      if (e?.response?.status == 401) {
        getRefreshToken(dispatch, rootState, appendData, params);
      }

      dispatch(toggleLoader(false));
      return null;
    } finally {
      // if (!(getState() as RootState).calculations.bim.calc.endFetch) {
      //   dispatch(appendData({ ...params, page: params.page + 1 }));
      // }
      dispatch(toggleLoader(false));
    }
  }
);
export const getChildren = createAsyncThunk<
  GetRimChildrenReturnType | null,
  {
    calcID: number | string;
    rowID: number | string;
    index: number;
    action: 'add' | 'remove';
  }
>('getRimChildren', async (params, { dispatch, getState }) => {
  const rootState = getState() as RootState;
  const grid = rootState.AgGrid.gridRef;
  const { action, index, ...restParams } = params;
  try {
    const response: any = await dispatch(
      calculationsApi.endpoints.getComplicatedRowsChildrenListV2.initiate({
        ...restParams,
        limit: 10000,
        offset: 0,
        type: 'rim'
      })
    ).unwrap();
    if (response && response.children && grid) {
      if (params.action === 'add') {
        const { hiddenRows } = (getState() as RootState).calculations.rim.calc;
        if (hiddenRows.has(params.rowID as number)) {
          grid.api.applyTransaction({
            addIndex: params.index + 1,
            add: response.children
          });
        }
      } else {
        grid.api.applyTransaction({
          remove: response.children
        });
      }
      grid.api.refreshCells({ force: true });
    }
    return {
      ...response,
      fromIndex: params.index + 1,
      isDelete: params.action === 'remove'
    };
  } catch (e: any) {
    if (e?.response?.status == 401) {
      getRefreshToken(dispatch, rootState, getChildren, params);
    }

    return null;
  }
});
