import { ArrowBack } from '@mui/icons-material';
import { Checkbox, MenuItem, Stack, Typography } from '@mui/material';
import { GetCoefficientsV3ActResponse } from 'api/params/params.types';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { ActListContext } from 'pages/Calculations/components/CalculationСomplicated';
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ActList } from 'types';
import { getFormatDate } from 'utils/formatDate';
import { StyledSelect } from '../../../Accomplishment/components/CaptionTable/CaptionTable.style';
import { ParametersForm } from './components';
import {
  SelectField,
  StyledBackButton,
  StyledDialog,
  StyledDialogHeader,
  StyledSuccessButton
} from './ParametersDialog.styles';
import { ParametersDialogProps, SideMenuNames } from './ParametersDialog.types';

const ParametersDialog: FC<ParametersDialogProps> = ({
  open,
  onClose,
  isExecution = false,
  confirm
}): JSX.Element => {
  const [disabledLoader, setDisabledLoader] = useState(false);
  const [loaderActTab, setLoaderActTab] = useState<boolean>(false);

  const toggleLoaderActTab = (f?: boolean): void => {
    setLoaderActTab(f !== undefined ? f : (prevState) => !prevState);
  };

  const changeDisabledLoader = useCallback(
    (b: boolean) => setDisabledLoader(b),
    []
  );

  const { data, current, setCurrent, isFetchActs, isFetching } =
    useContext(ActListContext);

  const methods = useForm<GetCoefficientsV3ActResponse>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    shouldFocusError: false
    // resolver: yupResolver(parametersFormValidationSchema),
  });

  const [selectedMenu, setSelectedMenu] = useState<SideMenuNames>(
    isExecution ? 'act' : 'nrsp'
  );

  const formatDate = (act: ActList) => {
    if (!act.startDate || !act.endDate) return '';
    return (
      getFormatDate({ date: act.startDate }) +
      ' — ' +
      getFormatDate({ date: act.endDate })
    );
  };

  const renderAct = useMemo(() => {
    if (!current) return 'Актов нет';
    return formatDate(current);
  }, [current]);

  const renderActs = useMemo(() => {
    const removed = (methods.getValues('removed.acts') || []).map((_) => _?.id);
    if (!data?.actList) return [];
    if (!removed.length)
      return data.actList.map((item) => {
        return (
          <MenuItem
            key={item.id}
            onClick={() => {
              if (current?.id !== item.id) {
                changeModalHandler(item);
              }
            }}
            selected={formatDate(item) === renderAct && item.id === current?.id}
            value={formatDate(item)}>
            {formatDate(item)}
          </MenuItem>
        );
      });

    return data.actList
      .filter((_) => !removed.includes(_.id))
      .map((item) => {
        return (
          <MenuItem
            key={item.id}
            onClick={() => {
              if (current?.id !== item.id) {
                changeModalHandler(item);
              }
            }}
            selected={formatDate(item) === renderAct && item.id === current?.id}
            value={formatDate(item)}>
            {formatDate(item)}
          </MenuItem>
        );
      });
  }, [methods.watch('removed.acts')]);
  // const renderActs = useMemo(() => {
  //   const removed = (methods.getValues('removed.acts') || []).map((_) => _?.id);
  //   if (!data?.estimateActs) return [];
  //   if (!removed.length) return data.estimateActs;
  //
  //   return data.estimateActs.map((g) => {
  //     return { ...g, acts: g.acts.filter((_) => !removed.includes(_.id)) };
  //   });
  // }, [methods.watch('removed.acts')]);

  const isOneChange = useRef(false);

  useEffect(() => {
    setSelectedMenu(isExecution ? 'act' : 'nrsp');
    methods.reset({
      indexes: [],
      nrsp: [],
      toTotals: [],
      limitedCosts: {},
      acts: [],
      synchronized: false,
      removed: {
        indexes: [],
        nrsp: [],
        toTotals: [],
        limitedCosts: [],
        acts: []
      },
      created: {
        indexes: {},
        toTotals: {}
      }
    });
    isOneChange.current = false;
  }, [isExecution, open]);

  const setSelectedMenuHandler = useCallback((value: SideMenuNames) => {
    setSelectedMenu(value);
  }, []);

  const disabled = useCallback(() => {
    /* кэфы к итогам сметы */
    const toTotals = methods.getFieldState('toTotals').isDirty;

    /* индексы изменения сметной стоимости */
    const indexes = methods.getFieldState('indexes').isDirty;

    /* кэфы НР и СП */
    const nrsp = methods.getFieldState('nrsp').isDirty;

    /* Акты */
    // const acts = methods.getFieldState('acts').isDirty;

    /* Галочка "Применить параметры как в расчете" */
    const sync = methods.getFieldState('synchronized').isDirty;

    /* Лимитированные затраты */
    const limit = methods.getFieldState('limitedCosts').isDirty;

    /* Лимитированные затраты к УДАЛЕНИЮ */
    const removedLimitedCosts =
      methods.formState.dirtyFields.removed?.limitedCosts;

    /* кэфы НР и СП к УДАЛЕНИЮ */
    const removedIndexes = methods.formState.dirtyFields.removed?.indexes;

    /* кэфы к итогам сметы к УДАЛЕНИЮ */
    const removedToTotals = methods.formState.dirtyFields.removed?.toTotals;

    /* акты к УДАЛЕНИЮ */
    // const removedActs = methods.getValues('removed.acts');

    /* Данные к СОЗДАНИЮ */
    const created = methods.getValues('created');

    let limitedCostsFlag = limit;

    let createdFlag = false;

    /*  */
    if (created?.indexes) {
      let key: keyof typeof created.indexes;
      for (key in created.indexes) {
        const obj = created.indexes[key].filter((_) => !!_);
        if (obj.length > 1) {
          createdFlag = true;
          break;
        }
      }
    }
    if (created?.toTotals) {
      let key: keyof typeof created.toTotals;
      for (key in created.toTotals) {
        const obj = created.toTotals[key].filter((_) => !!_);
        if (obj.length > 1) {
          createdFlag = true;
          break;
        }
      }
    }
    if (removedLimitedCosts) {
      limitedCostsFlag = true;
    }

    return {
      other:
        !toTotals &&
        !indexes &&
        !nrsp &&
        !limitedCostsFlag &&
        !createdFlag &&
        !removedIndexes &&
        !removedToTotals &&
        !sync,
      full:
        !toTotals &&
        !indexes &&
        !nrsp &&
        !limitedCostsFlag &&
        !createdFlag &&
        !removedIndexes &&
        !removedToTotals &&
        // !removedActs?.length &&
        // !acts &&
        !sync
    };

    // return disabled;
  }, [
    methods.formState.dirtyFields.removed,
    methods.formState.dirtyFields.created,
    isOneChange.current
  ]);

  /**
   * Эффект следит за изменениями в форме и при необходимости
   * снимает галочку "Применить параметры как в расчете"
   */
  useEffect(() => {
    const syncDefault = methods.formState.defaultValues?.synchronized;

    if (syncDefault) {
      if (!disabled().other) {
        if (!isOneChange.current) {
          isOneChange.current = true;
          methods.setValue('synchronized', false, {
            shouldValidate: false,
            shouldDirty: false
          });
        }
      } else {
        if (!methods.getValues('synchronized')) {
          isOneChange.current = false;
          methods.setValue('synchronized', syncDefault, {
            shouldValidate: false,
            shouldDirty: false
          });
        }
      }
    }
  }, [
    disabled(),
    isOneChange.current,
    methods.formState.defaultValues?.synchronized
  ]);

  /* Подтверждение закрытия формы */
  const closeModalHandler = useCallback(() => {
    if (!disabled().full) {
      openConfirm();
      return;
    }
    onClose();

    setTimeout(() => {
      methods.reset();
      setSelectedMenu(isExecution ? 'act' : 'nrsp');
    }, 300);
  }, [disabled, isExecution]);

  const { openConfirm, ConfirmDialog } = useConfirmDialog({
    handleConfirm: (confirm) => {
      if (confirm) onClose();
    }
  });

  const { openConfirm: openChangeConfirm, ConfirmDialog: ChangeConfirmDialog } =
    useConfirmDialog({
      handleConfirm: (confirm, fn) => {
        if (confirm) {
          fn?.();
        }
      }
    });

  const changeModalHandler = useCallback(
    (act: ActList) => {
      if (!disabled().full) {
        openChangeConfirm(() => setCurrent?.(act));
        return;
      }
      setCurrent?.(act);
    },
    [disabled, setCurrent]
  );

  useEffect(() => {
    if (!isFetching && !isFetchActs) {
      toggleLoaderActTab(false);
    }
  }, [isFetching, isFetchActs]);

  return (
    <StyledDialog open={open} maxWidth={false} fullWidth>
      <FormProvider {...methods}>
        <StyledDialogHeader>
          <Stack direction="row" alignItems="center" spacing={5}>
            <StyledBackButton
              variant="text"
              startIcon={<ArrowBack />}
              onClick={closeModalHandler}>
              Назад
            </StyledBackButton>
            <Typography variant="h1" color="primary.light">
              Изменение параметров расчета {isExecution ? 'актов' : 'сметы'}
            </Typography>
            {isExecution && current && (
              <>
                <SelectField>
                  Текущий акт:
                  <StyledSelect
                    smallText
                    hideBorder
                    width={240}
                    onClear={() => {
                      alert('clear');
                    }}
                    nullableValues={[renderAct]}
                    SelectProps={{
                      renderValue: () => {
                        const separator = ' — ';
                        const destructiveStr = renderAct.split(separator);
                        destructiveStr[1] = `по ${destructiveStr[1]}`;
                        return 'с ' + destructiveStr.join(separator);
                      },
                      MenuProps: {
                        sx: {
                          maxHeight: 500,
                          width: 240
                        }
                      },
                      value: renderAct,
                      placeholder: 'Выберите диапазон'
                    }}
                    value={renderAct}
                    placeholder={'Выберите диапазон'}
                    fullWidth
                    select>
                    {renderActs}
                  </StyledSelect>
                </SelectField>

                <Controller
                  render={({ field: { ref, ...props } }) => (
                    <SelectField
                      onChange={() => {
                        methods.setValue('synchronized', !props.value, {
                          shouldValidate: true
                        });
                      }}>
                      <Checkbox
                        {...props}
                        checked={props.value}
                        size={'small'}
                      />
                      Применить параметры как в расчете
                    </SelectField>
                  )}
                  name={'synchronized'}
                />
              </>
            )}
          </Stack>
          <Stack direction="row" spacing={1.5} height="36px">
            <StyledSuccessButton
              disabled={disabled().full || disabledLoader}
              color="success"
              form="parameters-form"
              type="submit"
              disableElevation>
              Применить
            </StyledSuccessButton>
          </Stack>
        </StyledDialogHeader>
        <ParametersForm
          actLoader={loaderActTab}
          confirm={(fn) => confirm(fn, () => toggleLoaderActTab(true))}
          isExecution={isExecution}
          setLoaderParent={changeDisabledLoader}
          selectedMenu={selectedMenu}
          closeWindow={onClose}
          onSetSelectedMenu={setSelectedMenuHandler}
        />
      </FormProvider>
      <ConfirmDialog />
      <ChangeConfirmDialog />
    </StyledDialog>
  );
};
export default React.memo(ParametersDialog);
// ?.filter((g) => g.acts.length)
// .map((g, idx) => {
//   return (
//     <React.Fragment key={'' + g.estimateName + g.estimateID}>
//       <StyledTooltip
//         PopperProps={{
//           disablePortal: false,
//         }}
//         title={g.estimateName}>
//         <Typography
//           style={{ cursor: 'default' }}
//           whiteSpace={'nowrap'}
//           textOverflow={'ellipsis'}
//           width={'100%'}
//           overflow={'hidden'}
//           color={({ palette: { primary } }) => primary.main}
//           p={1}>
//           {g.estimateName || 'Общие'}
//         </Typography>
//       </StyledTooltip>
//       {g?.acts?.map((item) => {
//         return (
//           <MenuItem
//             key={item.id}
//             onClick={() => {
//               if (current?.id !== item.id) {
//                 changeModalHandler(item);
//               }
//             }}
//             selected={formatDate(item) === renderAct && item.id === current?.id}
//             value={formatDate(item)}>
//             {formatDate(item)}
//           </MenuItem>
//         );
//       })}
//       {idx !== (data?.estimateActs?.length || 1) - 1 && <Divider />}
//     </React.Fragment>
//   );
// })
