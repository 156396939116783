import { Button, MenuItem, Stack, Typography } from '@mui/material';
import { api } from 'api/api';
import { useWS, useWSLSR, WSRequestBodyLSRUpload } from 'api/web-socket';
import { Drawer } from 'components/Drawer';
import UploadForm, { uploadFileTypes } from 'components/UploadForm';
import { Form, FormikProvider } from 'formik';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { useForm } from 'hooks/useForm';
import { useProjectId } from 'hooks/useProjectId';
import { useSnackbar } from 'notistack';
import React, { FC, useCallback, useMemo } from 'react';
import { useAppDispatch, useTypedSelector } from 'store/store';
import { LSRFileStatus, lsrFileStatusNames } from 'types';
import {
  StyledFolderIcon,
  StyledStatusSelect,
  UploadLSRFilesDrawerProps,
  UploadLSRSocketFilesDrawer
} from '.';
import { statusOptions } from '../Table/Table.constants';
import { getWsState, setField } from '../../../../store/slices/ws';
import style from '../../../../components/UploadForm/EmptyProjectStart.module.scss';
import {
  UploadText,
  UploadTitle,
  UploadWrapper
} from '../../../../components/UploadForm/style';
import { Link } from 'react-router-dom';

export const UploadLSRFilesDrawer: FC<UploadLSRFilesDrawerProps> = (
  props
): JSX.Element => {
  const dispatch = useAppDispatch();

  const {
    lsr: {
      progressPercent,
      files,
      isListFiles,
      progressTime,
      error,
      errorData,
      inProgress,
      isOpenSnackLsr: open,
      targetDrawer,
      isStarted,
      statusFiles
    }
  } = useTypedSelector(getWsState);

  const projectID = useProjectId().toString();

  const { connectionStatus } = useWS();

  const {
    uploadFileLsr,
    uploadFileLsrProgress,
    uploadFileLsrClick,
    uploadFileLsrStatus,
    uploadFileLsrError,
    clearAll,
    clearUploadFileLsrError
  } = useWSLSR();

  const isUploaded = useMemo(
    () => !!uploadFileLsrProgress || uploadFileLsrClick,
    [uploadFileLsrProgress, uploadFileLsrClick]
  );

  const onSubmit = (values: UploadLSRSocketFilesDrawer) => {
    if (connectionStatus !== 'Open') {
      enqueueSnackbar(
        'Установка соединения. Повторите попытку через несколько секунд.',
        {
          variant: 'error',
          autoHideDuration: 15000
        }
      );
    } else {
      if (values.files || files?.length) {
        const fileWS: WSRequestBodyLSRUpload = {
          projectID: +projectID,
          files: files ?? [],
          status: values.status ?? undefined
        };

        uploadFileLsr(fileWS);
        dispatch(
          setField({
            isListFiles: false,
            isStarted: true,
            inProgress: true,
            progressTime: '00:00:10',
            progressPercent: 0.1
          })
        );
      }
    }
  };

  const { formik } = useForm({
    initialValues: {
      files: files ?? null,
      status: files ? statusFiles : null
    },
    enableReinitialize: true,
    onSubmit
  });

  const resetAndCloseDrawer = useCallback(
    (f?: boolean) => {
      props.onClose();

      if (f) {
        setTimeout(() => {
          formik.resetForm();
          clearAll();
          setTimeout(() => {
            dispatch(setField());
          }, 2000);
        }, 300);
      }
      // else{
      // if (targetDrawer?.includes(projectID) && !isStarted) {
      // }
      // }
    },
    [formik, props, targetDrawer, projectID, isStarted]
  );

  const handleRefresh = useCallback(() => {
    dispatch(api.util.invalidateTags(['LSR', 'ProjectDashboard']));

    if (targetDrawer?.includes(projectID)) {
      resetAndCloseDrawer(true);
    }
  }, [dispatch, resetAndCloseDrawer, projectID]);

  const { enqueueSnackbar } = useSnackbar();

  const { openConfirm, ConfirmDialog } = useConfirmDialog({
    handleConfirm: (confirm) => {
      confirm && resetAndCloseDrawer(true);
    }
  });

  const closeConfirmDrawer = () => {
    if (isUploaded) {
      resetAndCloseDrawer();
    } else {
      formik.dirty ? openConfirm() : resetAndCloseDrawer(true);
    }
  };

  // useEffect(() => {
  //   if (uploadFileLsrProgress && props.open) {
  //     dispatch(setField({
  //       progressTime: uploadFileLsrProgress.remaining,
  //       progressPercent: Math.floor(uploadFileLsrProgress.percent * 100),
  //       inProgress: true
  //     }));
  //   }
  // }, [uploadFileLsrProgress,props.open]);
  //
  // useEffect(() => {
  //   if (uploadFileLsrStatus && props.open) {
  //     dispatch(setField({
  //       errorData: null,
  //       progressPercent: 0,
  //       progressTime: '',
  //       inProgress:false,
  //     }));
  //
  //     clearAll();
  //     handleRefresh();
  //   }
  // }, [clearAll, handleRefresh, uploadFileLsrStatus]);
  //
  // useEffect(() => {
  //   if (uploadFileLsrError && props.open) {
  //
  //     const errorNames = uploadFileLsrError.withErrors.map(erFile => erFile.name);
  //     const filteredState = !files ? null : files.filter(el => errorNames.includes(el.name));
  //     formik.setFieldValue('files', filteredState);
  //     dispatch(setField({
  //       error: true,
  //       errorData: uploadFileLsrError,
  //       progressTime: '',
  //       progressPercent: 0,
  //       inProgress:false,
  //     ...(!isStarted && location.pathname === targetDrawer&&{files: filteredState})
  //     }));
  //
  //     uploadFileLsrError?.uploaded?.length && dispatch(api.util.invalidateTags(['LSR', 'ProjectDashboard']));
  //   }
  // }, [uploadFileLsrError]);
  //
  // useEffect(() => {
  //   if (isDone) {
  //     console.log('ws lsr done')
  //     dispatch(setField({
  //       isStarted:false,
  //     }))
  //   }
  // }, [isDone]);
  console.log(files);
  return (
    <>
      <Drawer
        title="Загрузить документы"
        open={props.open}
        onClose={closeConfirmDrawer}>
        <FormikProvider value={formik}>
          <Stack p={2.5} component={Form} height="100%" overflow="auto">
            <Stack direction="row" alignItems="center" spacing={2.5}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <StyledFolderIcon />
                <Typography variant="body2">Статус:</Typography>
              </Stack>
              <StyledStatusSelect
                value={statusFiles}
                displayEmpty
                disabled={inProgress}
                fullWidth
                renderValue={(selected) =>
                  selected === null
                    ? 'Выберите статус'
                    : lsrFileStatusNames[selected]
                }
                onChange={(e) => {
                  formik.setFieldValue('status', e.target.value);
                  dispatch(
                    setField({
                      statusFiles: (e.target.value as LSRFileStatus) ?? null
                    })
                  );
                }}>
                {statusOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </StyledStatusSelect>
            </Stack>
            {(targetDrawer !== location.pathname && inProgress) ||
            (targetDrawer !== location.pathname && files?.length) ? (
              <main className={style.main_awd}>
                <div className={style.awd_container}>
                  <div className={style.awd_loader}>
                    <UploadWrapper
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                      isPointer={true}>
                      <UploadTitle variant="body2">
                        Процесс загрузки запущен в другом проекте
                      </UploadTitle>
                      <UploadText>
                        {error && files?.length ? (
                          <>
                            при загрузке файлов возникли проблемы{' '}
                            <Link
                              to={targetDrawer!}
                              onClick={() =>
                                dispatch(setField({ isOpenSnackLsr: false }))
                              }>
                              нажмите
                            </Link>{' '}
                            чтобы посмотреть
                          </>
                        ) : (
                          <Link
                            to={targetDrawer!}
                            onClick={() =>
                              dispatch(setField({ isOpenSnackLsr: false }))
                            }>
                            Перейти в проект
                          </Link>
                        )}
                      </UploadText>
                    </UploadWrapper>
                  </div>
                </div>
              </main>
            ) : (
              <UploadForm
                handleOnReload={clearUploadFileLsrError}
                errorsMSG={errorData}
                mode="xml"
                format={uploadFileTypes.xml}
                error={error}
                isLoading={inProgress}
                setError={(f) => dispatch(setField({ error: f }))}
                setFormData={undefined}
                isWS={true}
                // setFilesData={(files)=>dispatch(setField({key:'files',value:files}))}
                setFilesData={(files) => {
                  dispatch(setField({ files: files }));
                }}
                wsProgress={progressPercent}
                wsTime={progressTime}
                filesData={files}
                isListFiles={isListFiles}
                setIsListFiles={(f) => dispatch(setField({ isListFiles: f }))}
              />
            )}
            <Stack direction="row" spacing={2} mt="auto">
              <Button
                color="success"
                type="submit"
                disabled={!files || inProgress}
                fullWidth>
                Обработать
              </Button>
              <Button fullWidth onClick={closeConfirmDrawer}>
                Закрыть
              </Button>
            </Stack>
          </Stack>
        </FormikProvider>
      </Drawer>
      <ConfirmDialog />
    </>
  );
};
