import {
  ColDef,
  ColGroupDef,
  NewValueParams,
  ValueGetterParams,
  ValueSetterParams
} from 'ag-grid-community';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  ExecutionCalculationRimData,
  HandbookExecutionRowPartData,
  HandbookExecutionTreeData,
  PartExecutionRim
} from 'types';
import { generateNameHeader } from '../../Calculations/components/Accomplishment/helper';
import { AgGridReact } from 'ag-grid-react';

function generateChildren() {}

export function generateColumnsParts(
  parts: PartExecutionRim[],
  start: number,
  columnsWidth?: { [key: string]: number }
): (
  | ColDef<ExecutionCalculationRimData>
  | ColGroupDef<ExecutionCalculationRimData>
)[] {
  return (
    parts?.reduce(
      (
        acc: (
          | ColDef<ExecutionCalculationRimData>
          | ColGroupDef<ExecutionCalculationRimData>
        )[],
        part,
        idx
      ) => {
        let headerName = '';
        let ichActHHeader = false;
        switch (part?.type) {
          case 'total':
            headerName = 'Всего';
            break;
          case 'executed':
            headerName = 'Выполнено';
            break;
          case 'rest':
            headerName = 'Остаток';
            break;
          default:
            ichActHHeader = true;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            headerName = generateNameHeader(part.startDate!, part.endDate!);
            break;
        }
        const startIndex = start * (idx + 1);
        console.log('dynamicRows', part.dynamicRows);
        acc.push({
          field: 'parts',
          headerName,
          headerClass: (params: any) =>
            part.actID === params?.context?.current?.id
              ? 'header-top-border'
              : '',
          headerGroupComponent: 'headerComponent',
          // suppressMovable: false,
          marryChildren: true,
          lockPinned: true,
          suppressMovable: true,
          children: [
            ...(part.type !== 'total'
              ? [
                  {
                    field: `parts.${idx}.quantity.total`,
                    headerName: 'Кол-во',
                    sortIndex: startIndex + 1,
                    cellClass: (params: any) =>
                      part.actID === params?.context?.current?.id
                        ? `header-left-border ${part?.type}`
                        : part?.type,
                    headerClass: (params: any) =>
                      part.actID === params?.context?.current?.id
                        ? 'header-left-border'
                        : '',
                    // columnGroupShow: 'closed',
                    width: columnsWidth?.[`parts.${idx}.quantity.total`] || 120,
                    minWidth: 120,
                    lockPinned: true,
                    cellRendererParams: {
                      decimalMax: 6,
                      idx
                    },
                    cellRenderer: 'cellRenderer',
                    onCellValueChanged: (
                      params: NewValueParams<
                        HandbookExecutionTreeData,
                        HandbookExecutionRowPartData['quantity']
                      >
                    ) => {
                      params?.context?.onUpdate(
                        params,
                        part?.actID,
                        params.data.id
                      );
                    },
                    valueGetter: (
                      params: ValueGetterParams<
                        ExecutionCalculationRimData,
                        any
                      >
                    ) => {
                      return params?.data?.parts?.[idx]?.quantity.total;
                    },
                    valueSetter: (
                      params: ValueSetterParams<
                        ExecutionCalculationRimData,
                        any
                      >
                    ) => {
                      const updatedData = {
                        ...params.data,
                        parts: params.data.parts.map((e) =>
                          e.actID === part.actID
                            ? {
                                ...e,
                                quantity: {
                                  ...e.quantity,
                                  total: params.newValue
                                }
                              }
                            : e
                        )
                      };
                      params.api.applyTransaction({ update: [updatedData] });
                      return true;
                    },
                    editable: ({ data, context }: any) => {
                      return ichActHHeader
                        ? data?.type === 'level' &&
                            context?.current?.id === part?.actID
                        : false;
                    }
                  } as any
                ]
              : ([
                  {
                    field: `parts.${idx}.quantity.total`,
                    headerName: 'Кол-во',
                    sortIndex: startIndex + 1,
                    columnGroupShow: 'close',
                    cellClass: (params: any) =>
                      part.actID === params?.context?.current?.id
                        ? `header-left-border ${part?.type}`
                        : part?.type,
                    headerClass: (params: any) =>
                      part.actID === params?.context?.current?.id
                        ? 'header-left-border'
                        : '',
                    // columnGroupShow: 'closed',
                    width: columnsWidth?.[`parts.${idx}.quantity.total`] || 120,
                    minWidth: 120,
                    lockPinned: true,
                    cellRendererParams: {
                      decimalMax: 6,
                      idx
                    },
                    cellRenderer: 'cellRenderer'
                  },
                  {
                    headerName: 'Кол-во',
                    columnGroupShow: 'open',
                    marryChildren: true,
                    lockPinned: true,
                    children: [
                      {
                        field: 'quantity.perUnit',
                        headerName: 'на ед. изм.',
                        sortIndex: startIndex + 2,
                        cellRendererParams: {
                          decimalMax: 6,
                          idx
                        },
                        lockPinned: true,
                        cellRenderer: 'cellRenderer',
                        width: columnsWidth?.['quantity.perUnit'] || 100,
                        minWidth: 100,
                        cellClass: part?.type
                      },
                      {
                        field: 'quantity.coeff',
                        headerName: 'коэффициенты',
                        sortIndex: startIndex + 3,
                        cellRendererParams: {
                          decimalMax: 6,
                          idx
                        },
                        lockPinned: true,
                        cellRenderer: 'cellRenderer',
                        width: columnsWidth?.['quantity.coeff'] || 100,
                        minWidth: 100,
                        cellClass: part?.type
                      },
                      {
                        field: 'quantity.totalCoeff',
                        headerName: 'всего с учетом к-т',
                        sortIndex: startIndex + 4,
                        cellRendererParams: {
                          decimalMax: 6,
                          idx
                        },
                        lockPinned: true,
                        cellRenderer: 'cellRenderer',
                        width: columnsWidth?.['quantity.totalCoeff'] || 136,
                        minWidth: 136,
                        cellClass: part?.type,
                        onCellValueChanged: (
                          params: NewValueParams<
                            HandbookExecutionTreeData,
                            HandbookExecutionRowPartData['quantity']
                          >
                        ) => {
                          console.log(params);
                          params?.context?.onUpdate(
                            params,
                            part?.actID,
                            params.data.id
                          );
                        },
                        valueGetter: (
                          params: ValueGetterParams<
                            ExecutionCalculationRimData,
                            any
                          >
                        ) => {
                          return params?.data?.parts?.[idx]?.quantity.total;
                        },
                        valueSetter: (
                          params: ValueSetterParams<
                            ExecutionCalculationRimData,
                            any
                          >
                        ) => {
                          const updatedData = {
                            ...params.data,
                            parts: params.data.parts.map((e) =>
                              e.actID === part.actID
                                ? {
                                    ...e,
                                    quantity: {
                                      ...e.quantity,
                                      total: params.newValue
                                    }
                                  }
                                : e
                            )
                          };
                          params.api.applyTransaction({
                            update: [updatedData]
                          });
                          return true;
                        },
                        editable: ({ data, context }: any) => {
                          // return data?.type === 'RATE';
                          return ichActHHeader
                            ? data?.type === 'level' &&
                                context?.current?.id === part?.actID
                            : false;
                        }
                      }
                    ]
                  }
                ] as any)),
            {
              field:
                `parts.${idx}.${part.dynamicRows.length ? 'total' : 'cost.total'}` as keyof ExecutionCalculationRimData,
              headerName: part.dynamicRows.length
                ? 'Всего стоимость'
                : `Всего в текущем уровне цен`,
              sortIndex: startIndex + 5,

              cellClass: (params: any) =>
                part.actID === params?.context?.current?.id
                  ? `header-right-border ${part?.type}`
                  : part?.type,
              headerClass: (params: any) =>
                part.actID === params?.context?.current?.id
                  ? 'header-right-border'
                  : '',
              columnGroupShow: 'closed',
              width:
                columnsWidth?.[
                  `parts.${idx}.${part.dynamicRows.length ? 'total' : 'cost.total'}`
                ] || 136,
              minWidth: 136,
              lockPinned: true,
              cellRendererParams: {
                decimalMax: 2,
                idx
              },
              cellRenderer: 'cellRenderer'
            },
            {
              headerName: 'Сметная стоимость, руб.',
              columnGroupShow: 'open',
              marryChildren: true,
              lockPinned: true,
              children: [
                {
                  field: `parts.${idx}.cost.basis.perUnit`,
                  headerName: 'на ед. изм. в базисном уровне цен',
                  sortIndex: startIndex + 6,

                  cellRendererParams: {
                    decimalMax: 2,
                    idx
                  },
                  lockPinned: true,
                  cellRenderer: 'cellRenderer',
                  width:
                    columnsWidth?.[`parts.${idx}.cost.basis.perUnit`] || 160,
                  minWidth: 160,
                  cellClass: part?.type
                },
                {
                  field: `parts.${idx}.cost.basis.multiplier`,
                  headerName: 'индекс',
                  sortIndex: startIndex + 7,
                  cellRendererParams: {
                    decimalMax: 2,
                    idx
                  },
                  lockPinned: true,
                  cellRenderer: 'cellRenderer',
                  width:
                    columnsWidth?.[`parts.${idx}.cost.basis.multiplier`] || 100,
                  minWidth: 100,
                  cellClass: part?.type
                },
                {
                  field: `parts.${idx}.cost.current.perUnit`,
                  headerName: 'на ед. изм. в текущем уровне цен',
                  sortIndex: startIndex + 8,
                  cellRendererParams: {
                    decimalMax: 2,
                    idx
                  },
                  lockPinned: true,
                  cellRenderer: 'cellRenderer',
                  width:
                    columnsWidth?.[`parts.${idx}.cost.current.perUnit`] || 136,
                  minWidth: 136,
                  cellClass: part?.type
                },
                {
                  field: `parts.${idx}.cost.current.multiplier`,
                  headerName: 'коэффициенты',
                  sortIndex: startIndex + 9,
                  lockPinned: true,
                  cellRendererParams: {
                    decimalMax: 2,
                    idx
                  },
                  cellRenderer: 'cellRenderer',
                  width:
                    columnsWidth?.[`parts.${idx}.cost.current.multiplier`] ||
                    103,
                  minWidth: 103,
                  cellClass: part?.type
                },
                {
                  field: `parts.${idx}.cost.total`,
                  headerName: `${part?.dynamicRows.length ? 'итого' : 'всего'} в текущем уровне цен`,
                  sortIndex: startIndex + 10,
                  cellRendererParams: {
                    decimalMax: 2,
                    idx
                  },
                  lockPinned: true,
                  cellRenderer: 'cellRenderer',
                  width: columnsWidth?.[`parts.${idx}.cost.total`] || 160,
                  minWidth: 160,
                  cellClass: part?.type
                }
              ]
            },
            ...part.dynamicRows
              .map(
                (row, idxD) =>
                  ({
                    field:
                      `parts.${idx}.dynamicRows.${idxD}.value` as keyof ExecutionCalculationRimData,
                    columnGroupShow: 'open',
                    // width: 158,
                    // maxWidth: 158,
                    headerComponent: 'customHeaderMultiline',
                    sortIndex: startIndex + idxD + 11,
                    // width: 158,
                    headerName: `${row.title ?? ''}`,
                    lockPinned: true,
                    cellRenderer: 'cellRenderer',
                    width:
                      columnsWidth?.[
                        `parts.${idx}.dynamicRows.${idxD}.value`
                      ] || 136,
                    minWidth: 136,
                    cellClass: part?.type,
                    cellRendererParams: {
                      float: 'right',
                      decimalMax: 6,
                      idx
                    }
                  }) as any
              )
              .concat([
                {
                  field:
                    `parts.${idx}.total` as keyof ExecutionCalculationRimData,
                  headerName: 'Всего стоимость',
                  sortIndex: startIndex + part.dynamicRows.length + 12,
                  columnGroupShow: 'open',
                  cellRendererParams: {
                    decimalMax: 6,
                    idx
                  },
                  lockPinned: true,
                  cellRenderer: 'cellRenderer',
                  width: columnsWidth?.[`parts.${idx}.total`] || 160,
                  minWidth: 160,
                  cellClass: part?.type
                } as any,
                {
                  field: `parts.${idx}.tz`,
                  headerName: 'ТЗ',
                  sortIndex: startIndex + part.dynamicRows.length + 13,
                  columnGroupShow: 'open',
                  cellRendererParams: {
                    decimalMax: 2,
                    idx
                  },
                  lockPinned: true,
                  cellRenderer: 'cellRenderer',
                  width: columnsWidth?.[`parts.${idx}.tz`] || 136,
                  minWidth: 136,
                  cellClass: part?.type
                },
                {
                  field: `parts.${idx}.tzm`,
                  headerName: 'ТЗМ',
                  sortIndex: startIndex + part.dynamicRows.length + 14,
                  cellClass: (params: any) =>
                    part.actID === params?.context?.current?.id
                      ? `header-right-border ${part?.type}`
                      : part?.type,
                  headerClass: (params: any) =>
                    part.actID === params?.context?.current?.id
                      ? 'header-right-border'
                      : '',
                  columnGroupShow: 'open',
                  cellRendererParams: {
                    decimalMax: 2,
                    idx
                  },
                  lockPinned: true,
                  cellRenderer: 'cellRenderer',
                  width: columnsWidth?.[`parts.${idx}.tzm`] || 136,
                  minWidth: 136
                }
              ] as any)
          ]
          // ] as any)
          // : ([
          //     {
          //       field: `parts.${idx}.quantity.total`,
          //       headerName: 'Кол-во',
          //       columnGroupShow: 'closed',
          //       width: 120,
          //       minWidth: 120,
          //       cellRendererParams: {
          //         decimalMax: 6,
          //         idx
          //       },
          //       lockPinned: true,
          //       cellRenderer: 'cellRenderer',
          //       onCellValueChanged: (
          //         params: NewValueParams<ExecutionCalculationRimData, any>
          //       ) => {
          //         console.log(params);
          //         params?.context?.onUpdate(
          //           params,
          //           part?.actID,
          //           params.data.id
          //         );
          //       },
          //       valueGetter: (
          //         params: ValueGetterParams<
          //           ExecutionCalculationRimData,
          //           any
          //         >
          //       ) => {
          //         return params?.data?.parts?.[idx]?.quantity.total;
          //       },
          //       valueSetter: (
          //         params: ValueSetterParams<
          //           ExecutionCalculationRimData,
          //           any
          //         >
          //       ) => {
          //         const updatedData = {
          //           ...params.data,
          //           parts: params.data.parts.map((e) =>
          //             e.actID === part.actID
          //               ? {
          //                   ...e,
          //                   quantity: {
          //                     ...e.quantity,
          //                     total: params.newValue
          //                   }
          //                 }
          //               : e
          //           )
          //         };
          //         params.api.applyTransaction({ update: [updatedData] });
          //         return true;
          //       },
          //       editable: ({ data, context }: any) => {
          //         // return;
          //         // data?.type === 'RATE';
          //         return ichActHHeader
          //           ? data?.type === 'level' &&
          //               context?.current?.id === part?.actID
          //           : false;
          //       }
          //     },
          //     {
          //       field:
          //         `parts.${idx}.${part.dynamicRows.length ? 'total' : 'cost.total'}` as keyof ExecutionCalculationRimData,
          //       headerName: part.dynamicRows.length
          //         ? 'Всего стоимость'
          //         : `Всего в текущем уровне цен`,
          //       sortIndex: startIndex + 15,
          //       columnGroupShow: 'closed',
          //       width: 136,
          //       minWidth: 136,
          //       cellRendererParams: {
          //         decimalMax: 6,
          //         idx
          //       },
          //       lockPinned: true,
          //       cellRenderer: 'cellRenderer'
          //     },
          //     {
          //       headerName: 'Кол-во',
          //       columnGroupShow: 'open',
          //       marryChildren: true,
          //       lockPinned: true,
          //       children: [
          //         {
          //           field: 'quantity.perUnit',
          //           headerName: 'на ед. изм.',
          //           sortIndex: startIndex + 16,
          //           cellRendererParams: {
          //             decimalMax: 6,
          //             idx
          //           },
          //           lockPinned: true,
          //           cellRenderer: 'cellRenderer',
          //           width: 100,
          //           minWidth: 100
          //         },
          //         {
          //           field: 'quantity.coeff',
          //           headerName: 'коэффициенты',
          //           sortIndex: startIndex + 17,
          //           cellRendererParams: {
          //             decimalMax: 6,
          //             idx
          //           },
          //           lockPinned: true,
          //           cellRenderer: 'cellRenderer',
          //           width: 100,
          //           minWidth: 100
          //         },
          //         {
          //           field: 'quantity.totalCoeff',
          //           headerName: 'всего с учетом к-т',
          //           sortIndex: startIndex + 18,
          //           cellRendererParams: {
          //             decimalMax: 6,
          //             idx
          //           },
          //           lockPinned: true,
          //           cellRenderer: 'cellRenderer',
          //           width: 136,
          //           minWidth: 136,
          //           onCellValueChanged: (
          //             params: NewValueParams<
          //               HandbookExecutionTreeData,
          //               HandbookExecutionRowPartData['quantity']
          //             >
          //           ) => {
          //             console.log(params);
          //             params?.context?.onUpdate(
          //               params,
          //               part?.actID,
          //               params.data.id
          //             );
          //           },
          //           valueGetter: (
          //             params: ValueGetterParams<
          //               ExecutionCalculationRimData,
          //               any
          //             >
          //           ) => {
          //             return params?.data?.parts?.[idx]?.quantity.total;
          //           },
          //           valueSetter: (
          //             params: ValueSetterParams<
          //               ExecutionCalculationRimData,
          //               any
          //             >
          //           ) => {
          //             const updatedData = {
          //               ...params.data,
          //               parts: params.data.parts.map((e) =>
          //                 e.actID === part.actID
          //                   ? {
          //                       ...e,
          //                       quantity: {
          //                         ...e.quantity,
          //                         total: params.newValue
          //                       }
          //                     }
          //                   : e
          //               )
          //             };
          //             params.api.applyTransaction({
          //               update: [updatedData]
          //             });
          //             return true;
          //           },
          //           editable: ({ data, context }: any) => {
          //             // return data?.type === 'RATE';
          //             return ichActHHeader
          //               ? data?.type === 'level' &&
          //                   context?.current?.id === part?.actID
          //               : false;
          //           }
          //         }
          //       ]
          //     },
          //     {
          //       headerName: 'Сметная стоимость, руб.',
          //       columnGroupShow: 'open',
          //       lockPinned: true,
          //       marryChildren: true,
          //       children: [
          //         {
          //           field: `parts.${idx}.cost.basis.perUnit`,
          //           headerName:
          //             'на единицу измерения в базисном уровне цен',
          //           sortIndex: startIndex + 19,
          //           cellRendererParams: {
          //             decimalMax: 6,
          //             idx
          //           },
          //           lockPinned: true,
          //           cellRenderer: 'cellRenderer',
          //           width: 160,
          //           minWidth: 160
          //         },
          //         {
          //           field: `parts.${idx}.cost.basis.multiplier`,
          //           headerName: 'индекс',
          //           sortIndex: startIndex + 20,
          //           cellRendererParams: {
          //             decimalMax: 6,
          //             idx
          //           },
          //           lockPinned: true,
          //           cellRenderer: 'cellRenderer',
          //           width: 100,
          //           minWidth: 100
          //         },
          //         {
          //           field: `parts.${idx}.cost.current.perUnit`,
          //           headerName: 'на единицу измерения в текущем уровне цен',
          //           sortIndex: startIndex + 21,
          //           cellRendererParams: {
          //             decimalMax: 6,
          //             idx
          //           },
          //           lockPinned: true,
          //           cellRenderer: 'cellRenderer',
          //           width: 160,
          //           minWidth: 160
          //         },
          //         {
          //           field: `parts.${idx}.cost.current.multiplier`,
          //           headerName: 'коэффициенты',
          //           sortIndex: startIndex + 22,
          //           cellRendererParams: {
          //             decimalMax: 6,
          //             idx
          //           },
          //           lockPinned: true,
          //           cellRenderer: 'cellRenderer',
          //           width: 103,
          //           minWidth: 103
          //         },
          //         {
          //           field: `parts.${idx}.cost.total`,
          //           headerName: `${part?.dynamicRows.length ? 'итого' : 'всего'} в текущем уровне цен`,
          //           sortIndex: startIndex + 23,
          //           cellRendererParams: {
          //             decimalMax: 6,
          //             idx
          //           },
          //           lockPinned: true,
          //           cellRenderer: 'cellRenderer',
          //           width: 160,
          //           minWidth: 160
          //         }
          //       ]
          //     },
          //     ...part.dynamicRows
          //       .map((row, idxD) => ({
          //         field:
          //           `parts.${idx}.dynamicRows.${idxD}.value` as keyof ExecutionCalculationRimData,
          //         columnGroupShow: 'open',
          //         // width: 158,
          //         // maxWidth: 158,
          //         headerComponent: 'customHeaderMultiline',
          //         sortIndex: startIndex + 24 + idxD,
          //         width: 136,
          //         minWidth: 136,
          //         headerName: `${row.title ?? ''}`,
          //         lockPinned: true,
          //         cellRenderer: 'cellRenderer',
          //         cellRendererParams: {
          //           float: 'right',
          //           decimalMax: 6,
          //           idx
          //         }
          //       }))
          //       .concat([
          //         {
          //           field:
          //             `parts.${idx}.total` as keyof ExecutionCalculationRimData,
          //           headerName: 'Всего стоимость',
          //           sortIndex: startIndex + part.dynamicRows.length + 25,
          //           columnGroupShow: 'open',
          //           cellRendererParams: {
          //             decimalMax: 6,
          //             idx
          //           },
          //           lockPinned: true,
          //           cellRenderer: 'cellRenderer',
          //           width: 160,
          //           minWidth: 160
          //         } as any,
          //         {
          //           field: `parts.${idx}.tz`,
          //           headerName: 'ТЗ',
          //           sortIndex: startIndex + part.dynamicRows.length + 26,
          //           columnGroupShow: 'open',
          //           cellRendererParams: {
          //             decimalMax: 2,
          //             idx
          //           },
          //           lockPinned: true,
          //           cellRenderer: 'cellRenderer',
          //           width: 136,
          //           minWidth: 136
          //         },
          //         {
          //           field: `parts.${idx}.tzm`,
          //           headerName: 'ТЗМ',
          //           sortIndex: startIndex + part.dynamicRows.length + 27,
          //           columnGroupShow: 'open',
          //           cellRendererParams: {
          //             decimalMax: 2,
          //             idx
          //           },
          //           lockPinned: true,
          //           cellRenderer: 'cellRenderer',
          //           width: 136,
          //           minWidth: 136
          //         }
          //       ])
          //   ] as any)
        });
        return acc;
      },
      []
    ) || []
  );
}

export const useTable = (
  exampleRow: ExecutionCalculationRimData | undefined,
  updateFlag: number,
  fetching: any,
  collapseRowsIds: Set<number | string>,
  agGrid: AgGridReact<ExecutionCalculationRimData> | null,
  columnsWidth?: { [key: string]: number }
): [
  (
    | ColDef<ExecutionCalculationRimData, any>
    | ColGroupDef<ExecutionCalculationRimData>
  )[],
  () => void
] => {
  const [flag, setFlag] = useState(true);
  const state = useRef<
    (
      | ColDef<ExecutionCalculationRimData, any>
      | ColGroupDef<ExecutionCalculationRimData>
    )[]
  >([
    {
      field: 'lvl',
      pinned: 'left',
      lockPinned: true,
      lockPosition: true,
      resizable: false,
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      suppressMovable: true,
      headerName: 'Ур',
      colSpan: (params) => {
        if (params.data?.type === 'limit-header') {
          return params?.columnApi?.getAllColumns()?.length || 1;
        }
        return params.node?.rowPinned === 'top' ? 1 : 0;
      },
      cellRenderer: 'cellRenderer',
      cellRendererParams: {
        isLevel: true,
        isExecution: true,
        hiddenRowIds: collapseRowsIds
      }
    },
    {
      field: 'number',
      pinned: 'left',
      width: columnsWidth?.number || 60,
      minWidth: 60,

      // maxWidth: 60,
      // minWidth: 60,
      suppressMovable: false,
      headerName: '№ п\\п',

      cellRenderer: 'cellRenderer',
      cellRendererParams: {
        float: 'center',
        isCustomPinned: true
      },
      colSpan: (params) => {
        return params.node?.rowPinned === 'top' ? 7 : 0;
      }
    },
    {
      field: 'lsr',
      pinned: 'left',
      width: columnsWidth?.lsr || 120,
      minWidth: 120,

      // maxWidth: 120,
      // minWidth: 120,
      suppressMovable: false,
      headerName: 'Шифр ЛСР',
      cellRenderer: 'cellRenderer',
      cellRendererParams: {
        float: 'left'
      }
    },
    {
      field: 'chapter',
      pinned: 'left',
      width: columnsWidth?.chapter || 180,
      minWidth: 180,

      // maxWidth: 180,
      // minWidth: 180,
      suppressMovable: false,
      headerName: 'Раздел',
      cellRenderer: 'cellRenderer',
      cellRendererParams: {
        float: 'left'
      }
    },
    {
      field: 'header',
      pinned: 'left',
      width: columnsWidth?.header || 120,
      minWidth: 120,

      // maxWidth: 120,
      // minWidth: 120,
      suppressMovable: false,
      headerName: 'Заголовок',
      cellRenderer: 'cellRenderer',
      cellRendererParams: {
        float: 'left'
      }
    },
    {
      field: 'code',
      pinned: 'left',
      width: columnsWidth?.code || 235,
      minWidth: 235,

      // maxWidth: 235,
      // minWidth: 235,
      suppressMovable: false,
      headerName: 'Код расценки',
      cellRenderer: 'cellRenderer',
      cellRendererParams: {
        float: 'left'
      }
    },
    {
      field: 'title',
      pinned: 'left',
      width: columnsWidth?.title || 400,
      minWidth: 400,
      // maxWidth: 400,
      // minWidth: 400,
      suppressMovable: false,
      headerName: 'Наименование',
      cellRenderer: 'cellRenderer',
      cellRendererParams: {
        float: 'left'
      }
    },

    {
      field: 'unit',
      width: columnsWidth?.unit || 120,
      minWidth: 120,
      // maxWidth: 120,
      // minWidth: 120,
      suppressMovable: false,
      headerName: 'Ед. изм.',
      cellRenderer: 'cellRenderer',
      cellRendererParams: {
        float: 'center'
      }
    },
    ...generateColumnsParts(exampleRow?.parts || [], 8, columnsWidth)
  ]);
  console.log('dynamicRows', exampleRow);
  // const [state, setState] = useState<
  //   (
  //     | ColDef<ExecutionCalculationRimData, any>
  //     | ColGroupDef<ExecutionCalculationRimData>
  //   )[]
  // >([]);
  const resetColumns = useCallback(() => {
    setFlag(true);
  }, []);
  // useEffect(() => {
  //   if (!fetching) {
  //     setFlag(true);
  //     // flag.current = true;
  //     state.current = [];
  //     // setState([]);
  //   }
  // }, [updateFlag, fetching, exampleRow]);

  useEffect(() => {
    if (exampleRow) {
      setFlag(false);
      // flag.current = false;
      console.log('reload', updateFlag);
      // const savedState = agGrid?.columnApi?.getColumnState();
      const groupState = agGrid?.columnApi?.getColumnGroupState();
      const columns: (
        | ColDef<ExecutionCalculationRimData, any>
        | ColGroupDef<ExecutionCalculationRimData>
      )[] = [
        {
          field: 'lvl',
          pinned: 'left',
          lockPinned: true,
          lockPosition: true,
          resizable: false,
          width: 100,
          minWidth: 100,
          maxWidth: 100,
          sortIndex: 1,
          suppressMovable: true,
          headerName: 'Ур',
          colSpan: (params) => {
            if (params.data?.type === 'limit-header') {
              return params?.columnApi?.getAllColumns()?.length || 1;
            }
            return params.node?.rowPinned === 'top' ? 1 : 0;
          },
          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            isLevel: true,
            isExecution: true,
            hiddenRowIds: collapseRowsIds
          }
        },
        {
          field: 'number',
          pinned: 'left',
          width: columnsWidth?.number || 60,
          minWidth: 60,

          // maxWidth: 60,
          // minWidth: 60,
          suppressMovable: false,
          headerName: '№ п\\п',
          sortIndex: 2,

          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            float: 'center',
            isCustomPinned: true
          },
          colSpan: (params) => {
            return params.node?.rowPinned === 'top' ? 7 : 0;
          }
        },
        {
          field: 'lsr',
          pinned: 'left',
          width: columnsWidth?.lsr || 120,
          minWidth: 120,

          // maxWidth: 120,
          // minWidth: 120,
          suppressMovable: false,
          headerName: 'Шифр ЛСР',
          sortIndex: 3,
          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            float: 'left'
          }
        },
        {
          field: 'chapter',
          pinned: 'left',
          width: columnsWidth?.chapter || 180,
          minWidth: 180,

          // maxWidth: 180,
          // minWidth: 180,
          suppressMovable: false,
          headerName: 'Раздел',
          sortIndex: 4,
          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            float: 'left'
          }
        },
        {
          field: 'header',
          pinned: 'left',
          width: columnsWidth?.header || 120,
          minWidth: 120,

          // maxWidth: 120,
          // minWidth: 120,
          suppressMovable: false,
          headerName: 'Заголовок',
          sortIndex: 5,
          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            float: 'left'
          }
        },
        {
          field: 'code',
          pinned: 'left',
          width: columnsWidth?.code || 235,
          minWidth: 235,

          // maxWidth: 235,
          // minWidth: 235,
          suppressMovable: false,
          headerName: 'Код расценки',
          sortIndex: 6,
          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            float: 'left'
          }
        },
        {
          field: 'title',
          pinned: 'left',
          width: columnsWidth?.title || 400,
          minWidth: 400,
          // maxWidth: 400,
          // minWidth: 400,
          suppressMovable: false,
          headerName: 'Наименование',
          sortIndex: 7,
          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            float: 'left'
          }
        },

        {
          field: 'unit',
          width: columnsWidth?.unit || 120,
          minWidth: 120,
          // maxWidth: 120,
          // minWidth: 120,
          suppressMovable: false,
          headerName: 'Ед. изм.',
          sortIndex: 8,
          cellRenderer: 'cellRenderer',
          cellRendererParams: {
            float: 'center'
          }
        },
        ...generateColumnsParts(exampleRow.parts, 8, columnsWidth)
      ];

      // setState(columns);
      const savedState = agGrid?.columnApi?.getColumnState();
      agGrid?.api.setColumnDefs(columns);
      console.log(
        'savedState',
        savedState?.map((el: any) => el.sortIndex)
      );
      console.log('savedState', savedState);
      agGrid?.columnApi.resetColumnState();
      savedState &&
        agGrid?.columnApi.applyColumnState({
          state: savedState.sort((a, b) =>
            (a.sortIndex || 0) < (b.sortIndex || 0) ? -1 : 1
          ),
          applyOrder: true
        });
      groupState && agGrid?.columnApi.setColumnGroupState(groupState);
      // state.current = columns;
      setTimeout(() => {});
    }
  }, [exampleRow, updateFlag, flag, collapseRowsIds]);
  return [state.current, resetColumns];
};
// export const CellRenderer = (
//   props: ICellRendererParams<ExecutionCalculationRimData, any, any>
// ) => {
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const { current } = props.context;
//   const { idx } = props.context;
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const editable = useMemo(
//     () =>
//       props?.data?.type === 'level' &&
//       (props?.data?.parts[idx]?.type === 'act' ||
//         props?.data?.parts[idx]?.type === 'range') &&
//       !!current &&
//       !!props?.data?.parts[idx]?.startDate &&
//       isSameDay(
//         new Date(current.startDate!),
//         new Date(props?.data?.parts[idx]?.startDate!)
//       ),
//     [props]
//   );
//
//   const handleConfirm = (confirm: boolean) => {
//     if (confirm) {
//       props.colDef!.editable = true;
//       props.api.startEditingCell({
//         rowIndex: props.node.rowIndex!,
//         colKey: props.column || ''
//       });
//     }
//   };
//
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const { ConfirmDialog: WarnDialog, openConfirm: openWarnDialog } =
//     useConfirmDialog({
//       title: 'Подтвердить внесение изменений?',
//       body: 'Данные будут изменены в закрытом периоде.',
//       handleConfirm
//     });
//
//   const value = props.data?.parts[idx]?.quantity;
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   // const renderValue = useMemo(() => {
//   //   const currentQuantity = props.data?.parts[idx];
//   //   let value: string | null = null;
//   //   if (
//   //     currentQuantity?.quantity &&
//   //     typeof currentQuantity?.quantity === 'number'
//   //   ) {
//   //     value = currentQuantity
//   //       ?.quantity!.toString()
//   //       .replace(/\./g, ',');
//   //   } else if (
//   //     currentQuantity?.quantity &&
//   //     typeof currentQuantity?.quantity === 'string'
//   //   ) {
//   //     value = (currentQuantity?.quantity as string).replace(
//   //       /\./g,
//   //       ','
//   //     );
//   //   }
//   //   if (props.data?.id === -1) {
//   //     value = null;
//   //   }
//   //   return value;
//   // }, [props]);
//
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   useEffect(() => {
//     if (current && current.status === 'CLOSED') {
//       props.colDef!.editable = false;
//     }
//   }, [props, current]);
//   return (
//     <Cell
//       click={() => {
//         if (current && current.status === 'CLOSED' && editable) {
//           openWarnDialog(() => {
//             console.log('confirm');
//           });
//         } else {
//           if (editable) {
//             props.colDef!.editable = true;
//             props.api.startEditingCell({
//               rowIndex: props.node.rowIndex!,
//               colKey: props.column?.getColId() || ''
//             });
//           }
//         }
//       }}
//       originData={
//         props.data?.type !== 'file'
//           ? value
//             ? replaceStringFromServer(String(value), decimalMaxTooltip)
//             : null
//           : null
//       }
//       float={'center'}
//       isNum
//       isError={errors[idx] || false}>
//       {props.data?.type !== 'file'
//         ? value
//           ? replaceStringFromServer(String(value), decimalMax)
//           : null
//         : null}
//       {/*{renderValue}*/}
//       <WarnDialog />
//     </Cell>
//   );
// };
export function isHaveColorRow(
  type:
    | 'row'
    | 'level'
    | 'file'
    | 'total'
    | 'limit'
    | 'limit-header'
    | null
    | undefined,
  idPart: number | undefined,
  err: any
) {
  console.log(type, idPart, err);
  return false;
  // if (type === 'file' || type === 'total' || !idPart) return false;
  // return err?.[idPart] || false;
}
export function checkError(parts: PartExecutionRim[]) {
  const result: { [key: string]: boolean } = {};
  let total = 0;
  console.log(parts);
  const temp = parts.reduce(
    (acc, act, index) => {
      if (!acc[index]) {
        acc[index] = {
          value: 0,
          type: '',
          total: 0
        };
        acc[index].type = act.type!;
        if (acc[index].type === 'total') {
          acc[index].value = 0;
          acc[index].total = act.quantity?.total ?? 0;
          total = acc[index].total;
        } else if (acc[index].type === 'act') {
          if (acc[index - 1]) {
            acc[index].value =
              acc[index - 1].value + (act.quantity?.total ?? 0);
            acc[index].total = total;
          }
        } else {
          acc[index].value = act.quantity.total ?? 0;
          acc[index].total = total;
        }
      }

      return acc;
    },
    {} as { [key: string]: { value: number; type: string; total: number } }
  );

  for (const index in temp) {
    const tempKey = Number(index);
    const currentPart = temp[tempKey];

    if (currentPart.type !== 'total') {
      if (currentPart.type !== 'rest') {
        if (currentPart.total > 0) {
          result[tempKey] = currentPart.value > currentPart.total;
        } else {
          result[tempKey] = currentPart.value < currentPart.total;
        }
      } else {
        if (currentPart.total > 0) {
          result[tempKey] = currentPart.value < 0;
        } else {
          result[tempKey] = currentPart.value > 0;
        }
      }
    }
  }
  return result;
}
