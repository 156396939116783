import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import useWebSocket, {
  ReadyState,
  resetGlobalState
} from 'react-use-websocket';

import { useRefreshTokenMutation } from 'api/auth';
import { useMutationHandlers } from 'hooks/useMutationHandlers';
import { SendJsonMessage } from 'react-use-websocket/dist/lib/types';
import { setCredentials } from 'store/slices/auth';
import { useAppDispatch, useTypedSelector } from 'store/store';
import {
  ConnectionStatusType,
  ProviderWSProps,
  SOCKET_URL_LSR,
  WSContextCalcProps,
  WSContextHandbookProps,
  WSContextLSRProps,
  WSContextProps,
  WSEventType,
  WSHandbookCopyProps,
  WSManeType,
  WSRequest,
  WSResponse,
  WSTaskStatus
} from '.';

export const WSContext = createContext<WSContextProps | null>(null);

export const WSLSRContext = createContext<WSContextLSRProps | null>(null);
export const WSHandbookContext = createContext<WSContextHandbookProps | null>(
  null
);
export const WSCalcContext = createContext<WSContextCalcProps | null>(null);
export const WSHandbookCopyContext = createContext<WSHandbookCopyProps | null>(
  null
);

export const ProviderWS: React.FC<ProviderWSProps> = (props) => {
  const { token, refreshToken } = useTypedSelector((state) => state.auth);
  const [isAuth, setIsAuth] = useState<WSContextProps['isAuth']>(false);
  const dispatch = useAppDispatch();
  const [getRefreshToken, refreshTokenResponse] = useRefreshTokenMutation();

  const { lastMessage, readyState, sendJsonMessage } = useWebSocket(
    SOCKET_URL_LSR,
    {
      share: true,
      onOpen: () => {
        if (token) {
          sendSubscribeAuth(token);
        }
      },
      shouldReconnect: () => {
        return true;
      }
    }
  );

  const connectionStatus = useMemo(
    () =>
      ({
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated'
      })[readyState] as ConnectionStatusType,
    [readyState]
  );

  useEffect(() => {
    console.log('WS. connectionStatus:', connectionStatus);
  }, [connectionStatus]);

  const isOpen = readyState === ReadyState.OPEN;

  const sendSubscribeAuth = useCallback(
    (token: string) => {
      const data: WSRequest<WSEventType.auth> = {
        event: WSEventType.auth,
        body: { token: token }
      };
      sendJsonMessage({
        event: data.event,
        body: JSON.stringify(data.body)
      });
    },
    [sendJsonMessage]
  );

  useEffect(() => {
    if (token && connectionStatus !== 'Open') {
      sendSubscribeAuth(token);
    }
  }, [token]);

  useEffect(() => {
    return () => {
      resetGlobalState(SOCKET_URL_LSR);
    };
  }, []);

  useEffect(() => {
    if (lastMessage && lastMessage.data) {
      const data = JSON.parse(lastMessage.data);
      const result: WSResponse<typeof data.event, typeof data.status> = data;
      switch (result.event) {
        case WSEventType.auth: {
          switch (result.status) {
            case WSTaskStatus.OK: {
              setIsAuth(true);
              break;
            }
            case WSTaskStatus.ERROR: {
              setIsAuth(false);
              if (refreshToken) getRefreshToken({ refreshToken: refreshToken });
              break;
            }
            default:
              break;
          }
          break;
        }
        default:
          break;
      }
    }
  }, [getRefreshToken, lastMessage, refreshToken]);

  useMutationHandlers(refreshTokenResponse, (data) => {
    if (!isAuth) {
      dispatch(setCredentials(data));
      sendSubscribeAuth(data.token);
    }
  });

  const lsr = useLSR({ isOpen, sendJsonMessage, token, lastMessage });
  const handbook = useHandbook({ isOpen, sendJsonMessage, token, lastMessage });
  const calc = useCalc({ isOpen, sendJsonMessage, token, lastMessage });
  const copy = useHandbookCopy({ isOpen, sendJsonMessage, token, lastMessage });

  const getIsSuccess = useCallback(
    (wsType: WSManeType) => {
      switch (wsType) {
        case 'calc':
          return calc.createCalcStatus ? !!calc.createCalcStatus : undefined;
        case 'handbook':
          return handbook.uploadFileHandbookStatus
            ? !!handbook.uploadFileHandbookStatus
            : undefined;
        case 'lsr':
          return lsr.uploadFileLsrStatus
            ? !!lsr.uploadFileLsrStatus
            : undefined;
        case 'handbookCopy':
          return copy.copyCalcStatus ? !!copy.copyCalcStatus : undefined;
        default:
          return undefined;
      }
    },
    [
      calc.createCalcStatus,
      copy.copyCalcStatus,
      handbook.uploadFileHandbookStatus,
      lsr.uploadFileLsrStatus
    ]
  );

  const getIsError = useCallback(
    (wsType: WSManeType) => {
      switch (wsType) {
        case 'calc':
          return calc.createCalcError ? !!calc.createCalcError : undefined;
        case 'handbook':
          return handbook.uploadFileHandbookError
            ? !!handbook.uploadFileHandbookError
            : undefined;
        case 'lsr':
          return lsr.uploadFileLsrError ? !!lsr.uploadFileLsrError : undefined;
        case 'handbookCopy':
          return copy.copyCalcError ? !!copy.copyCalcError : undefined;
        default:
          return undefined;
      }
    },
    [
      calc.createCalcError,
      handbook.uploadFileHandbookError,
      copy.copyCalcError,
      lsr.uploadFileLsrError
    ]
  );

  const getIsProgress = useCallback(
    (wsType: WSManeType) => {
      switch (wsType) {
        case 'calc':
          return calc.createCalcProgress
            ? !!calc.createCalcProgress || calc.createCalcClick
            : undefined;
        case 'handbook':
          return handbook.uploadFileHandbookProgress
            ? !!handbook.uploadFileHandbookProgress ||
                handbook.uploadFileHandbookClick
            : undefined;
        case 'lsr':
          return lsr.uploadFileLsrProgress
            ? !!lsr.uploadFileLsrProgress || lsr.uploadFileLsrClick
            : undefined;
        case 'handbookCopy':
          return copy.copyCalcProgress
            ? !!copy.copyCalcProgress || copy.copyCalcClick
            : undefined;
        default:
          return undefined;
      }
    },
    [
      calc.createCalcClick,
      calc.createCalcProgress,
      handbook.uploadFileHandbookClick,
      handbook.uploadFileHandbookProgress,
      lsr.uploadFileLsrClick,
      lsr.uploadFileLsrProgress,
      copy.copyCalcClick,
      copy.copyCalcProgress
    ]
  );
  const getPercent = useCallback(
    (wsType: WSManeType) => {
      switch (wsType) {
        case 'calc':
          return calc.createCalcProgress?.percent
            ? calc.createCalcProgress.percent
            : undefined;
        case 'handbook':
          return handbook.uploadFileHandbookProgress?.percent
            ? handbook.uploadFileHandbookProgress.percent
            : undefined;
        case 'lsr':
          return lsr.uploadFileLsrProgress?.percent
            ? lsr.uploadFileLsrProgress.percent
            : undefined;
        case 'handbookCopy':
          return copy.copyCalcProgress?.percent
            ? copy.copyCalcProgress.percent
            : undefined;
        default:
          return undefined;
      }
    },
    [
      calc.createCalcProgress,
      copy.copyCalcProgress,
      handbook.uploadFileHandbookProgress,
      lsr.uploadFileLsrProgress
    ]
  );
  useEffect(() => {
    const focusOnWindow = ()=>{
      if(connectionStatus !== 'Open' && token){
        sendSubscribeAuth(token)
      }
    }
    window.addEventListener('focus',focusOnWindow)
    return ()=>{
      window.removeEventListener('focus',focusOnWindow)
    }
  }, []);
  return (
    <WSContext.Provider
      value={{
        isAuth,
        isOpen,
        connectionStatus,
        readyState,
        getIsSuccess,
        getIsError,
        getIsProgress,
        getPercent,
        sendSubscribeAuth
      }}>
      <WSLSRContext.Provider value={lsr}>
        <WSHandbookContext.Provider value={handbook}>
          <WSCalcContext.Provider value={calc}>
            <WSHandbookCopyContext.Provider value={copy}>
              {props.children}
            </WSHandbookCopyContext.Provider>
          </WSCalcContext.Provider>
        </WSHandbookContext.Provider>
      </WSLSRContext.Provider>
    </WSContext.Provider>
  );
};

const useLSR = (props: {
  isOpen: boolean;
  sendJsonMessage: SendJsonMessage;
  token: any;
  lastMessage: MessageEvent<any> | null;
}): WSContextLSRProps => {
  const [uploadFileLsrProgress, setUploadFileLsrProgress] =
    useState<WSContextLSRProps['uploadFileLsrProgress']>(null);
  const [uploadFileLsrStatus, setUploadFileLsrStatus] =
    useState<WSContextLSRProps['uploadFileLsrStatus']>(null);
  const [uploadFileLsrCanceled, setUploadFileLsrCanceled] =
    useState<WSContextLSRProps['uploadFileLsrCanceled']>(false);
  const [uploadFileLsrError, setUploadFileLsrError] =
    useState<WSContextLSRProps['uploadFileLsrError']>(null);
  const [uploadFileLsrClick, setUploadFileLsrClick] =
    useState<WSContextLSRProps['uploadFileLsrClick']>(false);

  const wsContext = useContext(WSContext);

  const { sendJsonMessage, isOpen, token } = props;

  useEffect(() => {
    if (uploadFileLsrClick) {
      wsContext?.sendSubscribeAuth(token);
    }
  }, [uploadFileLsrClick]);

  const uploadFileLsr: WSContextLSRProps['uploadFileLsr'] = (params) => {
    setUploadFileLsrClick(true);
    const data: WSRequest<WSEventType.lsr_upload> = {
      event: WSEventType.lsr_upload,
      body: params
    };
    if (token && isOpen) {
      sendJsonMessage({
        event: data.event,
        body: JSON.stringify(data.body)
      });
    }
  };

  const clearUploadFileLsrProgress = useCallback(() => {
    setUploadFileLsrProgress(null);
    setUploadFileLsrClick(false);
  }, []);
  const clearUploadFileLsrStatus = useCallback(
    () => setUploadFileLsrStatus(null),
    []
  );
  const clearUploadFileLsrCanceled = useCallback(
    () => setUploadFileLsrCanceled(false),
    []
  );
  const clearUploadFileLsrError = useCallback(
    () => setUploadFileLsrError(null),
    []
  );

  const clearAll = useCallback(() => {
    setUploadFileLsrError(null);
    setUploadFileLsrCanceled(false);
    setUploadFileLsrStatus(null);
    setUploadFileLsrProgress(null);
    setUploadFileLsrClick(false);
  }, []);

  useEffect(() => {
    if (props.lastMessage && props.lastMessage.data) {
      const data = JSON.parse(props.lastMessage.data);
      const result: WSResponse<typeof data.event, typeof data.status> = data;

      switch (result.event) {
        case WSEventType.lsr_upload: {
          switch (result.status) {
            case WSTaskStatus.CANCELED: {
              setUploadFileLsrCanceled(true);
              setUploadFileLsrClick(false);
              break;
            }
            case WSTaskStatus.ERROR: {
              const finalResult: WSResponse<
                WSEventType.lsr_upload,
                WSTaskStatus.ERROR
              > = result;
              setUploadFileLsrError(finalResult.body);
              setUploadFileLsrClick(false);
              break;
            }
            case WSTaskStatus.IN_PROGRESS: {
              const finalResult: WSResponse<
                WSEventType.lsr_upload,
                WSTaskStatus.IN_PROGRESS
              > = result;
              setUploadFileLsrProgress(finalResult.body);
              setUploadFileLsrClick(false);
              break;
            }
            case WSTaskStatus.OK: {
              const finalResult: WSResponse<
                WSEventType.lsr_upload,
                WSTaskStatus.OK
              > = result;
              setUploadFileLsrStatus(finalResult.body);
              setUploadFileLsrClick(false);
              break;
            }
            default:
              break;
          }
          break;
        }
        case WSEventType.uknown: {
          const finalResult: WSResponse<
            WSEventType.lsr_upload,
            WSTaskStatus.ERROR
          > = result;
          setUploadFileLsrError(finalResult.body);
          setUploadFileLsrClick(false);
          break;
        }
        default:
          break;
      }
    }
  }, [props.lastMessage]);

  return {
    uploadFileLsrProgress,
    uploadFileLsrStatus,
    uploadFileLsrCanceled,
    uploadFileLsrError,
    uploadFileLsrClick,
    uploadFileLsr,
    clearUploadFileLsrProgress,
    clearUploadFileLsrStatus,
    clearUploadFileLsrCanceled,
    clearUploadFileLsrError,
    clearAll
  };
};

const useCalc = (props: {
  isOpen: boolean;
  sendJsonMessage: SendJsonMessage;
  token: any;
  lastMessage: MessageEvent<any> | null;
}): WSContextCalcProps => {
  const [createCalcProgress, setCreateCalcProgress] =
    useState<WSContextCalcProps['createCalcProgress']>(null);
  const [createCalcStatus, setCreateCalcStatus] =
    useState<WSContextCalcProps['createCalcStatus']>(null);
  const [createCalcCanceled, setCreateCalcCanceled] =
    useState<WSContextCalcProps['createCalcCanceled']>(false);
  const [createCalcError, setCreateCalcError] =
    useState<WSContextCalcProps['createCalcError']>(false);
  const [createCalcClick, setCreateCalcClick] =
    useState<WSContextCalcProps['createCalcClick']>(false);

  const { sendJsonMessage, isOpen, token } = props;

  const createCalc: WSContextCalcProps['createCalc'] = (params) => {
    setCreateCalcClick(true);
    const data: WSRequest<WSEventType.lsr_create_calc> = {
      event: WSEventType.lsr_create_calc,
      body: params
    };
    if (token && isOpen) {
      sendJsonMessage({
        event: data.event,
        body: JSON.stringify(data.body)
      });
    }
  };
  const clearCalcProgress = useCallback(() => {
    setCreateCalcProgress(null);
    setCreateCalcClick(false);
  }, []);
  const clearCalcStatus = useCallback(() => setCreateCalcStatus(null), []);
  const clearCalcCanceled = useCallback(() => setCreateCalcCanceled(false), []);
  const clearCalcError = useCallback(() => setCreateCalcError(false), []);

  const clearAll = useCallback(() => {
    setCreateCalcProgress(null);
    setCreateCalcStatus(null);
    setCreateCalcCanceled(false);
    setCreateCalcError(false);
    setCreateCalcClick(false);
  }, []);

  useEffect(() => {
    if (props.lastMessage && props.lastMessage.data) {
      const data = JSON.parse(props.lastMessage.data);
      const result: WSResponse<typeof data.event, typeof data.status> = data;

      switch (result.event) {
        case WSEventType.lsr_create_calc: {
          switch (result.status) {
            case WSTaskStatus.ERROR: {
              setCreateCalcError(true);
              setCreateCalcClick(false);
              break;
            }
            case WSTaskStatus.CANCELED: {
              setCreateCalcCanceled(true);
              setCreateCalcClick(false);
              break;
            }
            case WSTaskStatus.IN_PROGRESS: {
              const finalResult: WSResponse<
                WSEventType.lsr_create_calc,
                WSTaskStatus.IN_PROGRESS
              > = result;
              setCreateCalcProgress(finalResult.body);
              setCreateCalcClick(false);
              break;
            }
            case WSTaskStatus.OK: {
              const finalResult: WSResponse<
                WSEventType.lsr_create_calc,
                WSTaskStatus.OK
              > = result;
              setCreateCalcStatus(finalResult.body);
              setCreateCalcClick(false);
              break;
            }
            default:
              break;
          }
          break;
        }
        default:
          break;
      }
    }
  }, [props.lastMessage]);

  return {
    createCalcProgress,
    createCalcStatus,
    createCalcCanceled,
    createCalcError,
    createCalcClick,
    createCalc,
    clearCalcProgress,
    clearCalcStatus,
    clearCalcCanceled,
    clearCalcError,
    clearAll
  };
};
const useHandbookCopy = (props: {
  isOpen: boolean;
  sendJsonMessage: SendJsonMessage;
  token: any;
  lastMessage: MessageEvent<any> | null;
}): WSHandbookCopyProps => {
  const [copyCalcProgress, setCopyCalcProgress] =
    useState<WSHandbookCopyProps['copyCalcProgress']>(null);
  const [copyCalcStatus, setCreateCalcStatus] =
    useState<WSHandbookCopyProps['copyCalcStatus']>(null);
  const [copyCalcCanceled, setCreateCalcCanceled] =
    useState<WSHandbookCopyProps['copyCalcCanceled']>(false);
  const [copyCalcError, setCreateCalcError] =
    useState<WSHandbookCopyProps['copyCalcError']>(null);
  const [copyCalcClick, setCreateCalcClick] =
    useState<WSHandbookCopyProps['copyCalcClick']>(false);

  const { sendJsonMessage, isOpen, token } = props;

  const copyCalc: WSHandbookCopyProps['copyCalc'] = (params) => {
    setCreateCalcClick(true);
    const data: WSRequest<WSEventType.copy_calc> = {
      event: WSEventType.copy_calc,
      body: params
    };
    if (token && isOpen) {
      sendJsonMessage({
        event: data.event,
        body: JSON.stringify(data.body)
      });
    }
  };

  const clearCalcProgress = useCallback(() => {
    setCopyCalcProgress(null);
    setCreateCalcClick(false);
  }, []);
  const clearCalcStatus = useCallback(() => setCreateCalcStatus(null), []);
  const clearCalcCanceled = useCallback(() => setCreateCalcCanceled(false), []);
  const clearCalcError = useCallback(() => setCreateCalcError(null), []);

  const clearAll = useCallback(() => {
    setCopyCalcProgress(null);
    setCreateCalcStatus(null);
    setCreateCalcCanceled(false);
    setCreateCalcError(null);
    setCreateCalcClick(false);
  }, []);

  useEffect(() => {
    if (props.lastMessage && props.lastMessage.data) {
      const data = JSON.parse(props.lastMessage.data);
      const result: WSResponse<typeof data.event, typeof data.status> = data;

      switch (result.event) {
        case WSEventType.copy_calc: {
          switch (result.status) {
            case WSTaskStatus.ERROR: {
              setCreateCalcError(data);
              setCreateCalcClick(false);
              break;
            }
            case WSTaskStatus.CANCELED: {
              setCreateCalcCanceled(true);
              setCreateCalcClick(false);
              break;
            }
            case WSTaskStatus.IN_PROGRESS: {
              const finalResult: WSResponse<
                WSEventType.copy_calc,
                WSTaskStatus.IN_PROGRESS
              > = result;
              setCopyCalcProgress(finalResult.body);
              setCreateCalcClick(false);
              break;
            }
            case WSTaskStatus.OK: {
              const finalResult: WSResponse<
                WSEventType.copy_calc,
                WSTaskStatus.OK
              > = result;
              setCreateCalcStatus(finalResult.body);
              setCreateCalcClick(false);
              break;
            }
            default:
              break;
          }
          break;
        }
        default:
          break;
      }
    }
  }, [props.lastMessage]);

  return {
    copyCalcProgress,
    copyCalcStatus,
    copyCalcCanceled,
    copyCalcError,
    copyCalcClick,
    copyCalc,
    clearCalcProgress,
    clearCalcStatus,
    clearCalcCanceled,
    clearCalcError,
    clearAll
  };
};

const useHandbook = (props: {
  isOpen: boolean;
  sendJsonMessage: SendJsonMessage;
  token: any;
  lastMessage: MessageEvent<any> | null;
}): WSContextHandbookProps => {
  const [uploadFileHandbookProgress, setUploadFileHandbookProgress] =
    useState<WSContextHandbookProps['uploadFileHandbookProgress']>(null);
  const [uploadFileHandbookStatus, setUploadFileHandbookStatus] =
    useState<WSContextHandbookProps['uploadFileHandbookStatus']>(null);
  const [uploadFileHandbookCanceled, setUploadFileHandbookCanceled] =
    useState<WSContextHandbookProps['uploadFileHandbookCanceled']>(false);
  const [uploadFileHandbookError, setUploadFileHandbookError] =
    useState<WSContextHandbookProps['uploadFileHandbookError']>(null);
  const [uploadFileHandbookClick, setUploadFileHandbookClick] =
    useState<WSContextHandbookProps['uploadFileHandbookClick']>(false);

  const { sendJsonMessage, isOpen, token } = props;

  const uploadFileHandbook: WSContextHandbookProps['uploadFileHandbook'] = (
    params
  ) => {
    setUploadFileHandbookClick(true);
    const data: WSRequest<WSEventType.handbk_upload> = {
      event: WSEventType.handbk_upload,
      body: params
    };
    if (token && isOpen) {
      sendJsonMessage({
        event: data.event,
        body: JSON.stringify(data.body)
      });
    }
  };

  const clearUploadFileHandbookProgress = useCallback(() => {
    setUploadFileHandbookProgress(null);
    setUploadFileHandbookClick(false);
  }, []);
  const clearUploadFileHandbookStatus = useCallback(
    () => setUploadFileHandbookStatus(null),
    []
  );
  const clearUploadFileHandbookCanceled = useCallback(
    () => setUploadFileHandbookCanceled(false),
    []
  );
  const clearUploadFileHandbookError = useCallback(
    () => setUploadFileHandbookError(null),
    []
  );
  const clearAll = useCallback(() => {
    setUploadFileHandbookProgress(null);
    setUploadFileHandbookStatus(null);
    setUploadFileHandbookCanceled(false);
    setUploadFileHandbookError(null);
    setUploadFileHandbookClick(false);
  }, []);

  useEffect(() => {
    if (props.lastMessage && props.lastMessage.data) {
      const data = JSON.parse(props.lastMessage.data);
      const result: WSResponse<typeof data.event, typeof data.status> = data;

      switch (result.event) {
        case WSEventType.handbk_upload: {
          switch (result.status) {
            case WSTaskStatus.CANCELED: {
              setUploadFileHandbookCanceled(true);
              setUploadFileHandbookClick(false);
              break;
            }
            case WSTaskStatus.ERROR: {
              const finalResult: WSResponse<
                WSEventType.handbk_upload,
                WSTaskStatus.ERROR
              > = result;
              setUploadFileHandbookError(finalResult.body);
              setUploadFileHandbookClick(false);
              break;
            }
            case WSTaskStatus.IN_PROGRESS: {
              const finalResult: WSResponse<
                WSEventType.handbk_upload,
                WSTaskStatus.IN_PROGRESS
              > = result;
              setUploadFileHandbookProgress(finalResult.body);
              setUploadFileHandbookClick(false);
              break;
            }
            case WSTaskStatus.OK: {
              const finalResult: WSResponse<
                WSEventType.handbk_upload,
                WSTaskStatus.OK
              > = result;
              setUploadFileHandbookStatus(finalResult.body);
              setUploadFileHandbookClick(false);
              break;
            }
            default:
              break;
          }
          break;
        }
        default:
          break;
      }
    }
  }, [props.lastMessage]);

  return {
    uploadFileHandbookProgress,
    uploadFileHandbookStatus,
    uploadFileHandbookCanceled,
    uploadFileHandbookError,
    uploadFileHandbookClick,
    uploadFileHandbook,
    clearUploadFileHandbookProgress,
    clearUploadFileHandbookStatus,
    clearUploadFileHandbookCanceled,
    clearUploadFileHandbookError,
    clearAll
  };
};
