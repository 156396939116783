import { GetExecutionCalculationData } from '../../../../types';

export class ExecutionState {
  data: GetExecutionCalculationData = { total: [], tree: [] };
  lastData?: GetExecutionCalculationData;
  page = 0;
  endFetched = false;
  isFirstDataFetched = false;
  prevParams?: { page?: number; depth?: number };
  isUpdating = false;
  depth?: number;
  resetCounter: number;

  constructor(
    prev?: ExecutionState,
    newData?: GetExecutionCalculationData,
    params?: ExecutionState['prevParams']
  ) {
    this.resetCounter = 0;
    if (!prev && !newData) return;

    if (this.prevParams?.page === params?.page) {
      this.data = {
        total: newData?.total || [],
        tree: newData?.tree || []
      };
    } else {
      const total = prev?.data?.total?.length
        ? prev?.data.total
        : newData?.total || [];
      this.data = {
        total,
        tree: [...(prev?.data?.tree || []), ...(newData?.tree || [])]
      };
    }
    this.endFetched = !newData?.tree?.length;
    if (params) this.prevParams = params;

    if (newData) this.isFirstDataFetched = true;
    if (this.depth !== params?.depth) this.isFirstDataFetched = false;
    this.page = params?.page || 0;
    if (params?.depth) this.depth = params.depth;
    // if (prev?.isUpdating) this.isUpdating = true;
    if (newData)
      this.lastData = newData?.tree?.length ? newData : prev?.lastData;
  }
}
