import { RowClassParams } from 'ag-grid-community';
import { IIndexMethodTable } from 'types/index-method/index-method';
import { ExecutionCalculationRimData } from '../../../types';

export function getRowClassIndexMethodCalculation(
  params: RowClassParams<IIndexMethodTable>
) {
  const classCell = [];
  if (params.data?.type === 'limit' && typeof params.data.id === 'string') {
    classCell.push('limit-row');
  }
  if (params.data?.type === 'file') {
    classCell.push(`level-${params.data?.lvl}`);
  } else {
    params.data?.type === 'level'
      ? classCell.push('rate')
      : classCell.push('rate-position');
  }
  if (params.node.data?.subtype) {
    classCell.push(params.node.data?.subtype + '-cell');
  }
  return classCell;
}

export const getNestedChildrenIdsIndexMethodCalculation = (
  id: number,
  defaultData: (IIndexMethodTable | ExecutionCalculationRimData)[]
): number[] => {
  const children = defaultData.filter((e) => e?.parentID === id);

  return children.reduce<number[]>(
    (acc, child) =>
      [
        ...acc,
        child.id as number,
        ...getNestedChildrenIdsIndexMethodCalculation(
          child.id as number,
          defaultData
        )
      ].filter((e) => e !== undefined),
    []
  );
};
