import { WsLSRState, WsState } from './ws.types';
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const initialState:WsState= {
  lsr:{
    error:false,
    errorData:null,
    files:null,
    isListFiles:false,
    progressPercent:0,
    progressTime:'',
    projectName:'',
    inProgress:false,
    isOpenLsrDrawer: false,
    isOpenSnackLsr:false,
    targetDrawer:null,
    isStarted:false,
    statusFiles:null
  }
}
type KeyWsLsr = keyof WsLSRState

type ValueWsLsr = WsLSRState[KeyWsLsr]

const wsSlice = createSlice({
  name:'wsSlice',
  initialState,
  reducers:{
    setField: <T extends keyof WsLSRState>(
      state: Draft<WsState>,
      action: PayloadAction<Partial<WsLSRState>|undefined>
    ) => {
      if (action.payload) {
        state.lsr = {
          ...state.lsr,
          ...action.payload
        };
      }else{
        state.lsr = initialState.lsr
      }
    },
  }
})
export const getWsState = (state: RootState) =>
  state.ws;
export const {setField} = wsSlice.actions;

export default wsSlice.reducer
