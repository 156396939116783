import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { AlertTitle, Box, Snackbar, Stack, Typography } from '@mui/material';
import LinearProgress, {
  linearProgressClasses,
  LinearProgressProps
} from '@mui/material/LinearProgress';
import { useWS, useWSLSR } from 'api/web-socket';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Loader } from './icons/Loader';
import { StyledAlert } from './styles';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch, useTypedSelector } from '../../../../store/store';
import { api } from '../../../../api/api';
import { getWsState, setField } from '../../../../store/slices/ws';
import { useProjectId } from '../../../../hooks/useProjectId';

interface WSCustomSnackbarProps {
  isDrawerOpen: boolean;
  wsType: 'lsr' | 'handbook' | 'calc' | 'handbookCopy';
}

export default function WSCustomSnackbar({
  isDrawerOpen,
  wsType
}: WSCustomSnackbarProps) {
  const { connectionStatus, getIsError, getIsProgress, getIsSuccess } = useWS();

  const projectID = useProjectId();

  const { clearAll } = useWSLSR();

  const location = useLocation();
  const dispatch = useAppDispatch();

  const {
    lsr: {
      progressPercent,
      files,
      progressTime,
      isOpenSnackLsr: open,
      targetDrawer,
      isStarted,
      inProgress
    }
  } = useTypedSelector(getWsState);

  const isSuccess = useMemo(
    () => getIsSuccess(wsType),
    [getIsSuccess, wsType, location.pathname]
  );
  const isError = useMemo(
    () => getIsError(wsType),
    [getIsError, wsType, location.pathname]
  );
  const isProgress = useMemo(
    () => getIsProgress(wsType),
    [getIsProgress, wsType, location.pathname]
  );

  const [colorVar, setColor] = useState<string>('');

  useEffect(() => {
    if (connectionStatus !== 'Open') {
      setColor('#F46B6B');
    } else if (isProgress === true) {
      setColor('#2B3648');
    } else if (isSuccess === true) {
      setColor('#2E7D32');
    } else if (isError === true) {
      setColor('#F46B6B');
    }
  }, [connectionStatus, isError, isProgress, isSuccess, location.pathname]);

  /* socket handlers */

  const { uploadFileLsrProgress, uploadFileLsrStatus, uploadFileLsrError } =
    useWSLSR();

  const handleClose = useCallback(() => {
    dispatch(setField({ isOpenSnackLsr: false }));
    if ((progressTime && progressPercent) || (isSuccess && inProgress)) {
    }
  }, [progressTime, progressPercent, isSuccess, inProgress]);

  useEffect(() => {
    if (inProgress && !isDrawerOpen && !open) {
      dispatch(setField({ isOpenSnackLsr: true }));
      console.log('ws', inProgress);
    }
  }, [
    isDrawerOpen,
    inProgress,
    uploadFileLsrProgress,
    uploadFileLsrStatus,
    uploadFileLsrError,
    location.pathname
  ]);

  useEffect(() => {
    if (uploadFileLsrProgress) {
      dispatch(
        setField({
          progressTime: uploadFileLsrProgress.remaining,
          progressPercent: Math.floor(uploadFileLsrProgress.percent * 100),
          inProgress: true
        })
      );
    }
  }, [uploadFileLsrProgress]);

  useEffect(() => {
    if (uploadFileLsrStatus) {
      clearAll();

      dispatch(api.util.invalidateTags(['LSR', 'ProjectDashboard']));
      dispatch(
        setField({
          errorData: null,
          progressPercent: 0,
          progressTime: '',
          inProgress: false,
          files: null
        })
      );
      if (
        location.pathname.toLowerCase().includes('lsr') &&
        targetDrawer?.includes(projectID.toString()) &&
        !isStarted
      ) {
        setTimeout(() => {
          dispatch(setField({ isOpenSnackLsr: false }));
        }, 3000);
      }
    }
  }, [clearAll, uploadFileLsrStatus]);

  useEffect(() => {
    if (uploadFileLsrError) {
      const errorNames = uploadFileLsrError.withErrors.map(
        (erFile) => erFile.name
      );
      const filteredState = !files
        ? null
        : files.filter((el) => errorNames.includes(el.name));
      dispatch(
        setField({
          error: true,
          errorData: uploadFileLsrError,
          progressTime: '',
          progressPercent: 0,
          inProgress: false,
          files: filteredState
        })
      );

      uploadFileLsrError?.uploaded?.length &&
        dispatch(api.util.invalidateTags(['LSR', 'ProjectDashboard']));
    }
  }, [uploadFileLsrError]);

  /* *************** */

  function LinearProgressPercent(
    props: LinearProgressProps & { value: number }
  ) {
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
      height: '6px',
      borderRadius: '15px',
      boxShadow:
        'inset 0px -1px 1px rgba(52, 89, 254, 0.15), inset 0px 1px 1px rgba(52, 89, 254, 0.15)',
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
          theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
      },
      [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#6D9ADC' : '#308fe8'
      }
    }));

    return (
      <Box
        sx={{
          width: '96%',
          position: 'absolute',
          bottom: 0,
          left: '10px',
          zIndex: 1100
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <BorderLinearProgress variant="determinate" {...props} />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography
              variant="body2"
              style={{ padding: '0 10px' }}
              color="#2B3648">{`${Math.round(props.value)}%`}</Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Stack spacing={2} sx={{ width: '100%', position: 'absolute' }}>
      <Snackbar
        open={open}
        autoHideDuration={!progressTime && !isSuccess && !isError ? 2000 : null}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <StyledAlert
          onClose={handleClose}
          variant="outlined"
          icon={
            (progressTime && <Loader />) ||
            (isSuccess && <DoneIcon sx={{ color: '#2E7D32' }} />) ||
            (isError && <ErrorOutlineIcon sx={{ color: '#F46B6B' }} />) || (
              <DoneIcon sx={{ color: '#2E7D32' }} />
            )
          }>
          <AlertTitle
            sx={{
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '20px',
              letterSpacing: '0.17px',
              color: `${colorVar}`,
              display: 'flex',
              alignItems: 'center'
            }}>
            {inProgress && (
              <>
                <Typography variant="body2" sx={{ lineHeight: '32px' }}>
                  Идет загрузка данных.
                  {progressPercent && progressPercent > 0
                    ? ` Загружено ${progressPercent}%`
                    : ' Получаем данные о файлах....'}
                </Typography>
              </>
            )}
            {((isSuccess && !isError) ||
              (!inProgress && !isSuccess && !isError)) &&
              (targetDrawer ? (
                <Link
                  to={targetDrawer}
                  onClick={() => {
                    dispatch(
                      setField({ isOpenSnackLsr: false, isOpenLsrDrawer: true })
                    );
                  }}>
                  Данные успешно загружены!
                </Link>
              ) : (
                'Данные успешно загружены!'
              ))}
            {isError &&
              !isSuccess &&
              (!targetDrawer ? (
                <Typography variant="body2" sx={{ lineHeight: '44px' }}>
                  Не удалось загрузить файлы
                </Typography>
              ) : (
                <Link
                  to={targetDrawer!}
                  onClick={() =>
                    dispatch(
                      setField({ isOpenSnackLsr: false, isOpenLsrDrawer: true })
                    )
                  }>
                  <Typography variant="body2" sx={{ lineHeight: '44px' }}>
                    Не удалось загрузить файлы
                  </Typography>
                </Link>
              ))}
          </AlertTitle>
          {progressTime && progressPercent && (
            <LinearProgressPercent value={progressPercent} />
          )}
        </StyledAlert>
      </Snackbar>
    </Stack>
  );
}
