import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { GetCoefficientsResponse } from '../../../../../../../../../api/params/params.types';
import { ActListContext } from '../../../../../../../../Calculations/components/CalculationСomplicated';
import { ActCol, ActRow, ActTable } from './act.styles';
import { Box, Typography } from '@mui/material';
import { ActRow as Row } from './act.row';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import { DialogForm, InnerDialogForm, LabelsGroup } from '../../../parameters-dialog.execution.types';
import { ActList, ActResponse } from 'types';
import { isEmpty } from 'utils/isEmpty';
import { isAfter, isBefore } from 'date-fns';
import { enqueueSnackbar } from 'notistack';
import {
  formatDateToString,
  getFormatDate,
  isSameDate,
  toLocalString
} from 'utils/formatDate';
import { useUpdateActMutation } from '../../../../../../../../../api/params';
import { useCalcId } from '../../../../../../../../../hooks/useCalcId';
import { useAppDispatch } from '../../../../../../../../../store/store';
import { clear, resetRimExecution } from '../../../../../../../../../store/slices/calculations/rim/rim.slice';
export const ActTab: React.FC<{
  setCurrentAct: (act: ActList | null) => void;
  actList: ActResponse | undefined;
  updateFragmentFn:()=>void
}> = ({ setCurrentAct, actList,updateFragmentFn }) => {
  const [updateAct] = useUpdateActMutation();
  const calcID = useCalcId()

  const dispatch = useAppDispatch()

  const {values:{currentAct}} = useFormikContext<DialogForm>()
  // const { control } = useFormContext<GetCoefficientsResponse>();
  // const { values } = useFormikContext<DialogForm>();
  const initialValues = useMemo(() => {
    const acts = actList?.actList
      // ?.filter((act) => !removedActsIdx.includes(act.id))
      ?.reduce((acc, prev) => {
        // const title = prev.estimateName || 'Общие';
        const label =
          'Акты за ' +
          formatDateToString(new Date(prev.onDate || ''), 'yyyy');

        const idxGroup = acc.findIndex((_) => _.label === label);
        //
        // prev.forEach((_) => {

        const update = {
          ...prev,
          onDate: new Date(prev.onDate || ''),
          startDate: new Date(prev.startDate || ''),
          endDate: new Date(prev.endDate || '')
        };
        if (idxGroup >= 0) {
          acc[idxGroup].fields = [...acc[idxGroup].fields, update];
        } else {
          acc.push({ label, fields: [update] });
        }
        // });
        return acc;
      }, [] as LabelsGroup[]) || [];
    return {acts}
  }, [actList]);


  const formik = useFormik<InnerDialogForm>({
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      console.log(values);
      updateAct({
        calcID,
        values: (values.acts || []).flatMap((label) => {
          // _.groups.flatMap(() => {
          return label.fields.flatMap((act) => {
            const { id, startDate, endDate } = act;
            if (currentAct?.id === id) {
              setCurrentAct?.({
                ...currentAct,
                id: currentAct.id,
                endDate: toLocalString(endDate as Date),
                startDate: toLocalString(startDate as Date),
                onDate: currentAct.onDate
              });
            }
            return {
              id,
              endDate: toLocalString(endDate as Date),
              startDate: toLocalString(startDate as Date)
            };
          });
          // }),
        })
      }).then(()=>{
        updateFragmentFn()
        dispatch(resetRimExecution())
      });
    }
  });

  const validate = useCallback((values:InnerDialogForm)=>{
    const errors: { [key: string]: { er: ReturnType<typeof checkDates> } } =
      {};
    console.log('values>>>',values);
    values.acts?.forEach((labels) => {
      labels.fields.forEach((act, idx) => {
        const er = checkDates(act,values);

        const key = `За ${new Date(act.onDate || '')?.getFullYear()} год, строка ${idx + 1}`;
        if (er?.end || er?.on || er?.split || er?.start) {
          if (errors[key]) {
            errors[key] = { ...errors[key], er };
          } else {
            errors[key] = { er };
          }
        }
      });
    });
    const keysErrors = Object.keys(errors);
    console.log('log',keysErrors);
    if (keysErrors.length) {
      enqueueSnackbar(
        <Box display={'flex'} flexDirection={'column'} gap={0.05}>
          Ошибка в Актах по периодам:
          {keysErrors.map((label) => {
            const err = errors[label].er;
            return (
              <Box key={label} display={'flex'} flexDirection={'column'}>
                <p>{label}</p>
                <Box display={'flex'} gap={0.2} flexDirection={'column'}>
                  {err?.start}
                  {err?.end}
                  {err?.on}
                  {err?.split}
                </Box>
              </Box>
            );
          })}
        </Box>,
        {
          variant: 'error',
          autoHideDuration: 3000
        }
      );

      formik.resetForm({ values: initialValues })
      return false
    }
    return true
  },[initialValues])


  const disabledDates =
    useCallback((values:InnerDialogForm): { start: Date; end: Date; id: number }[] => {
      if (!values.acts) return [];
      return values.acts.reduce(
        (acc: { start: Date; end: Date; id: number }[], item) => {
          acc.push(
            ...item.fields.flatMap((dates) => ({
              start: new Date(dates.startDate || ''),
              end: new Date(dates.endDate || ''),
              id: dates.id
            }))
          );
          return acc;
        },
        []
      );
    }, []);

  const checkDates = useCallback(
    (data: ActList,values:InnerDialogForm) => {
      /* Получаем актуальный список дат, все кроме входящей даты */
      const filteredDisabledDates = disabledDates(values).filter(
        (d) => d.id !== data.id
      );

      /**
       * Объект для формирования ошибок
       * start - попадает сюда, если есть ошибка в поле "Дата начала"
       * end - попадает сюда, если есть ошибка в поле "Дата окончания"
       * on - попадает сюда, если есть ошибка в поле "Сформирован на дату"
       * split - попадает сюда, если даты перепутаны местами, например, дата начала идет после даты конца
       */
      const errorText: {
        start: null | React.ReactElement;
        end: null | React.ReactElement;
        on: null | React.ReactElement;
        split: null | React.ReactElement;
      } = { start: null, end: null, on: null, split: null };

      if (
        (data.startDate && isNaN((data.startDate as Date).getTime())) ||
        data.startDate === null
      ) {
        errorText.start = <span>Дата начала некорректна</span>;
      }
      if (
        (data.endDate && isNaN((data.endDate as Date).getTime())) ||
        data.endDate === null
      ) {
        errorText.end = <span>Дата окончания некорректна</span>;
      }
      if (
        (data.onDate && isNaN((data.onDate as Date).getTime())) ||
        data.onDate === null
      ) {
        errorText.on = <span>Дата формирования некорректна</span>;
      }
      if (
        data.startDate &&
        !isNaN((data.startDate as Date).getTime()) &&
        data.endDate &&
        !isNaN((data.endDate as Date).getTime()) &&
        data.onDate &&
        !isNaN((data.onDate as Date).getTime())
      ) {
        for (const { start, end } of filteredDisabledDates) {
          if (
            (!errorText.start &&
              (isSameDate(data.startDate as Date, start) ||
                isAfter(data.startDate as Date, start)) &&
              (isSameDate(data.startDate as Date, end) ||
                isBefore(data.startDate as Date, end))) ||
            (isBefore(data.startDate as Date, start) &&
              isAfter(data.endDate as Date, end))
          ) {
            errorText.start = (
              <span>Дата начала пересекает существующие акты</span>
            );
          }
          if (
            !errorText.end &&
            (isSameDate(data.endDate as Date, start) ||
              isAfter(data.endDate as Date, start)) &&
            (isSameDate(data.endDate as Date, end) ||
              isBefore(data.endDate as Date, end))
          ) {
            errorText.end = (
              <span>Дата окончания пересекает существующие акты</span>
            );
          }
          if (
            !errorText.on &&
            isSameDate(data.startDate as Date, data.endDate as Date)
          ) {
            errorText.on = (
              <span>Даты не могут быть установлены одним днем</span>
            );
          }
          if (
            !errorText.split &&
            (isBefore(data.endDate as Date, data.startDate as Date) ||
              isAfter(data.startDate as Date, data.endDate as Date))
          ) {
            errorText.split = (
              <span>Дата начала не может идти после даты окончания</span>
            );
          }
        }
      }

      if (isEmpty(errorText, ['start', 'end', 'on', 'split'], false)) {
        return errorText;
      } else {
        return {} as typeof errorText;
      }
    },
    [disabledDates]
  );

  const { calculation } = useContext(ActListContext);

  useEffect(() => {
    if(validate(formik.values)){
      formik.handleSubmit()
    }
  }, [formik.values]);
  return (
    <FormikProvider value={formik}>
      <ActTable>
        <ActRow $isIntegrate={!!calculation?.integrationInfo} $border $paddingTop>
          <ActCol $head>Сформирован</ActCol>
          <ActCol $head $align={'center'}>
            Начало периода
          </ActCol>
          <span />
          <ActCol $head $align={'center'}>
            Окончание периода
          </ActCol>
          <span />
          {calculation?.integrationInfo && (
            <ActCol $head $align={'flex-start'}>
              Статус
            </ActCol>
          )}
        </ActRow>
        {formik.values.acts.map((estimate: any, estimateIndex: any) => {
          return (
            <React.Fragment key={'estimate' + estimateIndex + estimate.label}>
              {!!estimate.fields.length && (
                <Typography
                  style={{ cursor: 'default' }}
                  whiteSpace={'nowrap'}
                  textOverflow={'ellipsis'}
                  width={'100%'}
                  overflow={'hidden'}
                  pt={2}
                  fontSize={14}
                  color={'#7890b2'}>
                  {estimate.label || 'Общие'}
                </Typography>
              )}
              {estimate.fields.map((row: any, rowIndex: any) => (
                <Row
                  validate={validate}
                  calculation={calculation}
                  currentAct={currentAct}
                  setCurrentAct={setCurrentAct}
                  key={'parent' + estimateIndex + estimate.label + rowIndex}
                  parentIndex={estimateIndex}
                  rowIndex={rowIndex}
                  row={row}
                />
              ))}
            </React.Fragment>
          );
        })}
      </ActTable>
    </FormikProvider>
  );
};
