import { Drawer } from 'components/Drawer';
import { useProjectId } from 'hooks/useProjectId';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  calculationsApi,
  useAddEstimatesToCalculationMutation,
  useCancelAddingEstimatesToCalculationMutation,
  useGetCalculationEstimatesByIdQuery,
  useGetStatusAddingEstimatesToCalculationQuery
} from '../../../../api/calculations';
import Progress from '../../../../components/Progress';
import { AddCalculationDrawerProps } from './AddCalculationDrawer.types';
import { Button, Stack, Typography } from '@mui/material';
import {
  AddCalculationDrawerFooter,
  BorderLinearProgress
} from './AddCalculationDrawer.styles';

import { ICalculationEstimateItem } from 'api/calculations/types';
import { AvailableCalculationList } from './components/AvailableCalculationList';
import { AppendedCalculationList } from './components/AppendedCalculationList';
import { LoadingButton } from '@mui/lab';
import { useMutationHandlers } from 'hooks/useMutationHandlers';
import { useSnackbar } from 'notistack';
import { useStepperContext } from 'hooks/useStepper';
import { ActListContext, BimStepper } from '../CalculationСomplicated';
import { ConfirmDialog } from './components/ConfirmationDialog';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/store';
import { Stepper } from 'pages/projects-calculation/index-method';
import { params } from 'api/params';
import { resetRimExecution } from 'store/slices/calculations/rim/rim.slice';

type TTT = keyof BimStepper | keyof Stepper;
type RimBimStepper = {
  [K in TTT]: K extends keyof BimStepper
    ? BimStepper[K]
    : K extends keyof Stepper
      ? Stepper[K]
      : never;
};

export const AddCalculationDrawer: React.FC<AddCalculationDrawerProps> = ({
  calculationId,
  open,
  onClose,
  calculationType,
  contextType
}) => {
  const projectID = useProjectId();
  const [pollingInterval, setPollingInterval] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch: AppDispatch = useDispatch();

  const keyDepth = contextType === 'bim' ? 'calculation' : 'indexPage';
  const { toggleDepth } = useStepperContext<RimBimStepper>(
    function () {},
    keyDepth
  );
  const { getTable } = useContext(ActListContext);
  // сделано так, т.к. при использовании хука useConfirmDialog окно подтверждения мигало при перезапросах статуса
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const {
    data: estimatesData,
    isLoading,
    isFetching,
    refetch
  } = useGetCalculationEstimatesByIdQuery(
    {
      projectID,
      calcID: calculationId!
    },
    {
      skip: !calculationId
    }
  );

  const [addEstimatesToCalculation, addingResponse] =
    useAddEstimatesToCalculationMutation();

  const [cancelAdding, cancellingResponse] =
    useCancelAddingEstimatesToCalculationMutation();

  const { data: addingStatus, isError: isGetStatusError } =
    useGetStatusAddingEstimatesToCalculationQuery(
      {
        projectID,
        calcID: calculationId!,
        params: {
          taskID: addingResponse.data || ''
        }
      },
      {
        skip: !addingResponse.data || Boolean(!pollingInterval),
        pollingInterval: pollingInterval
      }
    );

  const [loading, setLoading] = useState(false);

  // остановка перезапросов статуса добавления при условии что в последнем ответе все выполнено или ошибка, дровер закрывается, перезапрашивается таблица
  useEffect(() => {
    if (
      ((addingStatus &&
        (addingStatus.isCompleted || addingStatus.isCanceled)) ||
        isGetStatusError) &&
      pollingInterval != 0
    ) {
      setLoading(false);
      setPollingInterval(0);
      toggleDepth(keyDepth, 0);
      onClose();
    }
  }, [addingStatus, isGetStatusError]);

  useMutationHandlers(
    addingResponse,
    () => {},
    () => {
      setLoading(false);
      enqueueSnackbar('Ошибка в обработке запроса', { variant: 'error' });
    }
  );

  useMutationHandlers(
    cancellingResponse,
    () => {
      setLoading(false);
      onClose();
    },
    () => {
      setLoading(false);
      enqueueSnackbar('Ошибка в обработке запроса отмены', {
        variant: 'error'
      });
      refetch();
    }
  );

  const handleConfirm = useCallback(
    (confirm: boolean) => {
      setOpenConfirmDialog(false);
      if (confirm) {
        // если идет добавление, отправляем запрос на отмену, останавливаем запросы статуса
        if (loading) {
          if (calculationId && addingResponse.data) {
            setPollingInterval(0);
            cancelAdding({
              projectID,
              calcID: calculationId,
              body: {
                id: addingResponse.data
              }
            });
          }
        } else {
          onClose();
        }
      }
    },
    [
      addingResponse.data,
      calculationId,
      cancelAdding,
      loading,
      onClose,
      projectID
    ]
  );

  const onDrawerClose = useCallback(
    (dirty: boolean) => {
      if (dirty) {
        setOpenConfirmDialog(true);
      } else {
        onClose();
      }
    },
    [onClose]
  );

  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [addedIds, setAddedIds] = useState<number[]>([]);

  // сброс состояний дровера при закрытии
  useEffect(() => {
    if (!open) {
      setSelectedIds([]);
      setAddedIds([]);
      if (addingResponse.data) {
        dispatch(
          calculationsApi.util.invalidateTags(['CalculationEstimatesById'])
        );
        if (contextType === 'bim') {
          dispatch(params.util.invalidateTags(['Params']));
          getTable?.(Number(calculationId!), 0, 0, true);
        } else {
          dispatch(params.util.invalidateTags(['RimCoefficients']));
          dispatch(resetRimExecution());
        }
      }
    }
  }, [open]);

  const addedItems = useMemo(
    () =>
      addedIds.map(
        (addedId) =>
          estimatesData?.available.find(
            (e) => e.id === addedId
          ) as ICalculationEstimateItem
      ),
    [addedIds, estimatesData?.available]
  );

  const onSelectCalculation = useCallback((id: number) => {
    setSelectedIds((state) => {
      const newState = new Set(state);
      if (state.includes(id)) {
        newState.delete(id);
      } else {
        newState.add(id);
      }
      return [...newState];
    });
  }, []);

  const onClearSelection = useCallback(() => {
    setSelectedIds([]);
  }, []);

  const onSelectAll = () => {
    setSelectedIds(estimatesData?.available.map((el) => el.id) || []);
  };

  const onAddSelection = () => {
    setAddedIds((state) => [...state, ...selectedIds]);
    setSelectedIds([]);
  };

  const onDeleteCalculation = useCallback((index: number) => {
    setAddedIds((state) => state.filter((item, idx) => idx !== index));
  }, []);

  const handleAddEstimates = () => {
    if (calculationId) {
      setLoading(true);
      // отправляем запрос на добавление, начинаем запросы статуса добавления
      addEstimatesToCalculation({
        projectID,
        calcID: calculationId,
        body: {
          estimateIDs: addedIds
        }
      });
      setPollingInterval(1000);
    }
  };

  return (
    <>
      <ConfirmDialog open={openConfirmDialog} onClose={handleConfirm} />
      <Drawer
        title={'Добавить ЛСР'}
        anchor="right"
        open={open}
        width={'1190px'}
        keepMounted={false}
        sx={{
          '& .MuiPaper-root': {
            maxWidth: '100%'
          },
          '& .MuiTypography-h1': {
            textAlign: 'left',
            color: '#2B3648',
            fontSize: '16px',
            fontWeight: 600
          },
          '& .MuiDivider-root': {
            borderBottomWidth: '1px'
          }
        }}
        onClose={() => {
          if (loading) {
            return false;
          }
          onDrawerClose(!!(addedIds.length || selectedIds.length));
        }}>
        <Stack gap="30px" p="20px" flex={1} height={'100%'}>
          {!(isLoading || isFetching) ? (
            <>
              <Stack
                gap="20px"
                direction="row"
                flex={1}
                height={'calc(100% - 70px)'}>
                <AvailableCalculationList
                  list={estimatesData?.available || []}
                  onAddSelection={onAddSelection}
                  onClearSelection={onClearSelection}
                  onSelectAll={onSelectAll}
                  onSelectCalculation={onSelectCalculation}
                  addedIds={addedIds}
                  selectedIds={selectedIds}
                  calculationType={calculationType}
                  loading={loading}
                />
                <AppendedCalculationList
                  onDeleteCalculation={onDeleteCalculation}
                  list={estimatesData?.appended || []}
                  newList={addedItems}
                  calculationType={calculationType}
                  loading={loading}
                />
              </Stack>
              <AddCalculationDrawerFooter>
                {loading &&
                  !!addingStatus &&
                  !(
                    addingStatus.isCanceled ||
                    addingStatus.isCompleted ||
                    isGetStatusError
                  ) && (
                    <Stack mr={'auto'} gap="8px">
                      <Typography>
                        {`${addingStatus.msg}...`}
                        <BorderLinearProgress
                          variant="determinate"
                          value={
                            (addingStatus.completed / addingStatus.total) * 100
                          }
                        />
                      </Typography>
                    </Stack>
                  )}
                <LoadingButton
                  color="success"
                  disabled={!addedIds.length}
                  loading={loading}
                  variant="contained"
                  onClick={handleAddEstimates}>
                  Сохранить
                </LoadingButton>
                <Button
                  onClick={() =>
                    onDrawerClose(
                      !!(addedIds.length || selectedIds.length) || loading
                    )
                  }>
                  отменить
                </Button>
              </AddCalculationDrawerFooter>
            </>
          ) : (
            <Progress />
          )}
        </Stack>
      </Drawer>
    </>
  );
};
