import React from 'react';
import { TParametersTabs } from '../parameters-dialog.execution.types';
import { ActTab, Table } from './components';
import { ActList, ActResponse } from '../../../../../../../types';

export const Content: React.FC<{
  tab: TParametersTabs;
  setCurrentAct: (act: ActList | null) => void;
  actList:ActResponse|undefined;
  updateFragmentFn:()=>void
}> = ({ tab, setCurrentAct,actList,updateFragmentFn }) => {
  switch (tab) {
    case 'acts':
      return <ActTab setCurrentAct={setCurrentAct} actList={actList} updateFragmentFn={updateFragmentFn} />;
    case 'limited_costs':
      // return <Table estimates={} />;
      return <Table />;
    default:
      return null;
  }
};
