/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import {
  ColDef,
  ColGroupDef,
  IRowNode,
  NewValueParams
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, {
  createContext,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  useEditQuantityMutation,
  useGetExecutionCalculationBimChildrenMutation,
  useGetExecutionCalculationBimMutation
} from '../../../../api/calculations';
import Progress from '../../../../components/Progress';
import {
  ActList,
  ExecutionCalculationData,
  GetExecutionCalculationData
} from '../../../../types';
import { ActListContext, BimStepper } from '../CalculationСomplicated';
import { PageStyled, WrapperAgGrid } from './Accomplishment.styles';
import { IPricesSwitch } from './Accomplishment.types';
import ActDialog from './components/ActDialog';
import CaptionTable from './components/CaptionTable';
import ExportDialog from './components/ExportDialog';
import { getRowClass, useTable } from './helper';
import { useStepperContext } from '../../../../hooks/useStepper';
import { useAppDispatch, useTypedSelector } from '../../../../store/store';
import {
  getAgGridRef,
  setGridRef
} from '../../../../store/slices/ag-grid/ag-grid-slice';
import {
  getBimCalcState,
  resetBimExecution,
  updateExecutionExpandedHeadersIds,
  updateExecutionHiddenRowsIds,
  updateExecutionTableState
} from '../../../../store/slices/calculations/bim/bim.slice';
import { ExecutionState } from '../CalculationСomplicated/CalculationСomplicated.types';
import { detectLastRowInView } from '../../../../utils/agGrid';
import CircularProgress from '@mui/material/CircularProgress';
import { LIMIT } from '../../../projects-calculation/index-method-execution';
import { debounce } from '@mui/material';
import {
  ENUMLocalStorage,
  useLocalStorage
} from '../../../../hooks/use-local-storage';
import { useParams } from 'react-router-dom';
import { useProjectId } from '../../../../hooks/useProjectId';
import useBreadcrumbs from '../../../../hooks/useBreadcrumbs';
import { ProjectLabel } from '../../../../components/ProjectLabel';
import { CalculationLabel } from '../../../../components/CalculationLabel';
import CustomTableHeader from './components/CustomTableHeader';
import { NoRows } from 'pages/Administration/AdminReferences/Prices/useTableData';

const defaultColDef = { resizable: true, editable: false };
const priceSwitches: IPricesSwitch[] = [
  { name: 'Базовые', value: 'base' },
  { name: 'Текущие', value: 'curr' }
];

interface TableContext {
  collapse?: (id: number, rowIndex?: number) => void;
  hiddenRowsIds?: number[];
  filteredData?: ExecutionCalculationData[];
  prices?: 'curr' | 'base';
  current?: ActList | null;
  updateData?: UpdateParams;
  total?: ExecutionCalculationData | null;
  calcID?: string;
  emptyCurr?: boolean;
}

type UpdateParams = (
  params: NewValueParams<ExecutionCalculationData, unknown>,
  act: ActList | null,
  calcID?: number | string
) => Promise<void>;

export const AgContext = createContext<TableContext>({});

const Accomplishment = () => {
  const projectID = useProjectId();
  const dispatch = useAppDispatch();
  const gridRef = useTypedSelector(getAgGridRef);
  const { executionExpandedHeadersIds, executionTableState } =
    useTypedSelector(getBimCalcState);
  const [isRowUpdating, setIsRowUpdating] = useState(false);

  const {
    calculation,
    current,
    table,
    isFetching,
    getTable,
    refetchActs,
    data: actResponseData,
    executionDataLength
  } = useContext(ActListContext);

  const { depth, setMaxDepth, maxDepth } = useStepperContext<BimStepper>();
  const firstLoad = useRef(false);
  const [data, setData] = useState<GetExecutionCalculationData | undefined>(
    undefined
  );

  const checkedDepth = useMemo(() => {
    if (depth.executed < 1) return 1;

    return depth.executed > 3 ? 3 : depth.executed;
  }, [depth]);

  const [getChildren, { isLoading: isChildrenLoading }] =
    useGetExecutionCalculationBimChildrenMutation();
  const [getBimExecution] = useGetExecutionCalculationBimMutation();

  const [prices, setPrices] = useState<'curr' | 'base'>('curr');
  const [updateLoader, setUpdateLoader] = useState(false);
  const { calcID } = useParams();
  const numberCalcId = Number(calcID);

  const {
    executionHiddenRowsIds,
    executionTableState: { resetCounter }
  } = useTypedSelector(getBimCalcState);

  const { setValue } = useLocalStorage(
    ENUMLocalStorage.levelsBaseMethodExecute,
    []
  );

  const Ref = useRef<AgGridReact<ExecutionCalculationData> | null>(null);
  const emptyCurr = useMemo(() => {
    let flag = true;
    /**
     * total.curr не пустой - есть текущие цены
     * parts[любая партия].curr - есть текущие цены
     */

    if (
      executionTableState.data?.total &&
      executionTableState.data.total?.[0]?.parts
    ) {
      flag = executionTableState.data.total[0].parts.some((part) => {
        let foundTotalKey: keyof typeof part.curr;
        for (foundTotalKey in part.curr) {
          if (
            !Array.isArray(part.curr[foundTotalKey]) &&
            foundTotalKey !== 'hasIndex' &&
            foundTotalKey !== 'hasError'
          ) {
            if (part.curr[foundTotalKey] !== null) {
              return true;
            }
          }
        }
        return false;
      });
      if (!flag) {
        setPrices('base');
      }
    }
    return !flag;
  }, [executionTableState.data?.total, isFetching]);

  const columnsRef: MutableRefObject<
    | (
        | ColDef<ExecutionCalculationData, any>
        | ColGroupDef<ExecutionCalculationData>
      )[]
    | undefined
  > = useTable(
    executionTableState.data?.total?.[0],
    Ref,
    emptyCurr,
    false,
    undefined,
    current
    // [executionExpandedHeadersIds]
  );

  const [createModal, setCreateModal] = useState(false);
  const [exportModal, setExportModal] = useState('');

  const [filteredData, setFilteredData] = useState<
    ExecutionCalculationData[] | undefined
  >(undefined);

  const changePrices = (price: IPricesSwitch) => {
    setPrices(price.value as typeof prices);
  };

  const [update, updateResponse] = useEditQuantityMutation();

  // const [editingCell, setEditingCell] = useState(false);

  // const onCellEditingStarted = useCallback(() => {
  //   setEditingCell(true);
  // }, []);

  const updateData: UpdateParams = async (params, act, calcID) => {
    // gridRef?.api.showLoadingOverlay();
    // setUpdateLoader(true);
    // params.api.showLoadingOverlay();
    // gridRef?.api.showLoadingOverlay();
    setIsRowUpdating(true);

    try {
      dispatch((dispatch, getState) => {
        const currentState = getBimCalcState(getState());
        const currentTableState = currentState.executionTableState;
        const currentData = [...currentTableState.data.tree];

        const itemIndex = currentData.findIndex(
          (item) => item.id === params.data.id
        );

        if (itemIndex !== -1) {
          let value;
          switch (typeof params.newValue) {
            case 'string':
              value = Number(
                params.newValue.replace(/\s/g, '').replace(',', '.')
              );
              break;
            default:
              value = params.newValue;
          }
          if (
            currentData[itemIndex]?.subtype === 'awaiting_deletion' &&
            !value
          ) {
            const ids = [itemIndex];

            currentData.forEach((el, index) => {
              if (el.parent_id === currentData[itemIndex]?.id) ids.push(index);
            });

            const childrens = currentData.filter(
              (el, index) =>
                el.parent_id === currentData[itemIndex]?.parent_id &&
                !ids.includes(index)
            );

            const folderIndex = currentData.findIndex(
              (el) => el.id === currentData[itemIndex]?.parent_id
            );
            const filterBy = (el: ExecutionCalculationData, index: number) => {
              return !ids.includes(index);
            };
            const filterByWithParent = (
              el: ExecutionCalculationData,
              index: number
            ) => {
              return !ids.concat(folderIndex).includes(index);
            };
            const filter = childrens.length ? filterBy : filterByWithParent;
            // console.group('on Update');
            // console.log('ids', ids);
            // console.log('folderIndex', folderIndex);
            // console.log('childrens', childrens);
            // console.log('currentData.filter', currentData.filter(filter));
            // console.log('currentData', currentData);
            // console.groupEnd();
            const tree = currentData.filter(filter);
            dispatch(
              updateExecutionTableState({
                ...currentTableState,
                data: {
                  ...currentTableState.data,
                  tree,
                  ...(!tree.length && { total: [] })
                }
              })
            );
          } else {
            const updatedParts = currentData[itemIndex].parts.map((value) =>
              value.actID === current?.id
                ? {
                    ...value,
                    quantity: params.newValue as number
                  }
                : value
            );

            currentData[itemIndex] = {
              ...currentData[itemIndex],
              parts: updatedParts
            };

            dispatch(
              updateExecutionTableState({
                ...currentTableState,
                data: { ...currentTableState.data, tree: currentData }
              })
            );
          }
        }
      });

      if (act?.id && calcID) {
        const editedData = {
          actID: act.id,
          calcID: Number(calcID),
          body: {
            rowID: params.data.id,
            quantity:
              params.newValue === undefined ||
              params.newValue === null ||
              params.newValue === '0'
                ? null
                : Number(params.newValue)
          },
          shouldInvalidate: false
        };
        await update(editedData).then(() => {
          refetchActs?.();
        });
        const parentId = params.data.parent_id;
        const rowId = params.data.id;
        if (!parentId) {
          gridRef?.api.hideOverlay();
          setIsRowUpdating(false);
          return;
        }
        await dispatch(async (dispatch, getState) => {
          const currentState = getBimCalcState(getState());
          const currentData = currentState.executionTableState.data.tree;

          const firstLevelTreeIndex =
            currentData
              .filter((item) => !item.parent_id)
              ?.findIndex((item) => item.id === parentId) ?? -1;
          const page =
            firstLevelTreeIndex !== -1
              ? Math.floor(firstLevelTreeIndex / LIMIT)
              : 0;

          // Get updated row and table data
          const [updatedChildren, updatedTableData] = await Promise.all([
            getChildren({
              calcID: Number(calcID),
              rowID: parentId
            }),
            getBimExecution({
              calcID: Number(calcID),
              limit: LIMIT,
              page,
              depth: 1
            })
          ]);

          if (!('data' in updatedChildren)) {
            return;
          }

          // Update the state with the latest data from the server
          const latestState = getBimCalcState(getState());
          const latestTableState = latestState.executionTableState;
          const latestData = [...latestTableState.data.tree];

          const updatedRow = updatedChildren.data.children.find(
            (item) => item.id === rowId
          );

          let updatedParentRow = null;
          if ('data' in updatedTableData) {
            updatedParentRow = updatedTableData.data.tree.find(
              (item) => item.id === parentId
            );
          }

          const itemIndex = latestData.findIndex((item) => item.id === rowId);

          const parentIndex = latestData.findIndex(
            (item) => item.id === parentId
          );

          if (itemIndex !== -1) {
            if (updatedRow)
              latestData[itemIndex] = { ...updatedRow, parent_id: parentId };
            if (updatedParentRow && parentIndex !== -1)
              latestData[parentIndex] = updatedParentRow;

            dispatch(
              updateExecutionTableState({
                ...latestTableState,
                data: {
                  ...latestTableState.data,
                  tree: latestData,
                  ...('data' in updatedTableData && {
                    total: updatedTableData.data.total
                  })
                },
                isFirstDataFetched: true
              })
            );
          }
        });
      }
    } catch (error) {
      // setEditingCell(false);
      // Обработка ошибки
      console.error('Error:', error);
    } finally {
      // gridRef?.api.hideOverlay();
      setIsRowUpdating(false);
    }
    return;
  };
  // useEffect(() => {
  //   if (isFetching && d === undefined) columnsRef.current = undefined;
  //   if (!isFetching) {
  //     setData(d);
  //   }
  // }, [d?.tree, d?.total, isFetching]);
  const activeRequests = useRef(false);
  const collapse = useCallback(
    async (id: number) => {
      const newSet = new Set(executionHiddenRowsIds);
      console.log('activeRequests', activeRequests.current);

      const isHidden = executionHiddenRowsIds.includes(id);
      if (activeRequests.current) return;
      if (!numberCalcId) return;
      activeRequests.current = true;
      // Генерируем уникальный ключ для запроса

      // Проверяем, есть ли уже активный запрос с такими параметрами
      // const controller = new AbortController();
      // activeRequests.current.set(requestKey, controller);

      try {
        if (isHidden) {
          newSet.delete(id);

          // Отправляем запрос с возможностью отмены
          const result = await getChildren({
            calcID: numberCalcId,
            rowID: id
          }).unwrap();

          const children = result.children.map((child) => {
            newSet.add(child.id);
            return {
              ...child,
              parent_id: id
            };
          });

          // Оптимизированная реализация для больших наборов данных
          let newTree: ExecutionCalculationData[] = [];
          if (executionTableState.data?.tree) {
            newTree = new Array(
              executionTableState.data.tree.length + (children?.length || 0)
            );
            let index = 0;

            for (let i = 0; i < executionTableState.data.tree.length; i++) {
              const current = executionTableState.data.tree[i];
              newTree[index++] = current;

              // Вставляем детей после родительского узла
              if (current.id === id && children?.length) {
                for (let j = 0; j < children.length; j++) {
                  newTree[index++] = children[j];
                }
              }
            }

            if (index < newTree.length) {
              newTree.length = index;
            }
          }

          if (executionTableState.data) {
            dispatch(
              updateExecutionTableState({
                ...executionTableState,
                data: { ...executionTableState.data, tree: newTree }
              })
            );
          }
        } else {
          newSet.add(id);
          const newTree: ExecutionCalculationData[] = [];
          if (executionTableState.data?.tree) {
            const treeLength = executionTableState.data.tree.length;
            // Оптимизированная реализация для больших наборов данных
            for (let i = 0; i < treeLength; i++) {
              const item = executionTableState.data.tree[i];
              if (item.parent_id !== id && !newSet.has(item.parent_id || 0)) {
                newTree.push(item);
              } else {
                newSet.add(item.id);
              }
            }
          }

          if (executionTableState) {
            dispatch(
              updateExecutionTableState({
                ...executionTableState,
                data: { ...executionTableState.data, tree: newTree }
              })
            );
          }
        }

        dispatch(updateExecutionHiddenRowsIds(Array.from(newSet)));
        activeRequests.current = false;
      } catch (error: unknown) {
        activeRequests.current = false;
        if (error instanceof Error && error.name === 'AbortError') {
          console.log('Запрос был отменен');
        } else {
          console.error('Ошибка при выполнении запроса:', error);
        }
      } finally {
      }
    },
    [
      activeRequests.current,
      executionTableState,
      executionHiddenRowsIds,
      numberCalcId,
      getChildren,
      dispatch
    ]
  );
  // const debounceCollapse = useMemo(() => debounce(collapse, 500), [collapse]);
  useLayoutEffect(() => {
    if (executionTableState.data?.tree) {
      setMaxDepth('executed', 3);
    }
  }, [executionTableState.data?.tree]);

  const handleExecutionExpandedHeadersIds = useCallback(
    (groupId: string) => {
      const newSet = new Set(executionExpandedHeadersIds);
      newSet.has(groupId) ? newSet.delete(groupId) : newSet.add(groupId);
      dispatch(updateExecutionExpandedHeadersIds(newSet));
    },
    [executionExpandedHeadersIds]
  );

  // useMutationHandlers(
  //   updateResponse,
  //   () => {
  //     enqueueSnackbar('Данные успешно обновлены', {
  //       variant: 'success'
  //     });
  //   },
  //   (error) => {
  //     console.log('<<< ERROR >>>', error);
  //     enqueueSnackbar('Есть проблемы', {
  //       variant: 'error'
  //     });
  //   }
  // );

  useEffect(() => {
    const shouldLoad =
      !executionTableState?.isFirstDataFetched ||
      executionTableState?.depth !== checkedDepth;

    if (calcID && shouldLoad && !firstLoad.current) {
      dispatch(
        updateExecutionTableState({
          ...new ExecutionState(),
          isFirstDataFetched: !!executionTableState?.isFirstDataFetched
        })
      );
      getTable?.(Number(calcID), checkedDepth, 0);
    }
  }, [
    calcID,
    // depth.executed,
    actResponseData,
    resetCounter,
    executionTableState?.isFirstDataFetched
  ]);

  // useEffect(() => {
  //   if ((isFetching && gridRef) || updateLoader) {
  //     gridRef?.api.showLoadingOverlay();
  //     // setFilteredData([]);
  //     // oneLoadData.current = false;
  //   }
  // }, [isFetching, gridRef, updateLoader]);

  const contextTable = useMemo(
    () => ({
      calcID,
      emptyCurr,
      collapse,
      current,
      updateData,
      filteredData,
      prices,
      total: executionTableState.data?.total?.[0],
      executionHiddenRowsIds,
      executionExpandedHeadersIds,
      handleExecutionExpandedHeadersIds
    }),
    [
      prices,
      updateData,
      current,
      executionExpandedHeadersIds,
      handleExecutionExpandedHeadersIds
    ]
  );

  useEffect(() => {
    dispatch(resetBimExecution());
  }, [depth]);

  useEffect(() => {
    if (isFetching) {
      if (!updateLoader) {
        setFilteredData(undefined);
        setData(undefined);
        columnsRef.current = undefined;
      }
    }
  }, [isFetching, updateLoader]);

  useBreadcrumbs(
    [
      { title: <ProjectLabel /> },
      {
        title: 'Расчеты',
        url: `/projects/${projectID}/calculations`
      },
      {
        title: calculation?.title ? (
          <CalculationLabel
            title={calculation?.title}
            type={calculation?.type}
          />
        ) : (
          'Предпросмотр'
        ),
        url: `projects/${projectID}/calculation/${calculation?.id}/edit`
      }
    ],
    [calculation?.title]
  );
  const doesExternalFilterPass = (
    params: IRowNode<ExecutionCalculationData>
  ) => {
    try {
      Ref.current?.api.setIsExternalFilterPresent(() => false);
      return params.data?.parent_id
        ? !executionHiddenRowsIds.includes(params.data.parent_id)
        : true;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    if (!firstLoad.current) {
      setValue((prevState) => {
        const isFind = prevState?.find((level) => String(level.id) === calcID);
        if (isFind) {
          return prevState?.map((level) => {
            if (String(level.id) === calcID) {
              return {
                id: Number(calcID),
                levels: executionHiddenRowsIds
              };
            }
            return level;
          });
        } else {
          return [
            ...(prevState ?? []),
            {
              id: Number(calcID),
              levels: executionHiddenRowsIds
            }
          ];
        }
      });
    }
  }, [executionHiddenRowsIds]);

  useEffect(() => {
    Ref.current?.api?.setIsExternalFilterPresent(() => true);
    Ref.current?.api?.onFilterChanged();
  }, [
    doesExternalFilterPass,
    Ref.current,
    location.pathname,
    executionHiddenRowsIds
  ]);

  const updateVisibleRows = useCallback(() => {
    if (gridRef?.api && !executionTableState?.endFetched) {
      const isLastRowFullyVisible = detectLastRowInView(gridRef);

      if (isLastRowFullyVisible && executionTableState.data?.tree?.length) {
        const currentPage = table?.page ?? 0;
        dispatch(
          updateExecutionTableState({
            ...executionTableState,
            page: executionTableState.page + 1,
            isUpdating: true
          })
        );

        setTimeout(() => {
          getTable?.(Number(calcID), depth.executed, currentPage + 1);
        }, 300);
      }
    }
  }, [gridRef, executionTableState, calcID]);
  const debouncedUpdateVisibleRows = useMemo(
    () => debounce(updateVisibleRows, 200),
    [updateVisibleRows, executionTableState]
  );

  useEffect(() => {
    if (Ref.current && Ref.current?.api) {
      Ref.current.api.refreshCells({ force: true });
      // if ag grid not rendered it my cause error
      try {
        Ref.current.api.refreshHeader();
      } catch (error) {
        console.warn('Failed to refresh grid header:', error);
      }
    }
  }, [contextTable]);

  return (
    <PageStyled>
      <AgContext.Provider value={contextTable}>
        <CaptionTable
          disableCurr={emptyCurr}
          act={() => setCreateModal(true)}
          exportKS={(str: string) => setExportModal(str)}
          prices={prices}
          changePrices={changePrices}
          priceSwitches={priceSwitches}
        />

        {!!executionTableState?.data?.tree?.length &&
          (isChildrenLoading ||
            isFetching ||
            executionTableState.isUpdating) && (
            <CircularProgress
              size={50}
              sx={{
                zIndex: 100,
                position: 'absolute',
                top: '50%',
                left: '50%',
                translate: '-50% -50%'
              }}
            />
          )}
        <WrapperAgGrid className="ag-theme-material reference-prices">
          <AgGridReact
            ref={Ref}
            onGridReady={(e) => {
              dispatch(setGridRef(e));
            }}
            onViewportChanged={debouncedUpdateVisibleRows}
            onBodyScroll={debouncedUpdateVisibleRows}
            context={contextTable}
            enableCellTextSelection
            ensureDomOrder
            maintainColumnOrder
            columnDefs={columnsRef.current}
            defaultColDef={defaultColDef}
            groupHeaderHeight={25}
            singleClickEdit
            gridOptions={{
              components: {
                CustomTableHeader
              }
              // onCellEditingStarted,
              // suppressDragLeaveHidesColumns: true,
              // navigateToNextHeader: () => null,
              // tabToNextHeader: () => null
            }}
            pinnedTopRowData={executionTableState?.data?.total}
            rowData={executionTableState?.data?.tree}
            suppressCellFocus
            onFirstDataRendered={(event) => {
              event.api.sizeColumnsToFit();
            }}
            // onGridSizeChanged={(event: GridSizeChangedEvent) => {
            //   event.api.sizeColumnsToFit();
            // }}
            // onViewportChanged={(event) => {
            //   event.api.sizeColumnsToFit();
            // }}
            getRowId={(params) => {
              return params.data.id.toString();
            }}
            getRowClass={getRowClass}
            getRowHeight={(params) => {
              if (params.node.rowPinned === 'top') {
                return 50;
              }
              return 55;
            }}
            rowStyle={{
              padding: '0 !important'
            }}
            rowHeight={55}
            headerHeight={36}
            doesExternalFilterPass={doesExternalFilterPass}
            loadingOverlayComponent={Progress}
            noRowsOverlayComponent={
              executionTableState.isFirstDataFetched &&
              !executionTableState?.data?.tree.length &&
              !executionDataLength
                ? NoRows
                : Progress
            }></AgGridReact>
        </WrapperAgGrid>
        <ActDialog
          data={actResponseData}
          update={(f) => {
            columnsRef.current = undefined;
            gridRef?.api.showLoadingOverlay();
            //setUpdateLoader(f);
            if (f) {
              dispatch(updateExecutionHiddenRowsIds([]));
              dispatch(
                updateExecutionTableState({
                  ...new ExecutionState(),
                  isFirstDataFetched: !!table?.isFirstDataFetched
                })
              );
              firstLoad.current = false;
            }
            // setFilteredData(null);
          }}
          close={() => setCreateModal(false)}
          open={createModal}
        />
        <ExportDialog close={() => setExportModal('')} open={exportModal} />
      </AgContext.Provider>
    </PageStyled>
  );
};

export default Accomplishment;
