import React, { useCallback, useContext } from 'react';

import { ActListContext } from 'pages/Calculations/components/CalculationСomplicated';
import { ICellRendererParams } from 'ag-grid-community';
import { IComplicatedIndexMethod } from 'api/calculations/types';
import { StyledCheckbox } from '../../styles';
import { CellWrapper } from 'pages/Calculations/components/Accomplishment/components/Cell/Cell.style';

interface IRowCheckbox
  extends ICellRendererParams<IComplicatedIndexMethod, any, any> {
  color?: string;
  className?: string;
  originData?: string | number | null | React.ReactNode;
  style?: React.CSSProperties;
  innerStyle?: React.CSSProperties;
}

export const RowCheckbox: React.FC<IRowCheckbox> = ({
  data,
  node,
  className,
  color,
  style
}) => {
  const { setSelectedRowsIds, selectedRowsIds } = useContext(ActListContext);

  /**
   * Функция клика по чекбоксу, в зависимости от условий
   * либо добавляет в массив к удалению расценку\позицию, либо убирает
   */
  const clickCheckbox = useCallback(
    (id: number) => {
      if (!setSelectedRowsIds) return;
      setSelectedRowsIds((prevState) => {
        const indexId = prevState.findIndex(
          (selectedRowId) => selectedRowId === id
        );
        const copyRemoved = [...prevState];

        if (indexId >= 0) {
          copyRemoved.splice(indexId, 1);
        } else {
          copyRemoved.push(id);
        }
        return copyRemoved;
      });
    },
    [setSelectedRowsIds]
  );

  return (
    <CellWrapper className={className} style={style} color={color}>
      {data?.type == 'level' && node.rowPinned != 'top' && (
        <StyledCheckbox
          size="small"
          onChange={() => (data?.id ? clickCheckbox(data.id) : false)}
          checked={
            selectedRowsIds && data?.id
              ? selectedRowsIds.findIndex((elId) => elId === data?.id) >= 0
              : false
          }
        />
      )}
    </CellWrapper>
  );
};
