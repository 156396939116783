import { Menu, MenuItem, Stack } from '@mui/material';
import {
  useDeleteCalculationMutation,
  useGetActListQuery,
  useGetExecutionCalculationBimMutation
} from 'api/calculations';
import { useCalcId } from 'hooks/useCalcId';
import useConfirmDialog, { UseExitConfirmProps } from 'hooks/useConfirmDialog';
import { useMutationHandlers } from 'hooks/useMutationHandlers';
import { useProjectId } from 'hooks/useProjectId';
import { useSnackbar } from 'notistack';
import { VorPage } from 'pages/Vor';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  clear,
  getBimCalcState,
  updateExecutionHiddenRowsIds,
  updateExecutionTableState
} from 'store/slices/calculations/bim/bim.slice';
import { useAppDispatch, useTypedSelector } from 'store/store';
import { ActList, CalcData, LevelNameTypeEn } from 'types';
import { addData, deleteData, getData, openDB } from 'utils/indexDB';
import { StepperContext } from '../../../../hooks/useStepper';
import Accomplishment from '../Accomplishment';
import CalculationLegend from '../CalculationBasic/components/CalculationLegend/CalculationLegend';
import CalculationDrawer from '../CalculationDrawer';
import Resources from '../Resources/Resources';
import { EstimateComplicated } from './components/EstimateСomplicated';
import IntegrateDrawer from './components/IntegrateDrawer';
import { ExecutionState } from './CalculationСomplicated.types';
import { LIMIT } from './CalculationСomplicated.constants';
import { AddCalculationDrawer } from '../AddCalculationDrawer';
import { ActListContext } from '.';

export type LevelNameShowType = {
  name: LevelNameTypeEn;
  show: boolean;
};
export type CalculationComplicatedProps = {
  calculation?: CalcData;
  openEdit: (b: boolean) => void;
  copyDrawer: boolean;
  toggleOpenCopy: (f?: boolean) => void;
};

export type BimStepper = { calculation: number; executed: number };
const CalculationComplicated = ({
  calculation,
  openEdit,
  copyDrawer,
  toggleOpenCopy
}: CalculationComplicatedProps) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();

  const projectID = useProjectId();
  const calcId = useCalcId();

  const { t } = useTranslation('user');
  const { executionTableState, executionHiddenRowsIds } =
    useTypedSelector(getBimCalcState);

  const [
    updateTable,
    { originalArgs, isLoading, data: executionCalculationData }
  ] = useGetExecutionCalculationBimMutation();
  const getDataExecution = async (
    id: number,
    depth?: number,
    page?: number,
    shouldClearState = false
  ) => {
    dispatch(
      updateExecutionTableState(
        shouldClearState
          ? new ExecutionState()
          : {
              ...executionTableState,
              isUpdating: true,
              page: page ?? executionTableState.page
            }
      )
    );

    const res = await updateTable({
      calcID: id,
      ...(depth && { depth }),
      page: page ?? executionTableState.page,
      limit: LIMIT
    });

    if ('data' in res) {
      const isDepthChanged = executionTableState.depth !== depth;
      const isPageChanged = originalArgs && originalArgs.page !== page;
      const isUpdatingEnded = !executionTableState.isUpdating;

      dispatch(
        updateExecutionTableState({
          ...new ExecutionState(
            isDepthChanged ? undefined : executionTableState,
            res.data,
            {
              page: originalArgs?.page
            }
          ),
          depth: depth && depth < 1 ? 1 : depth && depth! > 3 ? 3 : depth || 1,
          ...originalArgs,
          page: page ?? executionTableState.page,
          isUpdating: false
        })
      );
      if (
        ((isUpdatingEnded && (isDepthChanged || isPageChanged)) ||
          !isUpdatingEnded) &&
        res.data?.tree
      ) {
        const isFirstDataChunks = page === 0;
        const hiddenIds: number[] = isFirstDataChunks
          ? []
          : [...executionHiddenRowsIds];
        res.data?.tree?.forEach((item) => {
          const lvl = item.type === 'file' ? 1 : item.type === 'level' ? 2 : 3;
          // lvl = 3 all rows is open
          if (depth === 3) return;
          if (depth && lvl >= depth) {
            hiddenIds.push(item.id);
          }
        });
        dispatch(updateExecutionHiddenRowsIds(hiddenIds));
      }

      // dispatch(
      //   updateExecutionTableState({
      //     ...new ExecutionState(
      //       isDepthChanged ||
      //       shouldClearState ||
      //       (executionTableState.isFirstDataFetched && !isPageChanged)
      //         ? undefined
      //         : executionTableState,
      //       res.data,
      //       {
      //         page: originalArgs?.page
      //       }
      //     ),
      //     isUpdating: false,
      //     isFirstDataFetched: true,
      //     page: page ?? executionTableState.page,
      //     ...(depth && { depth })
      //   })
      // );
    }
  };

  const [integrateDrawer, setIntegrateDrawer] = useState(false);
  const toggleDrawer = useCallback((flag = false) => {
    setIntegrateDrawer(flag);
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  const params = new URLSearchParams(useLocation().search);
  const isPreview = params.get('state') === 'preview';

  const hideButtons = useTypedSelector((state) => state.vor.table.isAddingVor);

  const [selectedTab, setSelectedTab] = useState<number>(
    Number(searchParams.get('tab'))
  );

  // ******* Act list *******

  const {
    data,
    refetch,
    isLoading: isLoadingActs,
    isFetching: isFetchingActs
  } = useGetActListQuery({ calcID: calcId! });
  const [currentAct, setCurrentAct] = useState<ActList | null>(null);

  useEffect(() => {
    if (data && calcId && data?.actList) {
      openDB().then(async (db) => {
        const act = await getData(db, calcId);
        if (act) {
          const find = data.actList.find((_) => _?.id === act?.act?.id);
          if (find) {
            changeCurrentAct(find);
          } else {
            changeCurrentAct(act.act);
          }
        } else {
          const lastAct = data.actList[data.actList.length - 1];
          if (lastAct) {
            addData(db, { id: Number(calcId), act: lastAct }).then(() => {
              setCurrentAct(lastAct);
            });
          }
        }
      });
    }
  }, [data, calcId]);

  useEffect(() => {
    return () => {
      dispatch(clear());
    };
  }, []);

  const changeCurrentAct = useCallback(
    (act: ActList | null) => {
      openDB().then(async (db) => {
        if (act !== null) {
          addData(db, { id: Number(calcId), act }).then(() => {
            setCurrentAct(act);
          });
        } else {
          deleteData(db, calcId).then(() => {
            setCurrentAct(null);
          });
        }
      });
    },
    [currentAct, calcId]
  );

  // ************************

  const handleSelectedTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    setSearchParams(
      (prev) => {
        let newParams;
        if (prev.get('state')) {
          newParams = {
            state: prev.get('state'),
            tab: String(newValue)
          };
        } else {
          newParams = { tab: String(newValue) };
        }

        return newParams as any;
      },
      { replace: true }
    );
  };

  useEffect(() => {
    if (!Number(searchParams.get('tab'))) {
      setSearchParams(
        (prev) => {
          let newParams;

          if (prev.get('state')) {
            newParams = {
              state: prev.get('state'),
              tab: '0'
            };
          } else {
            newParams = { tab: '0' };
          }

          return newParams as any;
        },
        { replace: true }
      );
    }
    setSelectedTab(Number(searchParams.get('tab')));
  }, [searchParams]);

  // ******* Menu Calculation *******
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const MenuOpen = Boolean(anchorEl);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOpenMenuCalculation = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  // ******* Delete Calculation *******
  const [deleteCalculation, deleteCalculationResponse] =
    useDeleteCalculationMutation();

  const handleDeleteCalculationClick = useCallback(
    (actionName: string) => {
      openConfirmDelete(actionName);
    },
    [calculation?.id]
  );

  useMutationHandlers(deleteCalculationResponse, () => {
    navigate(`/projects/${projectID}/calculations`, { replace: true });
    enqueueSnackbar(t('success.calculationDelete'), {
      variant: 'success'
    });
  });

  // ******* dialog Confirm *******
  const handleConfirmDelete = useCallback((confirm: boolean) => {
    if (confirm) {
      deleteCalculation({ projectID, calcID: calculation?.id || 0 }).then(
        () => {
          openDB().then((db) => deleteData(db, calculation!.id));
        }
      );
    }
  }, []);

  const {
    ConfirmDialog: ConfirmDiaologDelete,
    openConfirm: openConfirmDelete
  } = useConfirmDialog({
    title: 'Вы уверены?',
    body: 'Расчёт будет удалён и восстановить его будет невозможно',
    handleConfirm: handleConfirmDelete
  });

  /***************************** Drawer Add Calculation ****************************************/
  const [isAddDrawerOpen, setIsAddDrawerOpen] = useState<boolean>(false);

  /***************************** Drawer Edit Calculation ****************************************/
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      setIsDrawerOpen(false);
    }
  }, []);

  const dataForConfirmDialog: UseExitConfirmProps = {
    title: 'Выйти из расчета?',
    body: 'Внесенные изменения не сохранятся. Продолжить?',
    confirmButtonText: 'Да',
    denyButtonText: 'Нет',
    handleConfirm
  };

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog);

  const onDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    immediately || !dirty ? setIsDrawerOpen(false) : openConfirm();
  }, []);

  // ******* Edit Calculation  *******

  const Content = useMemo(() => {
    switch (selectedTab) {
      case 0:
        return EstimateComplicated;
      case 1:
        return VorPage;
      case 2:
        return Resources;
      default:
        return Accomplishment;
    }
  }, [selectedTab]);

  useEffect(() => {
    dispatch(clear());
  }, [calcId]);

  const [selectedRowsIds, setSelectedRowsIds] = useState<number[]>([]);

  return (
    <Stack flex={1} maxWidth="100%" sx={{ height: '100%' }}>
      <ActListContext.Provider
        value={{
          data,
          current: currentAct,
          setCurrent: changeCurrentAct,
          table:
            isLoading &&
            (!executionTableState.isFirstDataFetched ||
              executionTableState?.page === 0)
              ? undefined
              : executionTableState,
          isFetching: isLoading && !executionTableState.isFirstDataFetched,
          refetchActs: refetch,
          isFetchActs: isLoadingActs || isFetchingActs || isLoading,
          getTable: getDataExecution,
          calculation,
          selectedRowsIds,
          setSelectedRowsIds,
          executionDataLength: executionCalculationData?.tree.length
        }}>
        <StepperContext defaultValue={{ calculation: 1, executed: 1 }}>
          <>
            {calculation && !isPreview && (
              <CalculationLegend
                openDrawer={() => toggleDrawer(true)}
                hideButton={hideButtons}
                currentAct={currentAct}
                calculation={calculation}
                handleOpenMenuCalculation={handleOpenMenuCalculation}
                selectedTab={selectedTab}
                calculationStatus={status}
                handleSelectedTab={handleSelectedTab}
              />
            )}

            <Stack flexGrow={1} overflow="auto">
              <Content calculation={calculation} />
            </Stack>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={MenuOpen}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}>
              <MenuItem
                onClick={() => {
                  toggleOpenCopy(true);
                  setAnchorEl(null);
                }}>
                Создать копию расчета
              </MenuItem>
              <MenuItem
                onClick={() => handleDeleteCalculationClick('Delete')}
                disabled={deleteCalculationResponse.isLoading}>
                Удалить расчет
              </MenuItem>
              <MenuItem
                onClick={() => {
                  openEdit(true);
                  setAnchorEl(null);
                }}>
                Редактировать
              </MenuItem>
              {selectedTab === 0 && (
                <MenuItem
                  onClick={() => {
                    setIsAddDrawerOpen(true);
                    setAnchorEl(null);
                  }}>
                  Добавить ЛСР
                </MenuItem>
              )}
            </Menu>
            <IntegrateDrawer
              actList={data?.actList ?? []}
              act={currentAct}
              open={integrateDrawer}
              changeAct={changeCurrentAct}
              close={() => {
                toggleDrawer();
              }}
              calculation={calculation}
            />
            <AddCalculationDrawer
              calculationId={calculation?.id || 0}
              open={isAddDrawerOpen}
              onClose={() => setIsAddDrawerOpen(false)}
              calculationType={calculation?.type}
              contextType="bim"
            />
          </>
        </StepperContext>
      </ActListContext.Provider>
      <CalculationDrawer
        open={isDrawerOpen}
        onClose={onDrawerClose}
        calculationId={calculation?.id || 0}
      />
      <ConfirmDialog />
      <ConfirmDiaologDelete />
    </Stack>
  );
};

export default CalculationComplicated;
