import { CustomCellEditorProps } from '@ag-grid-community/react';
import FunctionsIcon from '@mui/icons-material/Functions';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Typography } from '@mui/material';
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  HeaderClassParams,
  ICellRendererParams,
  NewValueParams,
  ValueGetterParams,
  ValueSetterParams
} from 'ag-grid-community';
import { PopperComponent } from 'components/PopperComponent';
import Cell from 'pages/Calculations/components/Accomplishment/components/Cell';
import { InputEdit } from 'pages/Calculations/components/Accomplishment/components/Cell/Cell.style';
import { Level } from 'pages/Calculations/components/Accomplishment/components/LevelExecution/LevelExecution.style';
import { generateNameHeader } from 'pages/Calculations/components/Accomplishment/helper';
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  ENUMResourceTypeToHumanObject,
  HandbookExecutionRowPartData,
  HandbookExecutionTreeData
} from 'types';
import { replaceStringFromServer } from 'utils/replaceStringFromServer';
import {
  ExecutedTabCellLevelFolder,
  ExecutedTabContext,
  IExecutedTabContext
} from '.';
import {
  SvgExecutedRate,
  SvgExecutedRatePosition
} from '../../../../../../assets/SVG/SVG';
import CustomTableHeader from '../../../Accomplishment/components/CustomTableHeader';

const classHeader = (
  props: HeaderClassParams<HandbookExecutionTreeData>,
  type:
    | 'header-top-border-executed'
    | 'header-left-border-executed'
    | 'header-right-border-executed'
    | 'header-right-border-executed header-left-border-executed',
  actID?: number | null
) => {
  return actID === props?.context?.current?.id ? type : '';
};

export function useTables(
  totalParts: HandbookExecutionRowPartData[]
):
  | (
      | ColDef<HandbookExecutionTreeData>
      | ColGroupDef<HandbookExecutionTreeData>
    )[]
  | undefined {
  const refColumns = useRef<
    | (
        | ColDef<HandbookExecutionTreeData>
        | ColGroupDef<HandbookExecutionTreeData>
      )[]
    | undefined
  >([]);

  useLayoutEffect(() => {
    if (totalParts.length) {
      refColumns.current = [
        ...getHeadersPartsDefault(),
        ...getHeadersPartsAdditional(totalParts)
      ];
      // setRefColumns([
      //   ...getHeadersPartsDefault(),
      //   ...getHeadersPartsAdditional(totalParts)
      // ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalParts.length]);
  return refColumns.current;
}

const classCell = (
  props: CellClassParams<HandbookExecutionTreeData>,
  type: 'header-left-border-executed' | 'header-right-border-executed',
  actID?: number | null
) => {
  return actID === props?.context?.current?.id ? type : '';
};
// export const getEndHeadersPartsDefault = (): (
//   | ColDef<HandbookExecutionTreeData>
//   | ColGroupDef<HandbookExecutionTreeData>
// )[] => {
//   return [
//     {
//       field: 'basis',
//       pinned: 'left',
//       lockPinned: true,
//       width: 124,
//       minWidth: 124,
//       suppressMovable:true,
//       // maxWidth: 124,
//       headerName: 'Основание',
//       cellRenderer: (props: any) => cellRenderer(props),
//       cellRendererParams: {
//         float: 'left'
//       }
//     },
//     {
//       field: 'note',
//       pinned: 'left',
//       lockPinned: true,
//       width: 124,
//       minWidth: 124,
//       suppressMovable: false,
//       // maxWidth: 124,
//       headerName: 'Примечание',
//       cellRenderer: (props: any) => cellRenderer(props),
//       cellRendererParams: {
//         float: 'left'
//       }
//     }
//   ];
// };
export const getHeadersPartsDefault = (): (
  | ColDef<HandbookExecutionTreeData>
  | ColGroupDef<HandbookExecutionTreeData>
)[] => {
  return [
    {
      field: 'lvl',
      pinned: 'left',
      lockPinned: true,
      lockPosition: true,
      resizable: false,
      headerName: 'Ур',
      suppressMovable: true,
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      colSpan: (params: any) => {
        return params.node?.rowPinned === 'top' ? 5 : 1;
      },
      // colSpan: (params) => {
      //   return  ? 1 : 0;
      // },

      cellRenderer: (props: any) => cellRenderer(props),
      cellRendererParams: {
        isLevel: true,
        float: 'left',
        isCustomPinned: true
      }
    },
    {
      field: 'num',
      pinned: 'left',
      width: 124,
      minWidth: 124,
      suppressMovable: false,
      // maxWidth: 124,
      headerName: '№ п\\п',
      cellRenderer: (props: any) => cellRenderer(props),
      cellRendererParams: {
        float: 'center'
      },
      colSpan: (params) => {
        return params.node?.rowPinned === 'top' ? 7 : 0;
      }
    },
    {
      field: 'resourceType',
      pinned: 'left',
      width: 188,
      suppressMovable: false,
      // maxWidth: 188,
      minWidth: 188,
      headerName: 'Тип ресурса',
      cellRenderer: (props: any) => cellRenderer(props),
      cellRendererParams: {
        float: 'left',
        isResourceType: true
      }
    },
    {
      field: 'code',
      pinned: 'left',
      width: 124,
      minWidth: 124,
      suppressMovable: false,
      // maxWidth: 124,
      headerName: 'Шифр',
      cellRenderer: (props: any) => cellRenderer(props),
      cellRendererParams: {
        float: 'center'
      }
    },
    {
      field: 'title',
      pinned: 'left',
      width: 428,
      minWidth: 428,
      suppressMovable: false,
      // maxWidth: 428,
      headerName: 'Наименование',
      cellRenderer: (props: any) => cellRenderer(props),
      cellRendererParams: {
        float: 'left'
      }
    },
    {
      field: 'unit',
      width: 148,
      suppressMovable: false,
      // maxWidth: 148,
      minWidth: 148,
      headerName: 'Ед. изм.',
      cellRenderer: (props: any) => cellRenderer(props),
      cellRendererParams: {
        float: 'center'
      }
    }
    // {
    //   pinned: 'left',
    //   lockPinned: true,
    //   width: 248,
    //   // suppressMovable: false,
    //   // maxWidth: 248,
    //   minWidth: 248,
    //   headerName: 'Количество',
    //   children: [
    //     {
    //       colSpan: (params) => {
    //         return params.data?.type !== 'RATE_POSITION' ? 2 : 1;
    //       },
    //       field: 'qtyPerUnit',
    //       valueGetter: (params: ValueGetterParams) => {
    //         if (params.data?.type === 'RATE') {
    //           return params?.data?.qtyTotal;
    //         }
    //         return params?.data?.qtyPerUnit;
    //       },
    //       width: 124,
    //       minWidth: 124,
    //       suppressMovable: false,
    //       // maxWidth: 124,
    //       lockPinned: true,
    //       headerName: 'На единицу',
    //       pinned: 'left',
    //       cellRenderer:(props)=>cellRenderer(props,idx,errors),
    //       cellRendererParams: {
    //         float: 'center',
    //         decimalMax: 6
    //       }
    //     },
    //     {
    //       field: 'qtyTotal',
    //       width: 124,
    //       minWidth: 124,
    //       suppressMovable: false,
    //       // maxWidth: 124,
    //       lockPinned: true,
    //       headerName: 'Всего',
    //       pinned: 'left',
    //       cellRenderer:(props)=>cellRenderer(props,idx,errors),
    //       cellRendererParams: {
    //         float: 'center',
    //         decimalMax: 6
    //       }
    //     }
    //   ]
    // }
  ];
};

export function checkError(parts: HandbookExecutionRowPartData[] = []) {
  const result: { [key: string]: boolean } = {};
  let total = 0;
  const temp = parts.reduce(
    (acc, act, index) => {
      if (!acc[index]) {
        acc[index] = {
          value: 0,
          type: '',
          total: 0
        };
        acc[index].type = act.type;
        if (acc[index].type === 'total') {
          acc[index].value = 0;
          acc[index].total = act.quantity ?? 0;
          total = acc[index].total;
        } else if (acc[index].type === 'act') {
          if (acc[index - 1]) {
            acc[index].value = acc[index - 1].value + (act.quantity ?? 0);
            acc[index].total = total;
          }
        } else {
          acc[index].value = act.quantity ?? 0;
          acc[index].total = total;
        }
      }

      return acc;
    },
    {} as { [key: string]: { value: number; type: string; total: number } }
  );

  for (const index in temp) {
    const tempKey = Number(index);
    const currentPart = temp[tempKey];

    if (currentPart.type !== 'total') {
      if (currentPart.type !== 'rest') {
        if (currentPart.total > 0) {
          result[tempKey] = currentPart.value > currentPart.total;
        } else {
          result[tempKey] = currentPart.value < currentPart.total;
        }
      } else {
        if (currentPart.total > 0) {
          result[tempKey] = currentPart.value < 0;
        } else {
          result[tempKey] = currentPart.value > 0;
        }
      }
    }
  }
  return result;
}

function isHaveColorRow(
  type: 'FOLDER' | 'RATE_POSITION' | 'RATE' | 'SUM',
  idPart: number,
  err: any
) {
  if (type === 'FOLDER' || type === 'SUM') return false;
  return err?.[idPart] || false;
}

export const getHeadersPartsAdditional = (
  exampleParts: HandbookExecutionRowPartData[] | null
) => {
  return (
    exampleParts?.reduce(
      (
        acc: (
          | ColDef<HandbookExecutionTreeData>
          | ColGroupDef<HandbookExecutionTreeData>
        )[],
        part,
        idx
      ) => {
        let headerName = '';
        let ichActHHeader = false;
        switch (part?.type) {
          case 'total':
            headerName = 'Всего';
            break;
          case 'executed':
            headerName = 'Выполнено';
            break;
          case 'rest':
            headerName = 'Остаток';
            break;
          default:
            ichActHHeader = true;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            headerName = generateNameHeader(part.startDate!, part.endDate!);
            break;
        }
        const errors: { [key: number]: boolean } = {};

        acc.push({
          field: `parts.${idx}`,
          headerName,
          suppressMovable: true,
          lockPinned: true,
          marryChildren: true,
          // suppressMovable: false,
          headerClass: (props) =>
            classHeader(props, 'header-top-border-executed', part.actID),
          headerGroupComponent: CustomTableHeader,
          children: [
            ...(headerName !== 'Всего'
              ? ([
                  {
                    headerClass: (props: any) =>
                      classHeader(
                        props,
                        'header-left-border-executed',
                        part.actID
                      ),
                    cellClass: (props: any) =>
                      classCell(
                        props,
                        'header-left-border-executed',
                        part.actID
                      ),
                    field: `parts.${idx}.quantity`,
                    // suppressMovable: false,
                    minWidth: 189,
                    headerName: 'Кол-во',
                    lockPinned: true,
                    suppressMovable: true,
                    editable: ({ data, context }: any) => {
                      return ichActHHeader
                        ? data?.type === 'RATE' &&
                            context?.current?.id === part?.actID
                        : false;
                    },
                    cellRenderer: (
                      props: ICellRendererParams<
                        HandbookExecutionTreeData,
                        unknown,
                        IExecutedTabContext
                      >
                    ) => cellRenderer(props, idx),
                    cellRendererParams: {
                      idx,
                      float: 'right',
                      isAct: true,
                      decimalMax: 6
                    },
                    cellEditor: ExecutedTabCellEditable,

                    onCellValueChanged: (
                      params: NewValueParams<
                        HandbookExecutionTreeData,
                        HandbookExecutionRowPartData['quantity']
                      >
                    ) => {
                      params?.context?.onUpdate(
                        params.newValue,
                        'quantity',
                        part?.actID,
                        params.data.id
                      );
                    },
                    valueGetter: (
                      params: ValueGetterParams<
                        HandbookExecutionTreeData,
                        HandbookExecutionRowPartData['quantity']
                      >
                    ) => {
                      return params?.data?.parts?.[idx]?.quantity;
                    },
                    valueSetter: (
                      params: ValueSetterParams<
                        HandbookExecutionTreeData,
                        HandbookExecutionRowPartData['quantity']
                      >
                    ) => {
                      const updatedData = {
                        ...params.data,
                        parts: params.data.parts.map((e) =>
                          e.actID === part.actID
                            ? { ...e, quantity: params.newValue }
                            : e
                        )
                      };
                      params.api.applyTransaction({ update: [updatedData] });
                      return true;
                    }
                  }
                ] as any)
              : []),
            ...(headerName === 'Всего'
              ? [
                  {
                    width: 248,
                    // suppressMovable:true,
                    // maxWidth: 248,
                    minWidth: 248,
                    headerName: 'Количество',
                    suppressMovable: true,
                    lockPinned: true,
                    marryChildren: true,
                    children: [
                      {
                        colSpan: (params: any) => {
                          return params.data?.type !== 'RATE_POSITION' ? 2 : 1;
                        },
                        field: 'qtyPerUnit',
                        valueGetter: (params: ValueGetterParams) => {
                          if (params.data?.type === 'RATE') {
                            return params?.data?.qtyTotal;
                          }
                          return params?.data?.qtyPerUnit;
                        },
                        width: 124,
                        minWidth: 124,
                        lockPinned: true,
                        suppressMovable: true,
                        // maxWidth: 124,
                        headerName: 'На единицу',
                        cellRenderer: (
                          props: ICellRendererParams<
                            HandbookExecutionTreeData,
                            unknown,
                            IExecutedTabContext
                          >
                        ) => cellRenderer(props, idx),
                        cellRendererParams: {
                          idx,
                          float: 'center',
                          decimalMax: 6
                        }
                      },
                      {
                        field: 'qtyTotal',
                        width: 124,
                        minWidth: 124,
                        lockPinned: true,
                        suppressMovable: true,
                        // maxWidth: 124,
                        headerName: 'Всего',
                        cellRenderer: (
                          props: ICellRendererParams<
                            HandbookExecutionTreeData,
                            unknown,
                            IExecutedTabContext
                          >
                        ) => cellRenderer(props, idx),
                        cellRendererParams: {
                          idx,
                          float: 'center',
                          decimalMax: 6
                        }
                      }
                    ]
                  }
                ]
              : []),
            {
              headerClass: (props) =>
                classHeader(props, 'header-right-border-executed', part.actID),
              columnGroupShow: 'closed',
              width: 174,
              // suppressMovable:true,
              maxWidth: 174,
              minWidth: 174,
              headerName: 'Всего стоимость',
              suppressMovable: true,
              lockPinned: true,
              marryChildren: true,
              children: [
                {
                  headerClass: (props) =>
                    classHeader(
                      props,
                      'header-right-border-executed',
                      part.actID
                    ),
                  cellClass: (props) =>
                    classCell(
                      props,
                      'header-right-border-executed',
                      part.actID
                    ),
                  field: `parts.${idx}`,
                  width: 174,
                  // suppressMovable:true,
                  maxWidth: 174,
                  minWidth: 174,
                  suppressMovable: true,
                  lockPinned: true,
                  headerComponent: () => {
                    const { prices } = useContext(ExecutedTabContext);

                    return (
                      <span style={{ textAlign: 'center', width: '100%' }}>
                        В {prices === 'base' ? 'базовых' : 'текущих'} ценах
                      </span>
                    );
                  },
                  cellRenderer: (
                    props: ICellRendererParams<
                      HandbookExecutionTreeData,
                      unknown,
                      IExecutedTabContext
                    >
                  ) => cellRenderer(props, idx),
                  cellRendererParams: {
                    idx,

                    isPart: true,
                    decimalMax: 2
                  }
                }
              ]
            },
            {
              field: `parts.${idx}.base`,
              // suppressMovable:true,
              columnGroupShow: 'open',
              headerName: 'В базовых ценах',
              suppressMovable: true,
              lockPinned: true,
              marryChildren: true,
              children: [
                {
                  // suppressMovable:true,
                  headerName: 'Стоимость единицы',
                  suppressMovable: true,
                  lockPinned: true,
                  marryChildren: true,
                  children: [
                    {
                      field: `parts.${idx}.base.fotPerUnit`,
                      width: 136,
                      minWidth: 136,
                      // suppressMovable:true,
                      headerName: 'ФОТ ОР',
                      suppressMovable: true,
                      lockPinned: true,
                      cellRenderer: (
                        props: ICellRendererParams<
                          HandbookExecutionTreeData,
                          unknown,
                          IExecutedTabContext
                        >
                      ) => cellRenderer(props, idx),
                      cellRendererParams: {
                        idx,

                        float: 'right',
                        decimalMax: 2
                      }
                    },
                    {
                      field: `parts.${idx}.base.mimPerUnit`,
                      width: 136,
                      minWidth: 136,
                      // suppressMovable:true,
                      headerName: 'МиМ',
                      suppressMovable: true,
                      lockPinned: true,
                      cellRenderer: (
                        props: ICellRendererParams<
                          HandbookExecutionTreeData,
                          unknown,
                          IExecutedTabContext
                        >
                      ) => cellRenderer(props, idx),
                      cellRendererParams: {
                        idx,

                        float: 'right',
                        decimalMax: 2
                      }
                    },
                    {
                      field: `parts.${idx}.base.mtrPerUnit`,
                      width: 136,
                      minWidth: 136,
                      // suppressMovable:true,
                      headerName: 'МТР',
                      suppressMovable: true,
                      lockPinned: true,
                      cellRenderer: (
                        props: ICellRendererParams<
                          HandbookExecutionTreeData,
                          unknown,
                          IExecutedTabContext
                        >
                      ) => cellRenderer(props, idx),
                      cellRendererParams: {
                        idx,

                        float: 'right',
                        decimalMax: 2
                      }
                    },
                    {
                      field: `parts.${idx}.base.costPerUnit`,
                      width: 136,
                      minWidth: 136,
                      // suppressMovable:true,
                      headerName: 'Всего',
                      suppressMovable: true,
                      lockPinned: true,
                      cellRenderer: (
                        props: ICellRendererParams<
                          HandbookExecutionTreeData,
                          unknown,
                          IExecutedTabContext
                        >
                      ) => cellRenderer(props, idx),
                      cellRendererParams: {
                        idx,

                        float: 'right',
                        decimalMax: 2
                      }
                    },
                    {
                      field: `parts.${idx}.base.kzPerUnit`,
                      width: 136,
                      minWidth: 136,
                      // suppressMovable:true,
                      headerName: 'КЗ',
                      suppressMovable: true,
                      lockPinned: true,
                      cellRenderer: (
                        props: ICellRendererParams<
                          HandbookExecutionTreeData,
                          unknown,
                          IExecutedTabContext
                        >
                      ) => cellRenderer(props, idx),
                      cellRendererParams: {
                        idx,

                        float: 'right',
                        decimalMax: 2
                      }
                    },
                    {
                      field: `parts.${idx}.base.totalPerUnit`,
                      width: 136,
                      minWidth: 136,
                      // suppressMovable:true,
                      headerName: 'Всего с КЗ',
                      suppressMovable: true,
                      lockPinned: true,
                      cellRenderer: (
                        props: ICellRendererParams<
                          HandbookExecutionTreeData,
                          unknown,
                          IExecutedTabContext
                        >
                      ) => cellRenderer(props, idx),
                      cellRendererParams: {
                        idx,

                        float: 'right',
                        decimalMax: 2
                      }
                    }
                  ]
                },
                {
                  // suppressMovable:true,
                  headerName: 'Общая стоимость',
                  suppressMovable: true,
                  lockPinned: true,
                  marryChildren: true,
                  children: [
                    {
                      field: `parts.${idx}.base.fotTotal`,
                      width: 136,
                      minWidth: 136,
                      // suppressMovable:true,
                      headerName: 'ФОТ ОР',
                      suppressMovable: true,
                      lockPinned: true,
                      cellRenderer: (
                        props: ICellRendererParams<
                          HandbookExecutionTreeData,
                          unknown,
                          IExecutedTabContext
                        >
                      ) => cellRenderer(props, idx),
                      cellRendererParams: {
                        idx,

                        float: 'right',
                        decimalMax: 2
                      }
                    },
                    {
                      field: `parts.${idx}.base.mimTotal`,
                      width: 136,
                      minWidth: 136,
                      // suppressMovable:true,
                      headerName: 'МиМ',
                      suppressMovable: true,
                      lockPinned: true,
                      cellRenderer: (
                        props: ICellRendererParams<
                          HandbookExecutionTreeData,
                          unknown,
                          IExecutedTabContext
                        >
                      ) => cellRenderer(props, idx),
                      cellRendererParams: {
                        idx,

                        float: 'right',
                        decimalMax: 2
                      }
                    },
                    {
                      field: `parts.${idx}.base.mtrTotal`,
                      width: 136,
                      minWidth: 136,
                      // suppressMovable:true,
                      headerName: 'МТР',
                      suppressMovable: true,
                      lockPinned: true,
                      cellRenderer: (
                        props: ICellRendererParams<
                          HandbookExecutionTreeData,
                          unknown,
                          IExecutedTabContext
                        >
                      ) => cellRenderer(props, idx),
                      cellRendererParams: {
                        idx,

                        float: 'right',
                        decimalMax: 2
                      }
                    },
                    {
                      field: `parts.${idx}.base.costTotal`,
                      width: 136,
                      minWidth: 136,
                      // suppressMovable:true,
                      headerName: 'Всего',
                      suppressMovable: true,
                      lockPinned: true,
                      cellRenderer: (
                        props: ICellRendererParams<
                          HandbookExecutionTreeData,
                          unknown,
                          IExecutedTabContext
                        >
                      ) => cellRenderer(props, idx),
                      cellRendererParams: {
                        idx,

                        float: 'right',
                        decimalMax: 2
                      }
                    },
                    {
                      field: `parts.${idx}.base.kzTotal`,
                      width: 136,
                      minWidth: 136,
                      // suppressMovable:true,
                      headerName: 'КЗ',
                      suppressMovable: true,
                      lockPinned: true,
                      cellRenderer: (
                        props: ICellRendererParams<
                          HandbookExecutionTreeData,
                          unknown,
                          IExecutedTabContext
                        >
                      ) => cellRenderer(props, idx),
                      cellRendererParams: {
                        idx,

                        float: 'right',
                        decimalMax: 2
                      }
                    },
                    {
                      field: `parts.${idx}.base.total`,
                      width: 136,
                      minWidth: 136,
                      // suppressMovable:true,
                      headerName: 'Всего с КЗ',
                      suppressMovable: true,
                      lockPinned: true,
                      cellRenderer: (
                        props: ICellRendererParams<
                          HandbookExecutionTreeData,
                          unknown,
                          IExecutedTabContext
                        >
                      ) => cellRenderer(props, idx),
                      cellRendererParams: {
                        idx,

                        float: 'right',
                        decimalMax: 2
                      }
                    }
                  ]
                }
              ]
            },
            {
              field: `parts.${idx}.curr`,
              // suppressMovable:true,
              columnGroupShow: 'open',
              suppressMovable: true,
              lockPinned: true,
              marryChildren: true,

              headerName: 'В текущих ценах',
              children: [
                {
                  // suppressMovable:true,
                  headerName: 'Стоимость единицы',
                  suppressMovable: true,
                  lockPinned: true,
                  marryChildren: true,
                  children: [
                    {
                      field: `parts.${idx}.curr.fotPerUnit`,
                      width: 136,
                      minWidth: 136,
                      // suppressMovable:true,
                      headerName: 'ФОТ ОР',
                      suppressMovable: true,
                      lockPinned: true,
                      cellRenderer: (
                        props: ICellRendererParams<
                          HandbookExecutionTreeData,
                          unknown,
                          IExecutedTabContext
                        >
                      ) => cellRenderer(props, idx),
                      cellRendererParams: {
                        idx,

                        float: 'right',
                        decimalMax: 2
                      }
                    },
                    {
                      field: `parts.${idx}.curr.mimPerUnit`,
                      width: 136,
                      minWidth: 136,
                      // suppressMovable:true,
                      headerName: 'МиМ',
                      suppressMovable: true,
                      lockPinned: true,
                      cellRenderer: (
                        props: ICellRendererParams<
                          HandbookExecutionTreeData,
                          unknown,
                          IExecutedTabContext
                        >
                      ) => cellRenderer(props, idx),
                      cellRendererParams: {
                        idx,

                        float: 'right',
                        decimalMax: 2
                      }
                    },
                    {
                      field: `parts.${idx}.curr.mtrPerUnit`,
                      width: 136,
                      minWidth: 136,
                      // suppressMovable:true,
                      headerName: 'МТР',
                      suppressMovable: true,
                      lockPinned: true,
                      cellRenderer: (
                        props: ICellRendererParams<
                          HandbookExecutionTreeData,
                          unknown,
                          IExecutedTabContext
                        >
                      ) => cellRenderer(props, idx),
                      cellRendererParams: {
                        idx,

                        float: 'right',
                        decimalMax: 2
                      }
                    },
                    {
                      field: `parts.${idx}.curr.costPerUnit`,
                      width: 136,
                      minWidth: 136,
                      // suppressMovable:true,
                      headerName: 'Всего',
                      suppressMovable: true,
                      lockPinned: true,
                      cellRenderer: (
                        props: ICellRendererParams<
                          HandbookExecutionTreeData,
                          unknown,
                          IExecutedTabContext
                        >
                      ) => cellRenderer(props, idx),
                      cellRendererParams: {
                        idx,

                        float: 'right',
                        decimalMax: 2
                      }
                    },
                    {
                      field: `parts.${idx}.curr.kzPerUnit`,
                      width: 136,
                      minWidth: 136,
                      // suppressMovable:true,
                      headerName: 'КЗ',
                      suppressMovable: true,
                      lockPinned: true,
                      cellRenderer: (
                        props: ICellRendererParams<
                          HandbookExecutionTreeData,
                          unknown,
                          IExecutedTabContext
                        >
                      ) => cellRenderer(props, idx),
                      cellRendererParams: {
                        idx,

                        float: 'right',
                        decimalMax: 2
                      }
                    },
                    {
                      field: `parts.${idx}.curr.totalPerUnit`,
                      width: 136,
                      minWidth: 136,
                      // suppressMovable:true,
                      headerName: 'Всего с КЗ',
                      suppressMovable: true,
                      lockPinned: true,
                      cellRenderer: (
                        props: ICellRendererParams<
                          HandbookExecutionTreeData,
                          unknown,
                          IExecutedTabContext
                        >
                      ) => cellRenderer(props, idx),
                      cellRendererParams: {
                        idx,

                        float: 'right',
                        decimalMax: 2
                      }
                    }
                  ]
                },
                {
                  // suppressMovable:true,
                  headerName: 'Общая стоимость',
                  suppressMovable: true,
                  lockPinned: true,
                  marryChildren: true,
                  children: [
                    {
                      field: `parts.${idx}.curr.fotTotal`,
                      width: 136,
                      minWidth: 136,
                      // suppressMovable:true,
                      headerName: 'ФОТ ОР',
                      suppressMovable: true,
                      lockPinned: true,
                      cellRenderer: (
                        props: ICellRendererParams<
                          HandbookExecutionTreeData,
                          unknown,
                          IExecutedTabContext
                        >
                      ) => cellRenderer(props, idx),
                      cellRendererParams: {
                        idx,

                        float: 'right',
                        decimalMax: 2
                      }
                    },
                    {
                      field: `parts.${idx}.curr.mimTotal`,
                      width: 136,
                      minWidth: 136,
                      // suppressMovable:true,
                      headerName: 'МиМ',
                      suppressMovable: true,
                      lockPinned: true,
                      cellRenderer: (
                        props: ICellRendererParams<
                          HandbookExecutionTreeData,
                          unknown,
                          IExecutedTabContext
                        >
                      ) => cellRenderer(props, idx),
                      cellRendererParams: {
                        idx,

                        float: 'right',
                        decimalMax: 2
                      }
                    },
                    {
                      field: `parts.${idx}.curr.mtrTotal`,
                      width: 136,
                      minWidth: 136,
                      // suppressMovable:true,
                      headerName: 'МТР',
                      suppressMovable: true,
                      lockPinned: true,
                      cellRenderer: (
                        props: ICellRendererParams<
                          HandbookExecutionTreeData,
                          unknown,
                          IExecutedTabContext
                        >
                      ) => cellRenderer(props, idx),
                      cellRendererParams: {
                        idx,

                        float: 'right',
                        decimalMax: 2
                      }
                    },
                    {
                      field: `parts.${idx}.curr.costTotal`,
                      width: 136,
                      minWidth: 136,
                      // suppressMovable:true,
                      headerName: 'Всего',
                      suppressMovable: true,
                      lockPinned: true,
                      cellRenderer: (
                        props: ICellRendererParams<
                          HandbookExecutionTreeData,
                          unknown,
                          IExecutedTabContext
                        >
                      ) => cellRenderer(props, idx),
                      cellRendererParams: {
                        idx,

                        float: 'right',
                        decimalMax: 2
                      }
                    },
                    {
                      field: `parts.${idx}.curr.kzTotal`,
                      width: 136,
                      minWidth: 136,
                      // suppressMovable:true,
                      headerName: 'КЗ',
                      suppressMovable: true,
                      lockPinned: true,
                      cellRenderer: (
                        props: ICellRendererParams<
                          HandbookExecutionTreeData,
                          unknown,
                          IExecutedTabContext
                        >
                      ) => cellRenderer(props, idx),
                      cellRendererParams: {
                        idx,

                        float: 'right',
                        decimalMax: 2
                      }
                    },
                    {
                      field: `parts.${idx}.curr.total`,
                      width: 136,
                      minWidth: 136,
                      // suppressMovable:true,
                      headerName: 'Всего с КЗ',
                      suppressMovable: true,
                      lockPinned: true,
                      cellRenderer: (
                        props: ICellRendererParams<
                          HandbookExecutionTreeData,
                          unknown,
                          IExecutedTabContext
                        >
                      ) => cellRenderer(props, idx),
                      cellRendererParams: {
                        idx,

                        float: 'right',
                        decimalMax: 2
                      }
                    }
                  ]
                }
              ]
            },

            {
              // suppressMovable:true,
              columnGroupShow: 'open',
              headerName: 'ТЗ',
              suppressMovable: true,
              lockPinned: true,
              marryChildren: true,
              children: [
                {
                  field: `parts.${idx}.tzPerUnit`,
                  width: 136,
                  minWidth: 136,
                  // suppressMovable:true,
                  headerName: 'На единицу',
                  suppressMovable: true,
                  lockPinned: true,
                  cellRenderer: (
                    props: ICellRendererParams<
                      HandbookExecutionTreeData,
                      unknown,
                      IExecutedTabContext
                    >
                  ) => cellRenderer(props, idx),
                  cellRendererParams: {
                    idx,

                    float: 'right',
                    decimalMax: 2,
                    isPinnedTopHidden: true,
                    isFolderHidden: true
                  }
                },
                {
                  field: `parts.${idx}.tzTotal`,
                  width: 136,
                  minWidth: 136,
                  // suppressMovable:true,
                  headerName: 'Всего',
                  suppressMovable: true,
                  lockPinned: true,
                  cellRenderer: (
                    props: ICellRendererParams<
                      HandbookExecutionTreeData,
                      unknown,
                      IExecutedTabContext
                    >
                  ) => cellRenderer(props, idx),
                  cellRendererParams: {
                    idx,

                    float: 'right',
                    decimalMax: 2
                  }
                }
              ]
            },
            {
              headerClass: (props) =>
                classHeader(props, 'header-right-border-executed', part.actID),
              // suppressMovable:true,
              columnGroupShow: 'open',
              headerName: 'ТЗМ',
              suppressMovable: true,
              lockPinned: true,
              marryChildren: true,
              children: [
                {
                  field: `parts.${idx}.tzmPerUnit`,
                  width: 136,
                  minWidth: 136,
                  // suppressMovable:true,
                  headerName: 'На единицу',
                  suppressMovable: true,
                  lockPinned: true,
                  cellRenderer: (
                    props: ICellRendererParams<
                      HandbookExecutionTreeData,
                      unknown,
                      IExecutedTabContext
                    >
                  ) => cellRenderer(props, idx),
                  cellRendererParams: {
                    idx,

                    float: 'right',
                    decimalMax: 2,
                    isPinnedTopHidden: true,
                    isFolderHidden: true
                  }
                },
                {
                  headerClass: (props) =>
                    classHeader(
                      props,
                      'header-right-border-executed',
                      part.actID
                    ),
                  cellClass: (props) =>
                    classCell(
                      props,
                      'header-right-border-executed',
                      part.actID
                    ),
                  field: `parts.${idx}.tzmTotal`,
                  width: 136,
                  minWidth: 136,
                  // suppressMovable:true,
                  headerName: 'Всего',
                  suppressMovable: true,
                  lockPinned: true,
                  cellRenderer: (
                    props: ICellRendererParams<
                      HandbookExecutionTreeData,
                      unknown,
                      IExecutedTabContext
                    >
                  ) => cellRenderer(props, idx),
                  cellRendererParams: {
                    idx,

                    float: 'right',
                    decimalMax: 2
                  }
                }
              ]
            }
          ]
        });
        return acc;
      },
      []
    ) || []
  );
};

// export const ExecutedTabCellRenderer = (
export const cellRenderer = (
  props: ICellRendererParams<
    HandbookExecutionTreeData,
    unknown,
    IExecutedTabContext
  >,
  idx?: number
  // errors?: any
) => {
  const isPinnedTop = props.node.rowPinned === 'top';
  const decimalMax = props.colDef?.cellRendererParams?.decimalMax;
  const decimalMaxTooltip = 18;
  // console.log('before errors');

  const [errors, setErrors] = useState<{ [key: number]: boolean }>({});

  useEffect(() => {
    if (props.data) setErrors(checkError(props.data.parts));
  }, [props.data?.parts]);

  // useEffect(() => {
  //   if (props.data && props.data.id !== -1) {
  //     console.log(
  //       props.colDef?.headerName,
  //       errors,
  //       idx!
  //     );
  //   }
  // }, [props.context?.errors]);

  if (isPinnedTop && props.colDef?.cellRendererParams?.isPinnedTopHidden) {
    return <></>;
  }

  // if (isPinnedTop && props.colDef?.cellRendererParams?.isCustomPinned) {
  //   return (
  //     <Box
  //       display="flex"
  //       justifyContent={'flex-start'}
  //       alignItems={'center'}></Box>
  //   );
  // }

  if (isPinnedTop && props.colDef?.cellRendererParams?.isLevel) {
    return (
      <Box
        className="hiddenRightBorder"
        display="flex"
        width={'100%'}
        alignItems={'center'}>
        <FunctionsIcon />
        <Typography variant="h1" color="primary.light" fontSize={'14px'}>
          Суммы
        </Typography>
      </Box>
    );
  }

  if (isPinnedTop && props.colDef?.cellRendererParams?.isAct) {
    return <></>;
  }

  if (isPinnedTop && props.colDef?.cellRendererParams?.isPart) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { prices } = useContext(ExecutedTabContext);

    if (!props.colDef?.field) return <></>;
    const data = props.colDef?.field.split('.')[1];
    if (data == undefined) return <></>;
    const result =
      data !== undefined && prices !== undefined
        ? props.node.data?.parts?.[+data]?.[prices]?.total
        : null;
    if (!result) return <></>;
    return (
      <Cell
        float="right"
        isError={isHaveColorRow(props.data?.type!, idx!, errors)}
        originData={
          String(result)
            ? decimalMax
              ? replaceStringFromServer(String(result), decimalMaxTooltip)
              : String(result)
            : ''
        }>
        {String(result)
          ? decimalMax
            ? replaceStringFromServer(String(result), +decimalMax)
            : String(result)
          : ''}
      </Cell>
    );
  }

  if (isPinnedTop) {
    return (
      <Cell
        float="right"
        originData={
          props.value
            ? decimalMax
              ? replaceStringFromServer(String(props.value), decimalMaxTooltip)
              : String(props.value)
            : ''
        }>
        {props.value
          ? decimalMax
            ? replaceStringFromServer(String(props.value), +decimalMax)
            : String(props.value)
          : ''}
      </Cell>
    );
  }

  if (
    props.colDef?.cellRendererParams?.isFolderHidden &&
    props.data?.type === 'FOLDER'
  ) {
    return <></>;
  }

  if (props.colDef?.cellRendererParams?.isAct) {
    const actTotal = props.data?.parts.reduce((acc, act) => {
      if (act.actID && act?.quantity) {
        return acc + Number(act.quantity);
      }
      return acc;
    }, 0);
    const allTotal = props?.data?.qtyTotal;
    const isError = Number(actTotal) > Number(allTotal);
    return (
      <Cell
        isError={isHaveColorRow(props.data?.type!, idx!, errors)}
        float="center"
        originData={
          props.value
            ? decimalMax
              ? replaceStringFromServer(String(props.value), decimalMaxTooltip)
              : String(props.value)
            : ''
        }>
        {props.value
          ? decimalMax
            ? replaceStringFromServer(String(props.value), +decimalMax)
            : String(props.value)
          : ''}
      </Cell>
    );
  }

  if (props.colDef?.cellRendererParams?.isLevel) {
    const hasChildren = props.data?.hasChildren;
    const type = props.data?.type;

    const close = !!(props.context.collapseRowsIds ?? []).find(
      (e) => e === props.data?.id
    );

    const onClick = () => {
      if (props.context.onCollapse && props.data?.id) {
        props.context.onCollapse(props.data?.id);
      }
    };

    switch (type) {
      case 'FOLDER':
        return (
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}>
            <Box
              width={'68px'}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}>
              <Level onClick={onClick} arrowClose={close}>
                {hasChildren ? (
                  <KeyboardArrowUpIcon className={'arrow'} />
                ) : (
                  <Box
                    sx={{
                      padding: '0 17px'
                    }}
                  />
                )}
                <ExecutedTabCellLevelFolder>
                  {props.value ? String(props.value) : ''}
                </ExecutedTabCellLevelFolder>
              </Level>
            </Box>
          </Box>
        );
      case 'RATE':
        return (
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}>
            <Box
              width={'68px'}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}>
              <Level onClick={onClick} arrowClose={close}>
                {hasChildren ? (
                  <KeyboardArrowUpIcon className={'arrow'} />
                ) : (
                  <Box
                    sx={{
                      padding: '0 17px'
                    }}
                  />
                )}
                <div>
                  <SvgExecutedRate
                    style={{ fill: '#5291DA', width: 17, height: 18 }}
                  />
                </div>
              </Level>
            </Box>
          </Box>
        );
      case 'RATE_POSITION':
        return (
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}>
            <Box
              width={'68px'}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}>
              <Level
                style={{ alignItems: 'center', justifyContent: 'center' }}
                onClick={onClick}
                arrowClose={close}
                floatRight>
                <Box
                  sx={{
                    padding: '0 17px'
                  }}
                />
                <div>
                  <SvgExecutedRatePosition
                    style={{
                      width: 16,
                      height: 17,
                      fill: '#B78BAC'
                    }}
                  />
                </div>
              </Level>
            </Box>
          </Box>
        );
      default:
        return <></>;
    }
  }

  if (props.colDef?.cellRendererParams?.isPart) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { prices } = useContext(ExecutedTabContext);

    if (!props.colDef?.field) return <></>;
    const data = props.colDef?.field.split('.')[1];
    if (data == undefined) return <></>;
    const result =
      data !== undefined && prices !== undefined
        ? props.node.data?.parts?.[+data]?.[prices]?.total
        : null;
    if (!result) return <></>;
    return (
      <Cell
        isError={isHaveColorRow(props.data?.type!, idx!, errors)}
        float="right"
        originData={
          decimalMax
            ? replaceStringFromServer(String(result), decimalMaxTooltip)
            : result
        }>
        {decimalMax
          ? replaceStringFromServer(String(result), +decimalMax)
          : result}
      </Cell>
    );
  }

  if (props.colDef?.cellRendererParams?.isResourceType) {
    return (
      <Cell float={props.colDef?.cellRendererParams?.float ?? 'left'}>
        {props.value
          ? ENUMResourceTypeToHumanObject[
              props.value as HandbookExecutionTreeData['resourceType']
            ]
          : ''}
      </Cell>
    );
  }

  return (
    <Cell
      isError={isHaveColorRow(props.data?.type!, idx!, errors)}
      float={props.colDef?.cellRendererParams?.float ?? 'left'}
      originData={
        props.value
          ? decimalMax
            ? replaceStringFromServer(String(props.value), decimalMaxTooltip)
            : String(props.value)
          : String(props.value)
      }>
      {props.value
        ? decimalMax
          ? replaceStringFromServer(String(props.value), +decimalMax)
          : String(props.value)
        : ''}
    </Cell>
  );
};

export const ExecutedTabCellEditable = React.memo(
  forwardRef<
    ReactElement | { getValue: () => string | number | null },
    CustomCellEditorProps<
      HandbookExecutionTreeData,
      HandbookExecutionRowPartData['quantity'],
      IExecutedTabContext
    >
  >((props, ref) => {
    const {
      control,
      setValue: setFormValue,
      formState
    } = useForm<{ input: string }>({
      mode: 'all',
      reValidateMode: 'onChange',
      defaultValues: {
        input: props.value?.toString() ?? ''
      }
    });
    const [value, setValue] = useState<string | number>(props.value ?? '');
    const refPopper = useRef<HTMLDivElement | null>(null);

    useImperativeHandle(
      ref,
      () => ({
        getValue: () => {
          console.log(
            'getvalue',
            value === '' ||
              value === ' ' ||
              value === undefined ||
              value === null ||
              value === '0' ||
              value === 0
              ? null
              : value
          );
          return value === '' ||
            value === null ||
            value === undefined ||
            value === '0' ||
            value === 0
            ? null
            : String(value).replace(',', '.').replace(/\s/g, '');
        },
        isCancelAfterEnd() {
          return !formState.isValid || !formState.isDirty;
        }
      }),
      [value, formState.isValid, formState.isDirty]
    );

    return (
      <Box
        display={'flex'}
        ref={refPopper}
        alignItems={'center'}
        width={'100%'}
        height={'100%'}>
        <Controller
          control={control}
          name="input"
          rules={{
            validate: {
              max: (v: string) => {
                if (v) {
                  const replacedValue = v.replace(/\s/g, '');
                  const dotIndex = replacedValue.indexOf(',');
                  const [MAX_LENGTH_LEFT, MAX_LENGTH_RIGHT] = [9, 6];

                  if (dotIndex >= 0) {
                    if (
                      replacedValue.substring(0, dotIndex).length >
                        MAX_LENGTH_LEFT ||
                      replacedValue.substring(dotIndex + 1).length >
                        MAX_LENGTH_RIGHT
                    ) {
                      return 'Максимальное значение 999 999 999,999999';
                    }
                  } else {
                    if (replacedValue.length > MAX_LENGTH_LEFT) {
                      return 'Максимальное значение 999 999 999,999999';
                    }
                  }
                  return true;
                }
                return true;
              }
            }
          }}
          render={({ field: { ...propsController }, formState }) => {
            const errorMessage = formState.errors?.input?.message || '';
            return (
              <>
                <InputEdit
                  {...propsController}
                  onFocus={(e: { target: { select: () => void } }) =>
                    e.target.select()
                  }
                  allowedDecimalSeparators={['.', ',']}
                  decimalSeparator={','}
                  style={{
                    width: '100%',
                    boxSizing: 'border-box',
                    borderBottom: `1px solid #3f51b5`
                  }}
                  allowNegative
                  onValueChange={(event) => {
                    setValue(event.formattedValue ?? '');
                    setFormValue('input', event.formattedValue ?? '');
                  }}
                  // onChange={(ev) => {
                  // console.log((ev.target as any).value ?? '');
                  // setValue((ev.target as any).value ?? '');
                  // setFormValue('input', (ev.target as any).value ?? '', {
                  //   shouldDirty: true
                  // });
                  // }}
                  value={value}
                  autoFocus
                  thousandSeparator={' '}
                  onBlur={() => props.stopEditing()}
                />
                <PopperComponent
                  message={errorMessage as string}
                  refItem={refPopper.current}
                />
              </>
            );
          }}
        />
      </Box>
    );
  })
);
ExecutedTabCellEditable.displayName = 'ExecutedTabCellEditable';

export const ExecutedTabHeaderGroupPart = () => {};
