import { createAsyncThunk } from '@reduxjs/toolkit';
import { calculationsApi } from 'api/calculations';
import {
  GetComplicatedListResponse,
  GetRowsListV2Request
} from 'api/calculations/types';
import { RootState } from 'store/store';
import {
  setHiddenRows,
  toggleEndFetch,
  toggleIsNotFetched,
  toggleLoader,
  togglePage,
  updateData
} from './bim.slice';
import axios, { AxiosResponse } from 'axios';
import { config } from '../../../../core/config';
import { getRefreshToken } from '../../../../utils/getRefreshToken';
import { delay } from 'lodash';

export const getData = createAsyncThunk<
  GetComplicatedListResponse | null,
  GetRowsListV2Request
>('bim_calculation/getData', async (params, { dispatch, getState, signal }) => {
  const rootState = getState() as RootState;
  const bimState = rootState.calculations.bim.calc;
  // const localStorageRows = getLocalStorage(
  //   ENUMLocalStorage.levelsBaseMethodCalc
  // );
  // const findLevels = localStorageRows?.find(
  //   (level) => level.id === params.calcID
  // );
  // if (findLevels?.levels) {
  //   dispatch(setHiddenRows(new Set(findLevels.levels)));
  // }
  try {
    const result: AxiosResponse<GetComplicatedListResponse> = await axios.get(
      config.apiHost + `/calculation/${params.calcID}/complicated/bim/get`,
      {
        // method: 'POST',
        params: {
          page: params.page,
          limit: params.limit,
          depth: params?.depth
        },
        signal,
        headers: {
          Authorization: 'Bearer ' + rootState.auth.token
        }
      }
    );
    await delay(() => {}, 1000);

    if (params?.depth && params.depth > 1) {
      const collapsedRows = new Set<number>();
      result.data.data.forEach((row) => {
        if (row.level! < params.depth!) {
          collapsedRows.add(row.id);
        }
      });
      dispatch(setHiddenRows(collapsedRows));
    } else if (params?.depth && params.depth === 1) {
      dispatch(setHiddenRows(new Set()));
    }
    // if (!findLevels?.levels) {
    //   const collapseRows = new Set(bimState.hiddenRows);
    //   result.data.data?.forEach((el) => {
    //     if (!(el.level! >= bimState.collapseMode)) {
    //       collapseRows.add(el.id);
    //     }
    //   });
    //   dispatch(setHiddenRows(collapseRows));
    // }

    if (result.data.data && result.data.data.length < params.limit) {
      dispatch(toggleEndFetch(true));
    }
    dispatch(toggleIsNotFetched(false));
    console.log('server response getData', result);
    return result.data ?? null;
  } catch (e: any) {
    if (e?.response?.status === 401) {
      getRefreshToken(dispatch, rootState, getData, params);
    }
    console.log(e);
    return null;
  } finally {
  }
});
export const appendData = createAsyncThunk<
  GetComplicatedListResponse | null,
  GetRowsListV2Request
>(
  'bim_calculation/appendData',
  async (params, { dispatch, getState, signal }) => {
    dispatch(toggleLoader(true));

    const rootState = getState() as RootState;
    const gridRef = (getState() as RootState).AgGrid.gridRef;
    const bimState = rootState.calculations.bim.calc;

    try {
      const result: AxiosResponse<GetComplicatedListResponse> = await axios.get(
        config.apiHost + `/calculation/${params.calcID}/complicated/bim/get`,
        {
          // method: 'POST',
          params: {
            page: params.page,
            limit: params.limit,
            depth: params?.depth
          },
          signal,
          headers: {
            Authorization: 'Bearer ' + rootState.auth.token
          }
        }
      );
      await delay(() => {}, 1000);

      console.log('server response appendData', result);
      if (result.data.data && gridRef) {
        const collapseRows = new Set(bimState.hiddenRows);

        dispatch(setHiddenRows(collapseRows));
        gridRef.api.applyTransaction({ add: result.data.data });
      }
      if (result.data.data && result.data.data.length < params.limit) {
        dispatch(toggleEndFetch(true));
      }
      dispatch(togglePage(params.page));
      dispatch(toggleLoader(false));

      return result.data ?? null;
    } catch (e: any) {
      if (e?.response?.status === 401) {
        getRefreshToken(dispatch, rootState, appendData, params);
      }
      console.log(e);
      dispatch(toggleLoader(false));
      return null;
    } finally {
      dispatch(toggleLoader(false));
    }
  }
);

export const getChildren = createAsyncThunk<
  {
    parentID: number;
    children: GetComplicatedListResponse['data'];
    fromIndex: number;
    isDelete: boolean;
  } | null,
  {
    calcID: number | string;
    rowID: number | string;
    index: number;
    action: 'add' | 'remove';
    variant?: 'complicated' | 'execution';
  }
>('getBimChildren', async (params, { dispatch, getState }) => {
  const rootState = getState() as RootState;
  const grid = rootState.AgGrid.gridRef;
  const { action, index, variant = 'complicated', ...restParams } = params;
  try {
    const commonParams = {
      ...restParams,
      limit: 2000,
      offset: 0,
      type: 'bim'
    };
    const response: any = await dispatch(
      variant === 'complicated'
        ? calculationsApi.endpoints.getComplicatedRowsChildrenListV2.initiate(
            commonParams
          )
        : calculationsApi.endpoints.getExecutionRowsChildrenList.initiate(
            commonParams
          )
    ).unwrap();

    if (response && response.children && grid) {
      if (params.action === 'add') {
        const { hiddenRows } = (getState() as RootState).calculations.bim.calc;
        if (hiddenRows.has(params.rowID as number)) {
          grid.api.applyTransaction({
            addIndex: params.index + 1,
            add: response.children
          });
        }
      } else {
        grid.api.applyTransaction({
          remove: response.children
        });
      }
      // grid.api.refreshCells({ force: true });
    }
    return {
      ...response,
      fromIndex: params.index + 1,
      isDelete: params.action === 'remove'
    };
  } catch (e: any) {
    if (e?.response?.status == 401) {
      getRefreshToken(dispatch, rootState, getChildren, params);
    }

    return null;
  }
});

export const updateTable = createAsyncThunk(
  'updateBimTable',
  async (params: any, { dispatch, getState, signal }) => {
    const rootState = getState() as RootState;

    const bim = rootState.calculations.bim.calc;

    const grid = rootState.AgGrid.gridRef;
    try {
      const result: AxiosResponse<GetComplicatedListResponse> = await axios.get(
        config.apiHost + `/calculation/${params.calcID}/complicated/bim/get`,
        {
          // method: 'POST',
          params: { page: params.page, limit: params.limit },
          signal,
          headers: {
            Authorization: 'Bearer ' + rootState.auth.token
          }
        }
      );
      if (result.data && grid) {
        const update = result.data.data.concat({
          ...result.data.total,
          id: 0.25
        });
        console.log('update', update, grid);
        await (async () => {
          return new Promise((resolve) => {
            grid.api.applyTransaction({ update });
            // grid.api.refreshCells({ force: true });
            resolve(undefined);
          });
        })();
        dispatch(updateData(update));
        if (bim.hiddenRows?.size) {
          bim.hiddenRows.forEach(console.log);
        }
      }
    } catch (e: any) {
      if (e?.response?.status === 401) {
        getRefreshToken(dispatch, rootState, updateTable, params);
      }
    }
  }
);
