import { api } from 'api/api';
import { Project, ProjectDashboard } from 'types';
import {
  AddProjectPreviewRequest,
  CreateProjectRequest,
  DeleteProjectPreviewRequest,
  DeleteProjectRequest,
  GetProjectByIdRequest,
  GetProjectDashboardRequest,
  GetProjectsResponse,
  GetProjectsWithStatisticsRequest,
  GetProjectsWithStatisticsResponse,
  IShortProjectResponse,
  UpdateProjectRequest
} from './projects.types';

export const projectsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProjectsWithStatistics: build.query<
      GetProjectsWithStatisticsResponse,
      GetProjectsWithStatisticsRequest
    >({
      query: (params) => ({
        url: '/project/overall',
        method: 'GET',
        params
      })
    }),
    getProjects: build.query<GetProjectsResponse, void>({
      query: () => ({
        url: '/project/list',
        method: 'GET'
      }),
      providesTags: ['Projects']
    }),
    createProject: build.mutation<Project, CreateProjectRequest>({
      query: ({ body }) => ({
        url: '/project/create',
        method: 'POST',
        body
      }),
      invalidatesTags: (result) => (result ? ['Projects', 'ShortProjects'] : [])
    }),
    getProjectDashboard: build.query<
      ProjectDashboard,
      GetProjectDashboardRequest
    >({
      query: ({ projectID }) => ({
        url: `/project/${projectID}/dashboard`,
        method: 'GET',
      }),
      providesTags: ['ProjectDashboard'],
      keepUnusedDataFor:0,
    }),
    getProjectById: build.query<Project, GetProjectByIdRequest>({
      query: ({ projectID }) => ({
        url: `/project/${projectID}/get`,
        method: 'GET'
      })
    }),
    updateProject: build.mutation<Project, UpdateProjectRequest>({
      query: ({ params, body }) => ({
        url: `/project/${params.projectID}/update`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: (result) =>
        result ? ['Projects', 'ProjectDashboard'] : []
    }),
    deleteProject: build.mutation<void, DeleteProjectRequest>({
      query: ({ projectID }) => ({
        url: `/project/${projectID}/delete`,
        method: 'DELETE'
      }),
      invalidatesTags: (result) => (result ? ['Projects'] : [])
    }),
    addProjectPreview: build.mutation<Project, AddProjectPreviewRequest>({
      query: ({ params, body }) => {
        const formData = new FormData();
        body.forEach((file) => {
          formData.append('file', file);
        });

        return {
          url: `/project/${params.projectID}/image/add`,
          method: 'PUT',
          body: formData
        };
      }
    }),
    deleteProjectPreview: build.mutation<void, DeleteProjectPreviewRequest>({
      query: ({ projectID, body }) => ({
        url: `/project/${projectID}/image/delete`,
        body,
        method: 'POST'
      })
    }),
    getShortProjects: build.query<IShortProjectResponse, void>({
      query: () => ({
        url: `/project/short-list`,
        method: 'GET'
      }),
      providesTags: ['ShortProjects']
    })
  }),
  overrideExisting: false
});

export const {
  useGetProjectsWithStatisticsQuery,
  useGetProjectsQuery,
  useCreateProjectMutation,
  useGetProjectDashboardQuery,
  useGetProjectByIdQuery,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useAddProjectPreviewMutation,
  useDeleteProjectPreviewMutation,
  useGetShortProjectsQuery
} = projectsApi;
